import Entity from '_entities/base/EntityNew'

import TicketCategory from '_entities/ticket/TicketCategory'
import User from '_entities/user/User'

export const TicketCancelReasonSchema = {
    name: 'TicketCancelReason',
    properties: {
        code: { type: 'string' },
        name: { type: 'string' },
        order: { type: 'int' },
        ticketCategories: { type: 'TicketCategory[]', entity: TicketCategory, default: () => [] },
        type: { type: 'string' },
        isActive: { type: 'bool', default: false },
        deletedAt: { type: 'int' },
        deletedBy: { type: 'User', entity: User },
    },
}

class TicketCancelReason extends Entity {
    get schema() {
        return TicketCancelReasonSchema
    }

    get dataMap() {
        return {
            is_active: 'isActive',
            deleted_at: 'deletedAt',
            deleted_by: 'deletedBy',
            ticket_categories: 'ticketCategories',
        }
    }

    static fieldLabels = {
        code: 'Код',
        name: 'Наименование',
    }
}

export default TicketCancelReason
