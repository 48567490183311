<template>
    <v-card v-if="semantic" flat>
        <v-card-title>Рекомендации ИИ</v-card-title>

        <v-row align="center" dense>
            <v-col cols="auto">
                <v-icon color="orange" small v-text="'$triangleExclamation'" />
                Для заявок с недостаточным описанием ИИ может давать неточные рекомендации.
            </v-col>
        </v-row>

        <div v-if="showTickets">
            <template v-if="semantic.tickets?.length">
                <v-list-group dense>
                    <template v-slot:activator>
                        <v-list-item-title>{{ fieldLabels.tickets }} {{ semantic.tickets.length ? `(${semantic.tickets.length})` : '' }}</v-list-item-title>
                    </template>
                    <v-list-item
                        v-for="ticket in semantic.tickets"
                        :key="ticket.id"
                        :to="{ name: 'TicketView', params: { ticketId: ticket.id } }"
                        target="_blank"
                        dense
                    >
                        <v-list-item-icon>
                            <v-icon v-text="'$clipboardList'" />
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="ticket.title || ticket.id" />
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon small v-text="'$arrowUpRightFromSquare'" />
                        </v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </template>
        </div>

        <div v-if="showTypes">
            <template v-if="semantic.types?.length">
                <v-list-group value="true">
                    <template v-slot:activator>
                        <v-list-item-title>{{ fieldLabels.types }} {{ semantic.types.length ? `(${semantic.types.length})` : '' }}</v-list-item-title>
                    </template>
                    <v-list dense>
                        <v-list-item
                            v-for="type in semantic.types"
                            :key="type.id"
                            @click.native="$emit('on-typing-click', type)"
                        >
                            <v-list-item-icon>
                                <v-icon v-text="'$listTree'" />
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="multiline-chip" v-text="type.name?.split('|').join(' / ')" />
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-list-group>
            </template>
        </div>

        <div v-if="showDepartments">
            <template v-if="semantic.departments?.length">
                <v-list-group>
                    <template v-slot:activator>
                        <v-list-item-title>{{ fieldLabels.departments }} {{ semantic.departments.length ? `(${semantic.departments.length})` : '' }}</v-list-item-title>
                    </template>
                    <v-list dense>
                        <v-list-item
                            v-for="department in semantic.departments"
                            :key="department.id"
                            @click.native="$emit('on-department-click', department)"
                        >
                            <v-list-item-icon>
                                <v-icon v-text="'$users'" />
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="multiline-chip" v-text="department.name" />
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-list-group>
            </template>
        </div>

        <div v-if="showExecutors">
            <template v-if="semantic.executors?.length">
                <v-list-group>
                    <template v-slot:activator>
                        <v-list-item-title>{{ fieldLabels.executors }} {{ semantic.executors.length ? `(${semantic.executors.length})` : '' }}</v-list-item-title>
                    </template>
                    <v-list dense>
                        <v-list-item
                            v-for="executor in semantic.executors"
                            :key="executor.id"
                            @click.native="$emit('on-executor-click', executor)"
                        >
                            <v-list-item-icon>
                                <v-icon v-text="'$user'" />
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="multiline-chip" v-text="executor.name" />
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-list-group>
            </template>
        </div>
    </v-card>
</template>

<script>
    import { API } from '_api'

    import TicketSemanticSimilar from '_entities/ticket/TicketSemanticSimilar'

    export default {
        name: 'FTicketSemanticSimilarPicker',

        props: {
            showTickets: { type: Boolean, default: true },
            showTypes: { type: Boolean, default: true },
            showDepartments: { type: Boolean, default: true },
            showExecutors: { type: Boolean, default: true },
            ticketId: { type: String, default: null },
        },

        data: () => ({
            semantic: null,
        }),

        computed: {
            fieldLabels() {
                return TicketSemanticSimilar.fieldLabels
            },

            hasRecommendations() {
                const hasTickets = this.showTickets && this.semantic?.tickets?.length
                const hasTypes = this.showTypes && this.semantic?.types?.length
                const hasDepartments = this.showDepartments && this.semantic?.departments?.length
                const hasExecutors = this.showExecutors && this.semantic?.executors?.length

                return hasTickets || hasTypes || hasDepartments || hasExecutors
            },
        },

        watch: {
            hasRecommendations: {
                handler(hasRecommendations) {
                    this.$emit('on-get-recommendations', hasRecommendations)
                },
            },
        },

        mounted() {
            this.fetchSemantic()
        },

        methods: {
            async fetchSemantic() {
                try {
                    const response = await API.v1.services.ticket.getTicketSemanticSimilar({ ticket_id: this.ticketId })

                    this.semantic = response ? new TicketSemanticSimilar(response) : null
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось получить рекомендации по переквалификации', e)
                    this.$logger.logException(e)
                }
            },
        },
    }
</script>

<style scoped>
    .multiline-chip {
        white-space: normal;
        height: auto;
        cursor: pointer;
    }
</style>
