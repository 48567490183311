import { API } from '_api'
import Keycloak from 'keycloak-js'

import User from '_entities/user/User'
import Worker from '_entities/worker/Worker'

const actions = {
    async restoreAccess({ commit }) {
        commit('restoreAccessRequest')

        try {
            const accessToken = await localStorage.getItem('accessToken')
            const userId = await localStorage.getItem('userId')

            commit('restoreAccessSuccess', { accessToken, userId })
        } catch (e) {
            commit('restoreAccessError', e)
        }
    },

    async signIn({ commit }, { username, password }) {
        commit('signInRequest')

        try {
            const response = await API.v1.services.auth.signIn({ username, password })

            const accessToken = response?.access_token || null
            const userId = response?.user_id || null

            if (accessToken) {
                await localStorage.setItem('accessToken', accessToken)
            }

            if (userId) {
                await localStorage.setItem('userId', userId)
            }

            commit('signInSuccess', { accessToken, userId })
        } catch (e) {
            commit('signInError', e)
        }
    },

    async signOut({ commit, state }) {
        commit('signOutRequest')

        try {
            await localStorage.removeItem('accessToken')
            await localStorage.removeItem('accessKeycloakToken')
            await localStorage.removeItem('userId')
            state.keycloak?.logout()

            console.log('state.keycloak', state.keycloak)

            commit('signOutSuccess')
        } catch (e) {
            commit('signOutError', e)
        }
    },

    async fetchCurrentWorker({ commit, state }) {
        commit('fetchCurrentWorkerRequest')
        let response

        try {
            response = await API.v1.services.worker.getCurrentWorker()
            const currentWorker = new Worker(response)

            response = await API.v1.services.user.getUserById(currentWorker.id)
            const currentUser = new User(response)

            await localStorage.setItem('userId', currentWorker.id)

            commit('fetchCurrentWorkersSuccess', { currentWorker, currentUser })
        } catch (e) {
            commit('fetchCurrentWorkersError', e)
        }
    },

    async changeWorkingStatus({ commit }) {
        commit('saveCurrentWorkerRequest')
        try {
            await API.v1.services.worker.changeWorkStatus()

            commit('saveCurrentWorkerSuccsess')
        } catch (e) {
            commit('saveWorCurrentkerError', e)
        }
    },

    // KEYCLOAK

    async createKeycloak({ commit }) {
        const options = {
            url: process.env.VUE_APP_KEYCLOAK_URL,
            realm: process.env.VUE_APP_KEYCLOAK_REALM,
            clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
        }

        const keycloak = new Keycloak(options)

        commit('setKeycloak', keycloak)
    },

    async initKeycloak({ state }, options) {
        if (state.keycloak) {
            try {
                const auth = await state.keycloak.init({ ...options, checkLoginIframe: false })

                if (auth) {
                    return true
                }
            } catch (e) {
                console.log('keycloak.init error: ', e)

                return false
            }
        }

        return false
    },

    async signInKeycloak({ state }) {
        if (state.keycloak) {
            try {
                const auth = await state.keycloak.login()

                if (auth) {
                    return true
                }
            } catch (e) {
                return false
            }
        }

        return false
    },

    async updateToken({ commit, state }) {
        if (state.keycloak?.authenticated && state.isNeedTokenUpdate) {
            const validitySeconds = process.env.VUE_APP_KEYCLOAK_TOKEN_VALIDITY_SECONDS

            await state.keycloak.updateToken(validitySeconds)

            const accessKeycloakToken = state.keycloak.token

            localStorage.setItem('accessKeycloakToken', accessKeycloakToken)

            commit('updateToken', { accessKeycloakToken })
            commit('updateTokenFlag', false)
        }
    },

    async restoreKeycloakAccess({ commit }) {
        commit('restoreAccessRequest')

        try {
            const accessToken = await localStorage.getItem('accessKeycloakToken')
            const userId = await localStorage.getItem('userId')

            commit('restoreAccessSuccess', { accessToken, userId })
        } catch (e) {
            commit('restoreAccessError', e)
        }
    },

    setUpdateTokenFlag({ commit }, flag) {
        commit('updateTokenFlag', flag)
    },
}

export default actions
