import FileBase from '_entities/media/file/FileBase'

export const FileVideoSchema = {
    name: 'FileVideo',
    properties: {
        duration: { type: 'int', default: 0 },
        height: { type: 'int' },
        width: { type: 'int' },
    },
}

class FileVideo extends FileBase {
    get schema() {
        return {
            ...super.schema,
            ...FileVideoSchema,
            properties: {
                ...super.schema.properties,
                ...FileVideoSchema.properties,
            },
        }
    }
}

export default FileVideo
