import { map as _map } from 'lodash'
import moment from 'moment'

import Entity from '_entities/base/EntityNew'

import CompanyLink from '_entities/company/CompanyLink'
import BuildingLink from '_entities/building/BuildingLink'
import PushMessageLocation from '_entities/pushMessage/PushMessageLocation'
import Service from '_entities/service/Service'
import UserLink from '_entities/user/UserLink'
import PushMessageTemplateLink from '_entities/pushMessage/PushMessageTemplateLink'
import PushMessageTemplateValue from '_entities/pushMessage/PushMessageTemplateValue'

export const PushMessageSchema = {
    name: 'PushMessage',
    primaryKey: 'id',
    properties: {
        buildings: { type: 'BuildingLink[]', entity: BuildingLink, default: [] },
        company: { type: 'CompanyLink', entity: CompanyLink },
        date: { type: 'int', default: () => moment().unix() },
        dateCreateAccount: { type: 'int' },
        id: { type: 'string' },
        isPersonal: { type: 'bool', default: false },
        location: { type: 'PushMessageLocation', entity: PushMessageLocation, default: new PushMessageLocation() },
        messageAuthor: { type: 'UserLink', entity: UserLink },
        service: { type: 'Service', entity: Service },
        template: { type: 'PushMessageTemplateLink', entity: PushMessageTemplateLink },
        templateValues: { type: 'PushMessageTemplateValue[]', entity: PushMessageTemplateValue, default: [] },
        text: { type: 'string' },
        title: { type: 'string' },
        type: { type: 'string' },
    },
}

class PushMessage extends Entity {
    get schema() {
        return PushMessageSchema
    }

    get dataMap() {
        return {
            date_create_account: 'dateCreateAccount',
            is_personal: 'isPersonal',
            message_author: 'messageAuthor',
            template_values: 'templateValues',
        }
    }

    static fieldLabels = {
        buildings: 'Дом',
        company: 'Компания',
        date: 'Дата отправки',
        dateCreateAccount: 'Дата создания аккаунта, от',
        id: 'ID',
        isPersonal: 'Персонализированное сообщение',
        messageAuthor: 'Создатель рассылки',
        text: 'Текст',
        title: 'Заголовок',
        type: 'Тип',
        sampleCategory: 'Категория уведомления',
        sampleView: 'Вид уведомления',
        sampleType: 'Тип уведомления',
    }

    static types = [
        {
            code: 'accident',
            name: 'Автоинформатор',
        },
        {
            code: 'payments',
            name: 'Квитанции',
        },
        {
            code: 'payment_required',
            name: 'Необходимо оплатить счет',
        },
        {
            code: 'meter_verification',
            name: 'Передача показаний',
        },
        {
            code: 'useful_info',
            name: 'Полезная информация',
        },
        {
            code: 'text',
            name: 'Простое уведомление',
        },
        {
            code: 'nps_rate',
            name: 'NPS опрос',
        },
        {
            code: 'service',
            name: 'Push с сылкой на сервис',
        },
    ]

    toArray() {
        // Hint: Типы сервисов, требующие обязательнего установленного флага ипризнака индивидуальности
        const isPersonal = ['meter_verification', 'service', 'useful_info', 'payments'].indexOf(this.type) !== -1

        return {
            buildings: _map(this.buildings, (building) => building.id),
            company: this.company?.id || null,
            date: this.date,
            date_create_account: this.dateCreateAccount,
            is_personal: this.isPersonal || isPersonal,
            location: this.location.toArray(),
            message_author: this.messageAuthor?.id || null,
            entity_id: this.service?.id,
            template: this.template?.id || null,
            template_values: _map(this.templateValues, (templateValue) => templateValue.toArray()),
            text: this.text,
            title: this.title,
            type: this.type,
        }
    }
}

export default PushMessage
