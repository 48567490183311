import Entity from '_entities/base/EntityNew'

import User from '_entities/user/User'

export const BuildingTypeSchema = {
    name: 'BuildingType',
    primaryKey: 'id',
    properties: {
        code: { type: 'string' },
        id: { type: 'string' },
        name: { type: 'string' },
        isActive: { type: 'bool', default: false },
        deletedAt: { type: 'int' },
        deletedBy: { type: 'User', entity: User },
    },
}

class BuildingType extends Entity {
    get schema() {
        return BuildingTypeSchema
    }

    get dataMap() {
        return {
            is_active: 'isActive',
            deleted_at: 'deletedAt',
            deleted_by: 'deletedBy',
        }
    }

    get getIcon() {
        switch (this.code) {
            case 'building': return 'building'
            case 'parking': return 'car'
            case 'boiler_room': return 'temperature'
        }

        return ''
    }

    static fieldLabels = {
        code: 'Код',
        id: 'ID',
        name: 'Название',
    }
}

export default BuildingType
