<template>
    <div>
        <v-dialog
            v-model="isShownDialog"
            max-width="320"
            persistent
        >
            <v-sheet
                height="370"
                width="320"
                dark
                class="pa-5"
            >
                <div class="content">
                    <div v-if="resident">
                        <div class="caller">
                            <div>
                                <div>
                                    {{ residentInitials }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="caller unknown">
                            <div>
                                <div>
                                    <v-icon v-text="'$user__Solid'" />
                                    <div class="question">
                                        ?
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="name">
                        {{ residentName }}
                    </div>
                    <div class="time-at">
                        {{ timeAtLine }}
                    </div>
                    <div>
                        <v-btn
                            color="#00BA77"
                            width="240"
                            height="40"
                            @click="answerCall"
                        >
                            Ответить
                        </v-btn>
                    </div>
                </div>
            </v-sheet>
        </v-dialog>
        <audio id="ringtone" loop :src="ringtoneFile" />
    </div>
</template>

<script>
    import { decodeFromSeconds } from '_utils/datetime'

    export default {
        name: 'FIncomingCallDialog',

        data: () => ({
            tabTitle: document.title,
            ringtone: null,
        }),

        computed: {
            ringtoneFile() {
                return require('@/assets/sound/ringtone.wav')
            },

            resident() {
                return this.$store.state.sipCall.resident
            },

            residentName() {
                return this.resident?.fullName || 'Неизвестно'
            },

            residentInitials() {
                if (this.resident) {
                    const fistNameLetter = this.resident.firstName[0] || ''
                    const lastNameLetter = this.resident.lastName[0] || ''

                    return (fistNameLetter + lastNameLetter)?.toUpperCase()
                }

                return null
            },

            isAnsweredPhoneCall() {
                return this.$store.state.sipCall.isAnsweredPhoneCall
            },

            isIncomingPhoneCall() {
                return this.$store.state.sipCall.isIncomingPhoneCall
            },

            isShownDialog() {
                return this.isIncomingPhoneCall && !this.isAnsweredPhoneCall
            },

            timeAtIncoming() {
                return this.$store.state.sipCall.timeAtIncoming
            },

            timeAtLine() {
                const timeAtIncoming = decodeFromSeconds(this.timeAtIncoming)

                return String(timeAtIncoming.seconds).padStart(2, '0')
            },

            isAppActive() {
                return this.$store.state.global.isAppActive
            },
        },

        watch: {
            isShownDialog: {
                handler(isShownDialog) {
                    if (isShownDialog) {
                        this.$store.dispatch('sipCall/fetchCallInfo')
                        this.$store.commit('sipCall/startAtIncoming')

                        this.sendNotification()

                        this.ringtone.play()
                    } else {
                        this.$store.commit('sipCall/stopAtIncoming')

                        this.ringtone.pause()
                    }
                },
            },

            timeAtIncoming: {
                handler(timeAtIncoming) {
                    if (timeAtIncoming === 0) {
                        this.$store.commit('sipCall/cancelCallPhone')
                    }
                },
            },
        },

        mounted() {
            this.ringtone = document.getElementById('ringtone')
        },

        methods: {
            answerCall() {
                this.$store.commit('sipCall/answerCall')
            },

            sendNotification() {
                if (!this.isAppActive) {
                    if (window.Notification) {
                        return new Notification('SCW: входящий звонок!')
                    } else if (window.webkitNotifications) {
                        return window.webkitNotifications.createNotification(null, 'SCW: входящий звонок!', null)
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .caller {
        border: 1px solid #28333F;
        border-radius: 50%;
        font-size: 24px;
        font-weight: 700;
        margin: 16px;
        padding: 16px;
    }
    .caller > div {
        height: 64px;
        width: 64px;
        background-color: #28333F;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .caller > div > div {
        position: relative;
    }
    .question {
        height: 17px;
        width: 17px;
        background-color: #E5E9ED;
        border-radius: 50%;
        font-size: 12px;
        font-weight: 700;
        color: black;
        text-align: center;

        position: absolute;
        bottom: 10px;
        right: -24px;
    }

    .name {
        font-size: 18px;
        font-weight: 700;
        max-width: 200px;
        text-align: center;
        height: 80px;
    }

    .time-at {
        font-size: 36px;
        font-weight: 700;
        text-align: center;
        height: 80px;
    }
</style>
