import Entity from '_entities/base/EntityNew'

export const TicketMostProbableTypeSchema = {
    name: 'TicketMostProbableType',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
    },
}

class TicketMostProbableType extends Entity {
    get schema() {
        return TicketMostProbableTypeSchema
    }

    get dataMap() {
        return {
            ticket_type_id: 'id',
            typing_name: 'name',
        }
    }
}

export default TicketMostProbableType
