import axios from 'axios'
import { baseURL } from './config'
import store from '@/store'

const client = axios.create({
    baseURL,
    timeout: 60000, // 60 сек
    validateStatus: function (status) {
        if (status >= 500 && status < 600) {
            store.dispatch('maintenanceWork/fetchMaintenanceStatus')
        }

        return true
    },
})

client.interceptors.request.use(async (config) => {
    // Do something before request is sent

    // Обновляем токен, если необходимо
    await store.dispatch('auth/updateToken')

    const accessToken = localStorage.getItem('accessToken')
    const accessKeycloakToken = localStorage.getItem('accessKeycloakToken')

    if (accessToken) {
        config.headers.Authorization = `Token ${accessToken}`
    }
    if (accessKeycloakToken) {
        config.headers.Authorization = `Bearer ${accessKeycloakToken}`
    }

    return config
}, (e) => {
    // Do something with request error
    return Promise.reject(e)
})

export const request = async (type, url, params, clientParams = {}) => {
    try {
        let response
        switch (type) {
            case 'GET':
                response = await client.get(url, { params })
                break
            case 'GETFILE':
                response = await client.get(url, { responseType: 'blob', params })
                break
            case 'POSTFILE':
                response = await client.post(url, params, { responseType: 'blob', ...clientParams })
                break
            case 'POST':
                response = await client.post(url, params, clientParams)
                break
            case 'PUT':
                response = await client.put(url, params, clientParams)
                break
            case 'PATCH':
                response = await client.patch(url, params, clientParams)
                break
            case 'DELETE':
                response = await client.delete(url, params, clientParams)
                break
        }

        if (response?.data?.errors) {
            const errors = response.data.errors || []

            return Promise.reject(errors)
        }

        return response?.data
    } catch (e) {
        const errors = e?.response?.data?.errors || [{
            detail: e.message,
            inner_error: '',
        }]

        return Promise.reject(errors)
    }
}

export default client
