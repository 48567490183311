import gql from 'graphql-tag';

export const getAccountList = gql`
    query accountList($after: String, $first: Int, $before: String, $last: Int) {
    accountList(
        after: $after
        first: $first
        before: $before
        last: $last
    ) {
        edges {
            node {
                amoId
                balance
            }
        }
    }
}`

export const getAllGlobalFlatsSpecs = gql`
    query allGlobalFlatsSpecsV2($city: ID, $type: String) {
        allGlobalFlatsSpecsV2(city: $city, type: $type) {
            specs
        }
    }
`
