export const premiseTypes = [
    {
        label: 'Квартира',
        value: 'flat',
    },
    {
        label: 'Паркинг',
        value: 'parking',
    },
    {
        label: 'Коммерческая недвижимость',
        value: 'commercial',
    },
    {
        label: 'Кладовка',
        value: 'pantry',
    },
]
