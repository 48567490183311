import Entity from '_entities/base/EntityNew'

export const NotificationMessageSchema = {
    name: 'NotificationMessage',
    primaryKey: 'id',
    properties: {
        deliveryTime: { type: 'int' }, // UNIX Date
        entityId: { type: 'string' },
        entityType: { type: 'string' },
        id: { type: 'string' },
        isDataOnly: { type: 'bool', default: false },
        notificationType: { type: 'string' },
        text: { type: 'string' },
        title: { type: 'string' },
    },
}

class NotificationMessage extends Entity {
    get schema() {
        return NotificationMessageSchema
    }

    get dataMap() {
        return {
            delivery_time: 'deliveryTime',
            entity_id: 'entityId',
            entity_type: 'entityType',
            is_data_only: 'isDataOnly',
            notification_type: 'notificationType',
        }
    }

    static fieldLabels = {
        deliveryTime: 'Дата и время отправления',
        notificationType: 'Тип уведомления',
        text: 'Текст сообщения',
        title: 'Заголовок',
    }

    toArray() {
        return {
            is_data_only: this.isDataOnly,
            delivery_time: this.deliveryTime,
            notification_type: this.notificationType,
            text: this.text,
            title: this.title,
        }
    }
}

export default NotificationMessage
