<template>
    <v-dialog
        v-model="show"
        dark
        :max-width="options.width"
        scrollable
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
    >
        <v-card>
            <v-toolbar dark dense flat>
                <v-toolbar-title class="white--text">
                    Переадресация звонка
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-list>
                    <v-list-item-group v-model="worker">
                        <v-list-item
                            v-for="queueEntry in queueEntries"
                            :key="queueEntry.id"
                            :disabled="queueEntry.isBusy"
                        >
                            <v-list-item-avatar>
                                <v-img :src="getWorkerAvatar(queueEntry)" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ getWorkerName(queueEntry) }}</v-list-item-title>
                                <v-list-item-subtitle>{{ getWorkerDepartment(queueEntry) }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-icon
                                    v-if="queueEntry.isBusy"
                                    class="ml-3"
                                    v-text="'$phoneVolume__Solid'"
                                />
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer />
                <v-btn
                    text
                    @click.native="cancel"
                >
                    Отмена
                </v-btn>
                <v-btn
                    color="primary"
                    :disabled="!worker && !phone"
                    @click.native="agree"
                >
                    Переадресовать
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { map as _map } from 'lodash'

    import { API } from '_api'

    import QueueTimeEntry from '_entities/timeEntry/QueueTimeEntry'

    export default {
        name: 'FRedirectCallDialog',

        props: {
            valid: { type: Boolean },
            value: { type: Object, default: null },
        },

        data: () => ({
            dialog: false,
            resolve: null,
            reject: null,
            options: {
                color: 'primary',
                width: 600,
                zIndex: 200,
            },
            phone: null,
            queueEntries: [],
            worker: null,
        }),

        computed: {
            show: {
                get() {
                    return this.dialog
                },
                set(value) {
                    this.dialog = value
                    if (value === false) {
                        this.cancel()
                    }
                },
            },
        },

        methods: {
            async fetchData() {
                try {
                    const params = {
                        page: 1,
                        page_size: 100,
                        ordering: 'user',
                        is_open: true,
                    }

                    const response = await API.v1.services.timeManagement.getQueueEntries(params)

                    const { results } = response

                    const queueEntries = _map(results, (result) => new QueueTimeEntry(result))

                    this.queueEntries = queueEntries
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось получить список сотрудников на линии', e)
                    this.$logger.logException(e)
                }
            },

            getWorkerAvatar(queueEntry) {
                return queueEntry.user?.photo?.getSmall()?.url || null
            },

            getWorkerDepartment(queueEntry) {
                return queueEntry.department?.name || 'Без отдела'
            },

            getWorkerName(queueEntry) {
                return queueEntry.user?.fullName || 'Без имени'
            },

            open(options) {
                this.dialog = true
                this.options = Object.assign(this.options, options)
                this.worker = null

                this.fetchData()

                return new Promise((resolve, reject) => {
                    this.resolve = resolve
                    this.reject = reject
                })
            },

            agree() {
                this.resolve({ worker: this.worker, phone: this.phone })
                this.dialog = false
            },

            cancel() {
                this.resolve()
                this.dialog = false
            },
        },
    }
</script>
