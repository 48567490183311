import { request } from '_api/v1/base/behemoth/client'

export const getTickets = async (options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    return await request('POST', 'post/tickets/', filters, { params })
}

export const getTicketsByUserId = async (userId, params) => {
    return await request('GET', `ticket/${userId}/tickets`, params)
}

export const getTicketById = async (ticketId) => {
    const response = await request('GET', `ticket/${ticketId}/`)

    if (response?.coordinates && typeof response.coordinates !== 'object') {
        const coordinates = response.coordinates?.split(':')

        response.coordinates = {
            lat: coordinates[0],
            lon: coordinates[1],
        }
    }

    return response
}

export const changeTicketResponsible = async (ticketId, params) => {
    return await request('PUT', `ticket/${ticketId}/responsible/`, params)
}

export const changeTicketTyping = async (ticketId, params) => {
    return await request('PUT', `ticket/${ticketId}/retyping/`, params)
}

export const changeTicketExecutor = async (ticketId, params) => {
    return await request('PUT', `ticket/${ticketId}/executor/`, params)
}

export const changeTicketManager = async (ticketId, params) => {
    return await request('PUT', `ticket/${ticketId}/manager/`, params)
}

export const changeTicketAuditors = async (ticketId, params) => {
    return await request('PUT', `ticket/${ticketId}/auditors/`, params)
}

export const changeTicketDateControl = async (ticketId, params) => {
    return await request('PUT', `ticket/${ticketId}/date_control/`, params)
}

export const changeTicketComment = async (ticketId, params) => {
    return await request('PUT', `ticket/${ticketId}/comment/`, params)
}

export const changeTicketDatePlannedCompletion = async (ticketId, params) => {
    return await request('PUT', `ticket/${ticketId}/date_planned_completion/`, params)
}

export const changeDateSpecialistArrival = async (ticketId, params) => {
    return await request('PUT', `ticket/${ticketId}/date_specialist_arrival/`, params)
}

export const createTicket = async ({ data }) => {
    return await request('POST', 'ticket/', data)
}

export const createTicketByService = async (params) => {
    return await request('POST', 'ticket/service/', params)
}

export const getTicketsExcel = async (params) => {
    return await request('GETFILE', 'ticket/excel/', params)
}

export const getCancelReasons = async (params) => {
    return await request('GET', 'ticket/cancel_reason/', params)
}

export const getReturnReasons = async () => {
    return await request('GET', 'ticket/return_reason/')
}

export const returnToResponsible = async (ticketId, params) => {
    return await request('POST', `ticket/${ticketId}/return_to_responsible/`, params)
}

export const acceptTicketCompletion = async (ticketId, params) => {
    const _params = {
        ...params,
        is_done: true,
    }
    return await request('PUT', `ticket/${ticketId}/check_ticket_completion/`, _params)
}

export const rejectTicketCompletion = async (ticketId, params) => {
    const _params = {
        ...params,
        is_done: false,
    }
    return await request('PUT', `ticket/${ticketId}/check_ticket_completion/`, _params)
}

export const getTicketSources = async () => {
    return await request('GET', 'ticket/source/')
}

export const finishTicketWithoutCheck = async (ticketId, params) => {
    return await request('PUT', `ticket/${ticketId}/finish_ticket_without_check/`, params)
}

export const takeInWork = async (ticketId, params) => {
    return await request('PUT', `ticket/${ticketId}/take_in_work/`, params)
}

export const changeTicketByRefuser = async (ticketId) => {
    return await request('PUT', `ticket/${ticketId}/ticket_by_refuser/`)
}

export const getTicketDepartmentChangeHistoryById = async (ticketId) => {
    return await request('GET', `ticket/department_change_history/${ticketId}/`)
}

export const getTicketDepartmentChangeHistory = async (params) => {
    return await request('GET', 'ticket/department_change_history/', params)
}

export const getTicketActionComments = async (params) => {
    return await request('GET', 'ticket/action_comment/', params)
}

export const getTicketTypification = async (params) => {
    return await request('GET', 'ticket/typification/', params)
}

export const getTicketRetypingAvailability = async (params) => {
    return await request('POST', 'post/ticket/ticket_retyping_availability/', params)
}

// Linked tickets

export const getTicketChildrenById = async (ticketId) => {
    return await request('GET', `ticket/${ticketId}/children/`)
}

// Additional check

export const getTicketAdditionalChecks = async (params) => {
    return await request('GET', 'ticket/additional_check/', params)
}

export const getTicketAdditionalCheckById = async (additionalCheckId) => {
    return await request('GET', `ticket/additional_check/${additionalCheckId}/`)
}

export const addTicketAdditionalCheck = async (params) => {
    return await request('POST', 'ticket/additional_check/', params)
}

export const updateTicketAdditionalCheck = async (additionalCheckId, data) => {
    return await request('PUT', `ticket/additional_check/${additionalCheckId}/`, data)
}

export const deleteTicketAdditionalCheck = async (additionalCheckId) => {
    return await request('DELETE', `ticket/additional_check/${additionalCheckId}/`)
}

export const acceptTicketCompletionByAdditionalRewiever = async (ticketId, params) => {
    const _params = {
        ...params,
        is_done: true,
    }
    return await request('PUT', `ticket/${ticketId}/check_by_additional_reviewer/`, _params)
}

export const rejectTicketCompletionByAdditionalRewiever = async (ticketId, params) => {
    const _params = {
        ...params,
        is_done: false,
    }
    return await request('PUT', `ticket/${ticketId}/check_by_additional_reviewer/`, _params)
}

export const getTicketDepartmentByUserGroup = async (params) => {
    return await request('POST', 'ticket/ticket_department/user_group/', params)
}

export const saveTicketDepartmentsForGroup = async (params) => {
    return await request('POST', 'ticket/user_group/save_ticket_departments/', params)
}

export const getUserGroupByTicketDepartment = async (params) => {
    return await request('POST', 'ticket/user_group/ticket_department/', params)
}

export const saveGroupsForTicketDepartment = async (params) => {
    return await request('POST', 'ticket/ticket_department/save_groups/', params)
}

// Reject reasons

export const getTicketRejectReasons = async (params) => {
    return await request('GET', 'ticket/reject_reason/', params)
}

export const changePlannedAmountForTicket = async (ticketId, params) => {
    return await request('PUT', `ticket/${ticketId}/planned_amount/`, params)
}

export const changeActualAmountForTicket = async (ticketId, params) => {
    return await request('PUT', `ticket/${ticketId}/actual_amount/`, params)
}

// Critical settings

export const getTicketCriticalSettings = async (params) => {
    return await request('GET', 'ticket/critical_setting/', params)
}

export const createTicketCriticalSetting = async (params) => {
    return await request('POST', 'ticket/critical_setting/', params)
}

export const updateTicketCriticalSetting = async (ticketCriticalSettingId, params) => {
    return await request('PUT', `ticket/critical_setting/${ticketCriticalSettingId}/`, params)
}

// Timers

export const getTicketTypeTimersSettingById = async (ticketTypeId) => {
    return await request('GET', `ticket/ticket_type/${ticketTypeId}/timers/`)
}

export const updateTicketTypeTimersSetting = async (ticketTypeId, params) => {
    return await request('PUT', `ticket/ticket_type/${ticketTypeId}/change_timers/`, params)
}

// Checklist

export const changeTicketChecklist = async (ticketId, params) => {
    return await request('POST', `ticket/${ticketId}/change_checklist/`, params)
}

// Critical report

export const composeReportCritacal = async (options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    return await request('POSTFILE', 'ticket/compose_report_critical/', filters, { params })
}

// Semantic AI search

export const getTicketSemanticSimilar = async (params) => {
    return await request('POST', 'ticket/semantic/similar/', params)
}
