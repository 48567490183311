<template>
    <div>
        <v-snackbar
            v-model="isShownDialog"
            left
            timeout="-1"
        >
            <v-row v-if="!isExpanded" dense align="center">
                <v-col cols="auto">
                    <v-btn icon @click="isExpanded = !isExpanded">
                        <v-icon x-small v-text="'$chevronUp'" />
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    {{ timeAtLine }}
                </v-col>
                <v-col v-if="isOutgoingPhoneCall && !isAnsweredPhoneCall" cols="auto">
                    <div class="indicator-container">
                        <div
                            class="call-status-indicator"
                            :class="{ connecting: !isAnsweredPhoneCall, connected: isAnsweredPhoneCall }"
                        />
                    </div>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <v-btn
                        color="red"
                        rounded
                        small
                        @click="hangup"
                    >
                        <v-icon small v-text="'$phoneHangup__Solid'" />
                    </v-btn>
                </v-col>
            </v-row>
            <template v-else>
                <v-row dense align="center">
                    <v-col cols="auto">
                        <v-btn icon @click="isExpanded = !isExpanded">
                            <v-icon x-small v-text="'$chevronDown'" />
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        {{ timeAtLine }}
                    </v-col>
                    <v-col v-if="isOutgoingPhoneCall && !isAnsweredPhoneCall" cols="auto">
                        <div class="indicator-container">
                            <div
                                class="call-status-indicator"
                                :class="{ connecting: !isAnsweredPhoneCall, connected: isAnsweredPhoneCall }"
                            />
                        </div>
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto">
                        {{ residentName }}
                    </v-col>
                    <v-col v-if="phoneNumber && isAllowPhoneCopy" cols="auto">
                        <v-btn icon @click="copyPhone">
                            <v-icon x-small v-text="'$clone'" />
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row dense align="center">
                    <v-col cols="auto">
                        <v-btn
                            v-if="isPaused"
                            icon
                            :style="{backgroundColor: 'white'}"
                            @click="pausing"
                        >
                            <v-icon small color="black" v-text="'$play__Solid'" />
                        </v-btn>
                        <v-btn
                            v-else
                            icon
                            @click="pausing"
                        >
                            <v-icon small v-text="'$pause__Solid'" />
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            v-if="isMuted"
                            icon
                            :style="{backgroundColor: 'white'}"
                            @click="muting"
                        >
                            <v-icon small color="black" v-text="'$microphoneSlash__Solid'" />
                        </v-btn>
                        <v-btn
                            v-else
                            icon
                            @click="muting"
                        >
                            <v-icon small v-text="'$microphone__Solid'" />
                        </v-btn>
                    </v-col>
                    <template v-if="isIncomingPhoneCall && isAnsweredPhoneCall">
                        <v-col cols="auto">
                            <v-btn
                                icon
                                @click="redirectCall"
                            >
                                <v-icon small v-text="'$phoneArrowRight__Solid'" />
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <router-link :to="{ name: 'TicketCreate', params: { isFromCall: true } }" target="_blank">
                                <v-icon small v-text="'$file__Solid'" />
                            </router-link>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn
                                v-if="resident"
                                icon
                                @click="editCallerInfo"
                            >
                                <v-icon small v-text="'$pen__Solid'" />
                            </v-btn>
                        </v-col>
                    </template>
                    <v-spacer />
                    <v-col cols="auto">
                        <v-btn
                            color="red"
                            rounded
                            @click="hangup"
                        >
                            <v-icon v-text="'$phoneHangup__Solid'" />
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </v-snackbar>

        <FRedirectCallDialog ref="redirectCallDialog" />
        <FCallerEditDialog ref="callerEditDialog" />
    </div>
</template>

<script>
    import FCallerEditDialog from '_features/sipCall/components/FCallerEditDialog'
    import FRedirectCallDialog from '_features/sipCall/components/FRedirectCallDialog'

    import { decodeFromSeconds } from '_utils/datetime'

    export default {
        name: 'FPhonePanelNew',

        components: {
            FCallerEditDialog,
            FRedirectCallDialog,
        },

        props: {
            expanded: { type: Boolean, default: false },
        },

        data: () => ({
            localExpanded: false,
        }),

        computed: {
            isExpanded: {
                get() {
                    return this.localExpanded
                },
                set(value) {
                    this.localExpanded = value
                },
            },

            isPaused() {
                return this.$store.state.sipCall.isPaused
            },

            resident() {
                return this.$store.state.sipCall.resident
            },

            residentName() {
                return this.resident?.fullName || 'Неизвестный контакт'
            },

            isAllowPhoneCopy() {
                return this.$store.state.sipCall.isAllowPhoneCopy
            },

            isMuted() {
                return this.$store.state.sipCall.isMuted
            },

            isCallRefered() {
                return this.$store.state.sipCall.isCallRefered
            },

            isOutgoingPhoneCall() {
                return this.$store.state.sipCall.isOutgoingPhoneCall
            },

            isAnsweredPhoneCall() {
                return this.$store.state.sipCall.isAnsweredPhoneCall
            },

            isIncomingPhoneCall() {
                return this.$store.state.sipCall.isIncomingPhoneCall
            },

            isShownDialog() {
                return (this.isIncomingPhoneCall && this.isAnsweredPhoneCall) || this.isOutgoingPhoneCall
            },

            timeAtLine() {
                const timeAtLine = decodeFromSeconds(this.$store.state.sipCall.timeAtLine)

                return String(timeAtLine.minutes).padStart(2, '0') + ':' + String(timeAtLine.seconds).padStart(2, '0')
            },

            phoneNumber() {
                return this.$store.state.sipCall.phoneNumber
            },
        },

        watch: {
            isShownDialog: {
                handler(isShownDialog) {
                    if (isShownDialog) {
                        this.$store.commit('sipCall/startAtLineTimer')
                    } else {
                        this.$store.commit('sipCall/stopAtLineTimer')
                    }
                },
            },

            isCallRefered: {
                handler(isCallRefered) {
                    if (isCallRefered) {
                        this.hangup()
                    }
                },
            },
        },

        mounted() {
            this.localExpanded = this.expanded
        },

        methods: {
            pausing() {
                this.$store.commit('sipCall/switchPause')
            },

            muting() {
                this.$store.commit('sipCall/switchMute')
            },

            hangup() {
                this.$store.commit('sipCall/cancelCallPhone')
            },

            async redirectCall() {
                const { worker, phone } = await this.$refs.redirectCallDialog.open()

                if (worker?.phone) {
                    this.$store.commit('sipCall/redirectCallPhone', worker.phone)
                } else if (phone) {
                    this.$store.commit('sipCall/redirectCallPhone', phone)
                }
            },

            editCallerInfo() {
                if (this.$refs.callerEditDialog.open()) {
                    this.$store.dispatch('sipCall/fetchCallInfo', this.phoneNumber)
                }
            },

            copyPhone() {
                navigator.clipboard.writeText(this.phoneNumber)

                this.$root.pushNotification({
                    text: 'Номер телефона скопирован',
                    color: 'green',
                })
            },
        },
    }
</script>

<style scoped>
    .indicator-container {
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .call-status-indicator {
        border-radius: 50%;
        height: 20px;
        width: 20px;
    }

    .connected {
        height: 15px;
        width: 15px;
        background-color:rgb(48, 194, 48);
    }

    .connecting {
        background-color: gold;
        animation-name: connecting;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-out;
    }

    @keyframes connecting {
        from {
            height: 6px;
            width: 6px;
            opacity: 1;
        }
        to {
            width: 20px;
            height: 20px;
            opacity: 0;
        }
    }
</style>
