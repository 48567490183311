import { print } from 'graphql'

export function createGraphqlRequest(axiosApiInstance) {
    return async function (query, variables) {
        let gqErrors

        const res = await axiosApiInstance.request({
            method: 'post',
            data: {
                query: print(query),
                variables,
            },
        });

        if (res.data?.errors?.length) {
            gqErrors = res.data?.errors

            throw new Error(JSON.stringify(res.data?.errors))
        }

        return {
            ...res?.data?.data,
            gqErrors,
        }
    }
}
