<template>
    <v-card :flat="flat == undefined || flat == false ? false : true">
        <v-card-text>
            <v-row dense align="center">
                <v-spacer />
                <v-col cols="auto">
                    <v-btn
                        :color="color"
                        :disabled="!loaded"
                        icon
                        outlined
                        @click="playing ? pause() : play()"
                    >
                        <v-icon v-if="!playing || paused" small v-text="'$play'" />
                        <v-icon v-else small v-text="'$pause'" />
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn
                        :color="color"
                        :disabled="!loaded"
                        icon
                        outlined
                        @click="stop()"
                    >
                        <v-icon small v-text="'$stop'" />
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn
                        :color="color"
                        :disabled="!loaded"
                        icon
                        outlined
                        @click="mute()"
                    >
                        <v-icon v-if="!isMuted" small v-text="'$volume'" />
                        <v-icon v-else small v-text="'$volumeMute'" />
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn
                        v-if="loaded && downloadable"
                        :color="color"
                        icon
                        outlined
                        @click="loaded ? download() : reload()"
                    >
                        <v-icon v-text="'$download'" />
                    </v-btn>
                </v-col>
                <v-col cols="1">
                    <v-slider
                        v-model="playerVolume"
                        hide-details
                        max="1"
                        min="0"
                        step="0.01"
                    />
                </v-col>
                <v-spacer />
            </v-row>
            <v-row>
                <v-col>
                    <v-progress-linear
                        v-model="percentage"
                        :disabled="!loaded"
                        height="5"
                        @change="setPosition"
                    />
                </v-col>
            </v-row>
            <p>{{ currentTime }} / {{ duration }}</p>
        </v-card-text>
        <audio
            id="player"
            ref="player"
            :src="file"
            @ended="ended"
            @canplay="canPlay"
        />
    </v-card>
</template>

<script>
    // компонент позаимствован отсюда: https://github.com/wilsonwu/vuetify-audio

    const formatTime = (second) => new Date(second * 1000)?.toISOString()?.substr(11, 8)
    export default {
        name: 'UIAudioPlayer',
        props: {
            autoPlay: { type: Boolean, default: false },
            canPlay: { type: Function, default: () => {} },
            color: { type: String, default: 'primary' },
            downloadable: { type: Boolean, default: false },
            ended: { type: Function, default: () => {} },
            file: { type: String, default: null },
            flat: { type: Boolean, default: false },
        },

        data() {
            return {
                audio: undefined,
                currentTime: '00:00:00',
                firstPlay: true,
                isMuted: false,
                loaded: false,
                paused: false,
                percentage: 0,
                playing: false,
                playerVolume: 0.5,
                totalDuration: 0,
            }
        },

        computed: {
            duration: function () {
                return this.audio ? formatTime(this.totalDuration) : ''
            },
        },

        mounted() {
            this.audio = this.$refs.player
            this.init()
        },

        beforeDestroy() {
            this.audio.removeEventListener('timeupdate', this._handlePlayingUI)
            this.audio.removeEventListener('loadeddata', this._handleLoaded)
            this.audio.removeEventListener('pause', this._handlePlayPause)
            this.audio.removeEventListener('play', this._handlePlayPause)
            this.audio.removeEventListener('ended', this._handleEnded)
        },

        methods: {
            setPosition() {
                this.audio.currentTime = parseInt(this.audio.duration / 100 * this.percentage)
                this.currentTime = formatTime(this.audio.currentTime)
            },

            stop() {
                this.audio.pause()
                this.paused = true
                this.playing = false
                this.audio.currentTime = 0
                this.currentTime = '00:00:00'
            },

            play() {
                if (this.playing) return
                this.audio.play().then(this.playing = true)
                this.paused = false
            },

            pause() {
                this.paused = !this.paused;
                (this.paused) ? this.audio.pause() : this.audio.play()
            },

            download() {
                this.audio.pause()
                window.open(this.file, 'download')
            },

            mute() {
                this.isMuted = !this.isMuted
                this.audio.muted = this.isMuted
            },

            reload() {
                this.audio.load()
            },

            _handleLoaded: function () {
                if (this.audio.readyState >= 2) {
                    if (this.audio.duration === Infinity) {
                        // Fix duration for streamed audio source or blob based
                        // https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome/39971175#39971175
                        this.audio.currentTime = 1e101
                        this.audio.ontimeupdate = () => {
                            this.audio.ontimeupdate = () => {}
                            this.audio.currentTime = 0
                            this.totalDuration = parseInt(this.audio.duration)
                            this.loaded = true
                        }
                    } else {
                        this.totalDuration = parseInt(this.audio.duration)
                        this.loaded = true
                    }
                    if (this.autoPlay) this.audio.play()
                } else {
                    throw new Error('Failed to load sound file')
                }
            },

            _handlePlayingUI: function (e) {
                this.audio.volume = this.playerVolume
                this.percentage = this.audio.currentTime / this.audio.duration * 100
                this.currentTime = formatTime(this.audio.currentTime)
            },

            _handleEnded() {
                this.paused = this.playing = false
            },

            init: function () {
                this.audio.addEventListener('timeupdate', this._handlePlayingUI)
                this.audio.addEventListener('loadeddata', this._handleLoaded)
                this.audio.addEventListener('pause', this._handlePlayPause)
                this.audio.addEventListener('play', this._handlePlayPause)
                this.audio.addEventListener('ended', this._handleEnded)
            },
        },
    }
</script>
