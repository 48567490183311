import Entity from '_entities/base/EntityNew'

export const TicketMostProbableExecutorSchema = {
    name: 'TicketMostProbableExecutor',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
    },
}

class TicketMostProbableExecutor extends Entity {
    get schema() {
        return TicketMostProbableExecutorSchema
    }

    get dataMap() {
        return {
            executor_id: 'id',
            executor_name: 'name',
        }
    }
}

export default TicketMostProbableExecutor
