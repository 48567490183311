import Entity from '_entities/base/EntityNew'

import FileAudio from '_entities/media/file/FileAudio'
import FileDocument from '_entities/media/file/FileDocument'
import FileImage from '_entities/media/file/FileImage'
import FileVideo from '_entities/media/file/FileVideo'

export const FileItemSchema = {
    name: 'FileItem',
    primaryKey: 'id',
    properties: {
        audio: { type: 'FileAudio', entity: FileAudio },
        document: { type: 'FileDocument', entity: FileDocument },
        id: { type: 'string' },
        image: { type: 'FileImage', entity: FileImage },
        type: { type: 'string' },
        video: { type: 'FileVideo', entity: FileVideo },
    },
}

class FileItem extends Entity {
    get schema() {
        return FileItemSchema
    }

    getFile() {
        return this.type ? this[this.type] : null
    }
}

export default FileItem
