import Entity from '_entities/base/EntityNew'

import User from '_entities/user/User'
import UserLink from '_entities/user/UserLink'

export const DepartmentSchema = {
    name: 'Department',
    primaryKey: 'id',
    properties: {
        head: { type: 'UserLink', entity: UserLink }, // UUID
        id: { type: 'string' },
        name: { type: 'string' },
        parent: { type: 'self' },
        isActive: { type: 'bool', default: false },
        deletedAt: { type: 'int' },
        deletedBy: { type: 'User', entity: User },
    },
}

class Department extends Entity {
    get schema() {
        return DepartmentSchema
    }

    get dataMap() {
        return {
            is_active: 'isActive',
            deleted_at: 'deletedAt',
            deleted_by: 'deletedBy',
        }
    }

    static fieldLabels = {
        head: 'Начальник отдела',
        id: 'ID отдела',
        name: 'Наименование',
        parent: 'Родительский отдел',
    }

    toArray() {
        const data = super.toArray()

        return {
            ...data,
            head: this.head?.id || null,
            parent: this.parent?.id || null,
        }
    }
}

export default Department
