import { orderBy as _orderBy } from 'lodash'

import { request } from '_api/v1/base/behemoth/client'

import { splitAPIOptions } from '_api/utils/tools'

export const getCalls = async (params) => {
    const response = await request('GET', 'call/', params)

    if (response) {
        response.results = response?.results?.map((call) => {
            call.comments = _orderBy(call.comments, ['date_time'], ['desc'])
            return call
        })
    }

    return response
}

export const removeFromRecallSection = async (phone) => {
    return await request('GET', `call/remove_from_recall/${phone}/`)
}

export const getCallRecord = async (callId) => {
    return await request('GET', `call/${callId}/record/`)
}

export const addCallComment = async (callId, params) => {
    return await request('POST', `call/${callId}/comment/`, params)
}

export const getCallsReport = async (params) => {
    return await request('GETFILE', 'call/report/', params)
}

export const getUnrecallCalls = async (params) => {
    let response = await getCallDirections()

    const callDirections = response?.results || []

    response = await request('GET', 'call/unrecall/', params)

    if (response?.results?.length) {
        response.results.forEach((unrecallCall) => {
            if (typeof unrecallCall.direction === 'string') {
                if (unrecallCall.direction_entity) {
                    unrecallCall.direction = unrecallCall.direction_entity
                } else {
                    unrecallCall.direction = callDirections.find((callDirection) => callDirection.name === unrecallCall.direction)
                }
            }
        })
    }

    return response
}

export const getCallDirections = async (params) => {
    return await request('POST', 'call/call_direction/', params)
}

export const getUnrecallCallsCount = async (options) => {
    const { filters, params } = splitAPIOptions(options)

    return await request('POST', 'call/qty_recall_group/', filters, { params })
}
