import Entity from '_entities/base/EntityNew'

export const ServiceDiscountCardCategorySchema = {
    name: 'ServiceDiscountCardCategory',
    primaryKey: 'id',
    properties: {
        code: { type: 'string' },
        id: { type: 'string' },
        name: { type: 'string' },
    },
}

class ServiceDiscountCardCategory extends Entity {
    get schema() {
        return ServiceDiscountCardCategorySchema
    }

    static fieldLabels = {
        code: 'Символьный код',
        id: 'ID',
        name: 'Название',
    }

    toServer() {
        return {
            code: this.code,
            id: this.id,
            name: this.name,
        }
    }
}

export default ServiceDiscountCardCategory
