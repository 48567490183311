import { map as _map } from 'lodash'

import Entity from '_entities/base/EntityNew'

import ChecklistTemplate from '_entities/checklist/ChecklistTemplate'
import Doc from '_entities/media/Doc'
import TicketCancelReason from '_entities/ticket/TicketCancelReason'
import TicketCategory from '_entities/ticket/TicketCategory'
import TicketKindLink from '_entities/ticket/TicketKindLink'
import TicketRejectReason from '_entities/ticket/TicketRejectReason'
import TicketTypeParentLink from '_entities/ticket/TicketTypeParentLink'
import User from '_entities/user/User'

export const TicketTypeSchema = {
    name: 'TicketType',
    primaryKey: 'id',
    properties: {
        assignmentType: { type: 'string' }, // 'assigned' || 'planned' || 'emergency' || 'critical'
        cancelReasons: { type: 'TicketCancelReason[]', entity: TicketCancelReason, default: [] },
        checklistTemplate: { type: 'ChecklistTemplate[]', entity: ChecklistTemplate, default: () => [] },
        code: { type: 'string' },
        executionTerm: { type: 'int' },
        executionTermSeconds: { type: 'int' },
        id: { type: 'string' },
        instruction: { type: 'Doc', entity: Doc, optional: true },
        isAutoAppointed: { type: 'bool', default: false },
        level: { type: 'int', default: 3 },
        name: { type: 'string' },
        order: { type: 'int' },
        parent: { type: 'TicketTypeParentLink', entity: TicketTypeParentLink },
        requestCancelReason: { type: 'boolean' },
        ticketCategory: { type: 'TicketCategory', entity: TicketCategory },
        ticketKind: { type: 'TicketKindLink', entity: TicketKindLink },
        ticketRejectReasons: { type: 'TicketRejectReason[]', entity: TicketRejectReason, default: [] },
        timeToRefine: { type: 'int', default: 0 },
        timeToRefineSeconds: { type: 'int', default: 0 },
        timeToRefineType: { type: 'string', default: 'seconds' },
        isActive: { type: 'bool', default: false },
        deletedAt: { type: 'int' },
        deletedBy: { type: 'User', entity: User },
    },
}

class TicketType extends Entity {
    get schema() {
        return TicketTypeSchema
    }

    get dataMap() {
        return {
            assignment_type: 'assignmentType',
            cancel_reasons: 'cancelReasons',
            checklist_template: 'checklistTemplate',
            execution_term: 'executionTerm',
            execution_term_seconds: 'executionTermSeconds',
            is_auto_appointed: 'isAutoAppointed',
            request_cancel_reason: 'requestCancelReason',
            ticket_category: 'ticketCategory',
            ticket_kind: 'ticketKind',
            ticket_reject_reasons: 'ticketRejectReasons',
            time_to_refine: 'timeToRefine',
            time_to_refine_seconds: 'timeToRefineSeconds',
            is_active: 'isActive',
            deleted_at: 'deletedAt',
            deleted_by: 'deletedBy',
        }
    }

    static fieldLabels = {
        assignmentType: 'Класс заявки',
        cancelReasons: 'Причины отмены',
        code: 'Код',
        checklistTemplate: 'Чеклист',
        executionTerm: 'Время выполнения (план), минуты',
        executionTermSeconds: 'Время выполнения (план)',
        id: 'ID',
        instruction: 'Инструкция',
        isAutoAppointed: 'Автоматическое назначение исполнителя из создателя заявки и перевод заявки на стадию "В работе"',
        name: 'Название',
        order: 'Порядок',
        requestCancelReason: 'Запрос причины отмены',
        ticketKind: 'Вид заявки',
        ticketRejectReasons: 'Причины отклонения',
        timeToRefine: 'Кол-во времени, минуты',
        timeToRefineSeconds: 'Время на доработку заявки',
    }

    get getAssignmentType() {
        switch (this.assignmentType) {
            case 'assigned': return 'Платная'
            case 'planned': return 'Плановая'
            case 'emergency': return 'Аварийная'
            case 'critical': return 'ЧС'
            default: return this.assignmentType
        }
    }

    static timeToRefineTypes = [
        { value: 'seconds', text: 'секунды' },
        { value: 'minutes', text: 'минуты' },
        { value: 'hours', text: 'часы' },
        { value: 'days', text: 'дни' },
    ]

    toArray() {
        return {
            ...super.toArray(),
            cancel_reasons: _map(this.cancelReasons, (cancelReason) => {
                return {
                    code: cancelReason.code,
                    order: cancelReason.order,
                }
            }),
            deleted_by: this.deletedBy?.id,
            execution_term: this.executionTermSeconds ? Math.round(this.executionTermSeconds) : null,
            execution_term_seconds: this.executionTermSeconds ? Math.round(this.executionTermSeconds) : null,
            instruction: this.instruction?.id || null,
            is_auto_appointed: this.isAutoAppointed,
            level: this.level,
            parent: this.parent?.id || null,
            request_cancel_reason: this.requestCancelReason,
            ticket_category: this.ticketCategory?.id,
            ticket_kind: this.ticketKind?.id,
            ticket_reject_reasons: _map(this.ticketRejectReasons, (ticketRejectReason) => ticketRejectReason.id),
            time_to_refine: this.timeToRefineSeconds ? Number(this.timeToRefineSeconds) : null,
            time_to_refine_seconds: this.timeToRefineSeconds ? Number(this.timeToRefineSeconds) : null,
        }
    }
}

export default TicketType
