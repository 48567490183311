<template>
    <UIPage>
        <UIPageHeader :title="pageTitle" />

        <v-form ref="pushMessageForm" v-model="isValidForm" :disabled="isFormDisabled">
            <CSection>
                <v-card v-if="canSelectMode" :loading="isFetching" class="mb-5">
                    <CContainer>
                        <v-card-title class="pa-0 black--text">
                            Добавить push-уведомление
                        </v-card-title>
                        <v-radio-group v-model="pushType">
                            <v-radio value="sample">
                                <template v-slot:label>
                                    <h3>По шаблону</h3>
                                </template>
                            </v-radio>
                            <v-radio value="free">
                                <template v-slot:label>
                                    <h3>В свободной форме</h3>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </CContainer>
                </v-card>

                <v-card v-if="isSample && canSelectMode" :loading="isFetching" class="mb-5">
                    <CContainer>
                        <v-card-title class="pa-0 mb-5 black--text">
                            Выберите шаблон
                        </v-card-title>
                        <CPushMessagesTemplateSelection
                            :field-labels="fieldLabels"
                            @addSample="addSample"
                        />
                    </CContainer>
                </v-card>

                <v-card :loading="isFetching" class="mb-5">
                    <CContainer>
                        <v-card-title class="pa-0 mb-5 black--text">
                            Настройка отправки
                        </v-card-title>
                        <CPushMessageTypesSelect
                            v-model="pushMessage.type"
                            :disabled="pushType === 'sample'"
                            :rules="[rules.required]"
                        />
                        <FServicePicker
                            v-if="pushMessage.type === 'service'"
                            v-model="pushMessage.service"
                            :rules="[isServiceRequired]"
                        />
                        <UIDateTimePicker
                            v-model="pushMessage.date"
                            :label="fieldLabels.date"
                            :min="minDate"
                            :rules="[rules.required]"
                        />
                        <UIDateTimePicker
                            v-model="pushMessage.dateCreateAccount"
                            :label="fieldLabels.dateCreateAccount"
                        />
                        <FCCompaniesSelect
                            v-model="pushMessage.company"
                            :rules="[rules.requiredObject]"
                        />
                        <FBuildingPicker
                            v-model="pushMessage.buildings"
                            :label="fieldLabels.buildings"
                            multiple
                            :rules="[rules.requiredArray]"
                        />
                        <FEntrancePicker
                            v-model="pushMessage.location.entrances"
                            :api-filter="entranceFilter"
                            :disabled="!pushMessage.buildings || pushMessage.buildings.length === 0"
                            :label="locationFieldLabels.entrances"
                            multiple
                        />
                        <FPipePicker
                            v-model="pushMessage.location.pipes"
                            :api-filter="pipeFilter"
                            :disabled="notEntrancePresent"
                            :label="locationFieldLabels.pipes"
                            multiple
                        />
                        <v-select
                            v-model="pushMessage.location.floors"
                            :disabled="notEntrancePresent"
                            :items="floors"
                            :label="locationFieldLabels.floors"
                            multiple
                            outlined
                        />
                        <v-autocomplete
                            v-model="pushMessage.location.premises"
                            clearable
                            item-text="number"
                            item-value="id"
                            :items="filteredPremises"
                            :label="locationFieldLabels.premises"
                            multiple
                            outlined
                            return-object
                        />
                        <v-switch
                            v-model="pushMessage.isPersonal"
                            :disabled="isPersonal"
                            :label="fieldLabels.isPersonal"
                        />
                    </CContainer>
                </v-card>

                <v-card :loading="isFetching" class="mb-5">
                    <CContainer>
                        <v-card-title class="pa-0 mb-5 black--text">
                            Заголовок
                        </v-card-title>
                        <v-text-field
                            v-model="pushMessage.title"
                            clearable
                            :label="fieldLabels.title"
                            outlined
                            :rules="[rules.required]"
                            :disabled="isSample"
                        />
                        <v-card-title class="pa-0 mb-5 black--text">
                            Текст публикаци
                        </v-card-title>
                        <UITextarea
                            v-model="pushMessage.text"
                            clearable
                            html-editor
                            :label="fieldLabels.text"
                            outlined
                            :rules="[rules.required]"
                            :disabled="isSample"
                        />
                        <v-alert v-if="isSample" outlined type="info" color="blue">
                            Нижезаполненные поля будут добавлены в текст уведомления
                            автоматически при отправке
                        </v-alert>
                        <template v-if="templateValuesModel?.length">
                            <div
                                v-for="field in templateValuesModel"
                                :key="field.id"
                            >
                                <component
                                    :is="getFieldComponent(field.type)"
                                    v-if="isDictionary(field)"
                                    v-model="field.value"
                                    :label="field.name"
                                    :items="field.values"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    :rules="field.isRequired ? [requiredNotEmptyObject] : []"
                                />
                                <template v-else>
                                    <component
                                        :is="getFieldComponent(field.type)"
                                        v-if="field.type === 'phone'"
                                        v-model="field.value.name"
                                        :label="field.name"
                                        :disabled="!isEnabledPhoneEnter"
                                        :mode="isEnabledPhoneEnter ? 'office' : 'free'"
                                        :rules="field.isRequired ? [rules.required] : []"
                                    />
                                    <component
                                        :is="getFieldComponent(field.type)"
                                        v-else-if="field.type === 'date' && sample?.nextDayDateLimit"
                                        v-model="field.value.name"
                                        :label="field.name"
                                        :items="field.values"
                                        :max="maxNextDayDateLimit"
                                        :min="minNextDayDateLimit"
                                        :rules="field.isRequired ? [rules.required] : []"
                                    />
                                    <component
                                        :is="getFieldComponent(field.type)"
                                        v-else-if="field.type === 'date' && field.code === 'date_start'"
                                        v-model="field.value.name"
                                        :label="field.name"
                                        :items="field.values"
                                        :max="maxDateStart"
                                        :rules="field.isRequired ? [rules.required] : []"
                                    />
                                    <component
                                        :is="getFieldComponent(field.type)"
                                        v-else-if="field.type === 'date' && field.code === 'date_finish'"
                                        v-model="field.value.name"
                                        :label="field.name"
                                        :items="field.values"
                                        :min="minDateFinish"
                                        :rules="field.isRequired ? [rules.required] : []"
                                    />
                                    <component
                                        :is="getFieldComponent(field.type)"
                                        v-else-if="field.type === 'time' && field.code === 'time_start'"
                                        v-model="field.value.name"
                                        :label="field.name"
                                        :items="field.values"
                                        :max="maxTimeStart"
                                        :rules="field.isRequired ? [rules.required] : []"
                                    />
                                    <component
                                        :is="getFieldComponent(field.type)"
                                        v-else-if="field.type === 'time' && field.code === 'time_finish'"
                                        v-model="field.value.name"
                                        :label="field.name"
                                        :items="field.values"
                                        :min="minTimeFinish"
                                        :rules="field.isRequired ? [rules.required] : []"
                                    />
                                    <component
                                        :is="getFieldComponent(field.type)"
                                        v-else
                                        v-model="field.value.name"
                                        :label="field.name"
                                        :items="field.values"
                                        :rules="field.isRequired ? [rules.required] : []"
                                    />
                                </template>
                            </div>
                        </template>
                    </CContainer>
                </v-card>
            </CSection>
        </v-form>

        <CFooter>
            <CContainer>
                <v-row justify="space-between" no-gutters>
                    <v-col cols="auto" />
                    <v-col cols="auto">
                        <v-btn color="primary" text @click="goBack()">
                            Назад
                        </v-btn>
                        <v-btn
                            class="ml-2"
                            color="primary"
                            :disabled="!isValidForm || isFormDisabled"
                            :loading="isSaving"
                            @click="saveHandler"
                        >
                            Сохранить и отправить
                        </v-btn>
                    </v-col>
                </v-row>
            </CContainer>
        </CFooter>

        <v-dialog
            v-model="isShownCheckDialog"
            width="800"
        >
            <v-card flat>
                <v-card-text>
                    <v-card-title class="my-5 black--text">
                        Пожалуйста, подтвердите отправку push-уведомления
                    </v-card-title>
                    <v-card-title class="pa-0 mb-5 black--text">
                        Заголовок
                    </v-card-title>
                    <v-text-field
                        v-model="pushMessage.title"
                        outlined
                        readonly
                    />
                    <v-card-title class="pa-0 mb-5 black--text">
                        Текст публикаци
                    </v-card-title>
                    <UITextarea
                        v-model="templateText"
                        outlined
                        readonly
                    />
                    <template v-if="!templateCheckResult?.isUnique">
                        <v-card flat class="card-warning" color="rgb(255,245,245)">
                            <v-card-text>
                                <v-card-title>
                                    <v-icon color="red" class="mr-3" v-text="'$triangleExclamation'" /> Уведомление уже было создано
                                </v-card-title>
                                <div class="text-body-1 ml-13">
                                    Идентичное сообщение уже создал пользователь {{ getUserName() }} {{ getTime() }}
                                </div>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        text
                        @click="isShownCheckDialog = false"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="saveAndPush"
                    >
                        {{ templateCheckResult?.isUnique ? 'Сохранить и отправить' : 'Все равно отправить' }}
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="clearPushMessage"
                    >
                        Удалить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </UIPage>
</template>

<script>
    import moment from 'moment'

    import { API } from '_api'

    import {
        filter as _filter,
        forEach as _forEach,
        map as _map,
        max as _max,
        min as _min,
        orderBy as _orderBy,
        range as _range,
        uniqBy as _uniqBy,
    } from 'lodash'

    import AccidentCompany from '_entities/accident/AccidentCompany'
    import Entrance from '_entities/entrance/Entrance'
    import Premise from '_entities/premise/Premise'
    import PushMessage from '_entities/pushMessage/PushMessage'
    import PushMessageLocation from '_entities/pushMessage/PushMessageLocation'
    import PushMessageTemplate from '_entities/pushMessage/PushMessageTemplate'
    import PushMessageTemplateValue from '_entities/pushMessage/PushMessageTemplateValue'
    import PushMessageTemplateCheckResult from '_entities/pushMessage/PushMessageTemplateCheckResult'

    import CPushMessagesTemplateSelection from '_features/pushMessages/components/CPushMessagesTemplateSelection'
    import CPushMessageTypesSelect from '_features/pushMessages/components/CPushMessageTypesSelect'
    import FCCompaniesSelect from '_features/companies/components/FCCompaniesSelect'
    import FBuildingPicker from '_features/housingStock/buildings/components/FBuildingPicker'
    import FPipePicker from '_features/housingStock/pipes/components/FPipePicker'
    import FEntrancePicker from '_features/housingStock/entrances/components/FEntrancePicker'
    import FServicePicker from '_features/service/services/components/FServicePicker'
    import { PERMISSIONS } from '_features/permissions/constants'

    import CContainer from '_common/components/CContainer'
    import CFooter from '_common/components/CFooter'
    import CSection from '_common/components/CSection'

    import {
        UIDatePicker,
        UIDateTimePicker,
        UITimePicker,
        UIPage,
        UIPageHeader,
        UIPhoneMaskedInput,
        UISelect,
        UITextarea,
    } from '_ui'

    export default {
        name: 'PushMessageEditScreen',

        components: {
            CContainer,
            CFooter,
            CPushMessagesTemplateSelection,
            CPushMessageTypesSelect,
            CSection,
            FBuildingPicker,
            FCCompaniesSelect,
            FPipePicker,
            FEntrancePicker,
            FServicePicker,
            UIDatePicker,
            UIDateTimePicker,
            UIPage,
            UIPageHeader,
            UIPhoneMaskedInput,
            UISelect,
            UITextarea,
            UITimePicker,
        },

        data: () => ({
            isFetching: false,
            isSaving: false,
            isShownCheckDialog: false,
            isValidForm: false,
            companies: [],
            pushMessage: new PushMessage(),
            premises: [],
            pushType: 'sample',
            rules: {
                required: (value) => (!!value && (!!value.length || value > 0)) || 'Обязательное поле.',
                requiredObject: (value) => (value && typeof value === 'object') || 'Обязательное поле.',
                requiredArray: (value) => (value.length > 0) || 'Обязательное поле',
            },
            sample: null,
            templateCheckResult: null,
            templateValuesModel: [],
            templateSelectedEntrance: null,
        }),

        computed: {
            maxTimeStart() {
                const timeFinishField = _filter(this.templateValuesModel, (templateValue) => templateValue.type === 'time' && templateValue.code === 'time_finish')[0]

                return timeFinishField?.value?.name || null
            },

            minTimeFinish() {
                const timeStartField = _filter(this.templateValuesModel, (templateValue) => templateValue.type === 'time' && templateValue.code === 'time_start')[0]

                return timeStartField?.value?.name || null
            },

            maxDateStart() {
                const dateFinishField = _filter(this.templateValuesModel, (templateValue) => templateValue.type === 'date' && templateValue.code === 'date_finish')[0]

                return dateFinishField?.value?.name ? moment.unix(dateFinishField?.value?.name).format('YYYY-MM-DD') : null
            },

            minDateFinish() {
                const dateStartField = _filter(this.templateValuesModel, (templateValue) => templateValue.type === 'date' && templateValue.code === 'date_start')[0]

                return dateStartField?.value?.name ? moment.unix(dateStartField?.value?.name).format('YYYY-MM-DD') : null
            },

            isEnabledPhoneEnter() {
                return _filter(this.templateValuesModel, (templateValue) => templateValue.value?.name === 'Управляющая компания').length > 0
            },

            accidentCompanyPhone() {
                const accidentCompanyId = _filter(this.templateValuesModel, (templateValue) => {
                    return templateValue.code === 'accident_companies'
                })[0]?.value?.id

                if (accidentCompanyId) {
                    return _filter(this.companies, (company) => company.id === accidentCompanyId)[0]?.phoneNumber
                }

                return null
            },

            templateText() {
                return this.templateCheckResult?.text
            },

            canFreeEdit() {
                return this.$hasPermission([
                    PERMISSIONS.CAN_ADD_NEWSLETTER_PUSH_MESSAGE,
                    PERMISSIONS.CAN_CHANGE_NEWSLETTER_PUSH_MESSAGE,
                ])
            },

            canSampledEdit() {
                return this.$hasPermission([
                    PERMISSIONS.CAN_ADD_NEWSLETTER_TEMPLATE_PUSH_MESSAGE,
                    PERMISSIONS.CAN_CHANGE_NEWSLETTER_TEMPLATE_PUSH_MESSAGE,
                ])
            },

            canSelectMode() {
                return this.canFreeEdit && this.canSampledEdit && !this.pushMessageId
            },

            isSample() {
                return this.pushType === 'sample'
            },

            fieldLabels() {
                return PushMessage.fieldLabels
            },

            floors() {
                let max = 1
                let min = 1

                _forEach(this.pushMessage?.location?.entrances, (entrance) => {
                    max = _max([max, _max(entrance.floors)])
                    min = _min([min, _min(entrance.floors)])
                })

                return _range(min, max + 1)
            },

            isCopy() {
                return this.$route.params?.isCopy
            },

            isFormDisabled() {
                return this.isFetching || this.isSaving
            },

            isServiceRequired() {
                return (this.pushMessage.type !== 'service') || (this.pushMessage.type === 'service' && this.pushMessage.service !== null) || 'Обязательное поле'
            },

            isPersonal() {
                return ['meter_verification', 'service', 'useful_info', 'payments'].indexOf(this.pushMessage?.type) !== -1
            },

            locationFieldLabels() {
                return PushMessageLocation.fieldLabels
            },

            minDate() {
                return moment().format('YYYY-MM-DD HH:mm')
            },

            minNextDayDateLimit() {
                if (!this.sample?.nextDayDateLimit || !this.pushMessage?.date) {
                    return null
                }

                return moment.unix(this.pushMessage.date).format('YYYY-MM-DD HH:mm')
            },

            maxNextDayDateLimit() {
                if (!this.sample?.nextDayDateLimit || !this.pushMessage?.date) {
                    return null
                }

                return moment.unix(this.pushMessage.date).add(1, 'day').format('YYYY-MM-DD HH:mm')
            },

            notEntrancePresent() {
                return !this.pushMessage.location.entrances || this.pushMessage.location.entrances.length === 0
            },

            pageTitle() {
                if (this.pushMessageId) {
                    return 'Редактирование Push сообщения'
                } else {
                    return 'Новое Push сообщение'
                }
            },

            filteredPremises() {
                const floors = this.pushMessage.location.floors
                const pipes = _map(this.pushMessage.location.pipes, (pipe) => pipe.id)
                const entrances = _map(this.pushMessage.location.entrances, (entrance) => entrance.id)

                const hasFloors = floors && floors.length > 0
                const hasPipes = pipes && pipes.length > 0
                const hasEntrances = entrances && entrances.length > 0

                let premiseInFloors
                let premiseInPipes
                let premiseInEntrances

                return _filter(this.premises, (premise) => {
                    premiseInEntrances = !hasEntrances || entrances.indexOf(premise.entrance?.id) !== -1
                    premiseInPipes = !hasPipes || pipes.indexOf(premise.pipe?.id) !== -1
                    premiseInFloors = !hasFloors || floors.indexOf(premise.floor) !== -1

                    if (premiseInEntrances && premiseInPipes && premiseInFloors) {
                        return premise
                    }
                })
            },

            pushMessageId() {
                return this.$route.params?.pushMessageId
            },

            entranceFilter() {
                const buildingsId = _map(this.pushMessage.buildings, (building) => building.id)
                return buildingsId?.length > 0 ? { building__in: buildingsId.join(',') } : null
            },

            pipeFilter() {
                const entranceIds = _map(this.pushMessage.location.entrances, (entrance) => entrance.id)
                return entranceIds?.length > 0 ? { entrance_id__in: entranceIds.join(',') } : null
            },

            templateSelectedEntranceId() {
                // Для ограничения выбора этажа в шаблоне, отслеживаем выбранный в шаблоне подъезд
                return _filter(this.templateValuesModel, (templateValue) => templateValue.code === 'entrances')[0]?.value?.id
            },

            entranceValues() {
                return _orderBy(_uniqBy(_map(this.filteredPremises, (premise) => premise.entrance), 'id'), 'name')
            },

            floorValues() {
                if (this.templateSelectedEntrance) {
                    return _map(this.templateSelectedEntrance.floors, (floor) => String(floor))
                }

                return this.floors
            },
        },

        watch: {
            templateValuesModel: {
                handler: 'checkTemplateValuesModel',
                deep: true,
            },

            pushMessageId: {
                handler() {
                    this.fetchPushMessage()
                },
                immediate: true,
            },

            templateSelectedEntranceId: {
                handler(templateSelectedEntranceId) {
                    if (templateSelectedEntranceId) {
                        this.fetchEntrance()
                    } else {
                        this.templateSelectedEntrance = null
                    }
                },
                immediate: true,
            },

            'pushMessage.buildings': function (newValue, oldValue) {
                this.pushMessage.location.entrances = []
                this.fetchPremises()
            },

            'pushMessage.location.entrances': function (newValue, oldValue) {
                if (oldValue?.length) {
                    this.pushMessage.location.pipes = []
                    this.pushMessage.location.floors = []
                }
            },

            'pushMessage.location.pipes': function (newValue, oldValue) {
                if (oldValue?.length) {
                    this.pushMessage.location.premises = []
                }
            },

            'pushMessage.location.floors': function (newValue, oldValue) {
                if (oldValue?.length) {
                    this.pushMessage.location.premises = []
                }
            },

            'pushMessage.type': {
                handler(type) {
                    if (type !== 'service') {
                        this.pushMessage.service = null

                        setTimeout(() => this.$refs.pushMessageForm.validate(), 100)
                    }

                    this.pushMessage.isPersonal = this.isPersonal
                },
            },

            filteredPremises: {
                handler: 'buildTemplateValues',
                deep: true,
            },

            pushType: {
                handler(pushType) {
                    if (pushType === 'free') {
                        this.sample = null
                        this.buildTemplateValues()
                    }

                    if (pushType === 'sample') {
                        this.pushMessage.type = 'text'
                    }

                    this.pushMessage.title = null
                    this.pushMessage.text = null
                },
            },

            floorValues: {
                handler: 'fillTemplateValuesFloors',
            },
        },

        mounted() {
            this.fetchCompanies()

            if (this.canFreeEdit) {
                this.pushType = 'free'
            }

            this.$refs.pushMessageForm?.validate()
        },

        methods: {
            getFieldComponent(type) {
                switch (type) {
                    case 'time':
                        return 'UITimePicker'
                    case 'date':
                        return 'UIDatePicker'
                    case 'list_relation':
                    case 'list':
                        return 'UISelect'
                    case 'phone':
                        return 'UIPhoneMaskedInput'
                    default:
                        return 'div'
                }
            },

            getUserName() {
                return this.templateCheckResult?.identicalMessageCreatedBy?.userProfile?.shortName
            },

            getTime() {
                const time = this.templateCheckResult?.identicalMessageCreatedAt

                if (time) {
                    return moment.unix(time).format('DD.MM.YYYY HH:mm')
                }

                return null
            },

            isDictionary(field) {
                return field.code === 'entrances' || field.code === 'accident_companies'
            },

            addSample(sample) {
                this.sample = sample
                this.pushMessage.template = {
                    id: this.sample.id,
                    title: this.sample.title,
                }
                this.pushMessage.title = this.sample.title
                this.pushMessage.text = this.sample.text

                this.buildTemplateValues()
            },

            async fetchCompanies() {
                try {
                    const params = {
                        page: 1,
                        page_size: Number.MAX_SAFE_INTEGER,
                    }

                    const response = await API.v1.services.accident.getAccidentCompanies(params)

                    const { results } = response

                    if (results) {
                        const companies = _map(results, (result) => {
                            return new AccidentCompany(result)
                        })

                        this.companies = _filter(companies, (company) => company.phoneNumber)
                    }
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось получить список компаний', e)
                    this.$logger.logException(e)
                }
            },

            async fetchEntrance() {
                try {
                    const response = await API.v1.services.entrance.getEntranceById(this.templateSelectedEntranceId)

                    this.templateSelectedEntrance = null

                    if (response) {
                        this.templateSelectedEntrance = new Entrance(response)
                    }
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось получить данные подъезда', e)
                    this.$logger.logException(e)
                }
            },

            async fetchPremises() {
                try {
                    const params = {
                        page: 1,
                        page_size: Number.MAX_SAFE_INTEGER,
                        building__in: _map(this.pushMessage.buildings, (building) => building.id).join(','),
                    }

                    const response = await API.v1.services.premise.getPremises(params)

                    const { results } = response

                    const premises = []
                    _forEach(results, (premise) => {
                        premises.push(new Premise(premise))
                    })

                    this.premises = premises
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось получить список помещений', e)
                    this.$logger.logException(e)
                }
            },

            async fetchPushMessage() {
                if (this.pushMessageId) {
                    this.isFetching = true
                    try {
                        const response = await API.v1.services.newsletter.getPushMessageById(this.pushMessageId)

                        this.pushMessage = response ? new PushMessage(response) : new PushMessage()

                        if (this.pushMessage.template?.id) {
                            const sample = await API.v1.services.newsletter.getTemplateById({ id: this.pushMessage.template.id })
                            if (sample) {
                                this.addSample(new PushMessageTemplate(sample))
                            }
                        }

                        this.checkPushMode()
                    } catch (e) {
                        this.$root.$pushRequestNotifications('Не удалось получить данные push-сообщения', e)
                        this.$logger.logException(e)
                        this.isFetching = false
                    }

                    this.isFetching = false
                }
            },

            async getTemplateText() {
                try {
                    this.pushMessage.templateValues = _map(this.templateValuesModel, (templateValue) => {
                        const pushMessageTemplateValue = new PushMessageTemplateValue({
                            fieldId: templateValue.id,
                            fieldText: templateValue.value?.name,
                            fieldValue: templateValue.value?.id || null,
                        })

                        if (templateValue.type === 'date') {
                            pushMessageTemplateValue.fieldText = moment.unix(pushMessageTemplateValue.fieldText).format('DD.MM.YYYY')
                        }

                        return pushMessageTemplateValue
                    })
                    const params = this.pushMessage.toArray()

                    const response = await API.v1.services.newsletter.getTemplateText(params)

                    if (response) {
                        this.templateCheckResult = new PushMessageTemplateCheckResult(response)
                    }
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось получить полный текст push-сообщения', e)
                    this.$logger.logException(e)
                    this.isFetching = false
                }
            },

            async checkBeforeSave() {
                await this.getTemplateText()

                if (this.templateCheckResult) {
                    this.isShownCheckDialog = true
                }
            },

            checkPushMode() {
                // При создании возможно переключение режима редактирования.
                // При редактировании эта возможность недоступна и текущий режим редактирования нужно определять самостоятельно.
                if (this.pushMessageId) {
                    this.pushType = !this.pushMessage.template?.id ? 'free' : 'sample'
                }
            },

            goBack() {
                const route = {
                    name: 'NewslettersPush',
                }
                this.$router.push(route)
            },

            saveHandler() {
                if (this.pushType === 'sample') {
                    this.checkBeforeSave()
                }
                if (this.pushType === 'free') {
                    this.save()
                }
            },

            saveAndPush() {
                this.isShownCheckDialog = false
                this.save()
            },

            async save() {
                if (this.$refs.pushMessageForm.validate()) {
                    this.isSaving = true

                    try {
                        const text = this.templateText || this.pushMessage.text
                        this.pushMessage.text = this.trimSumbols(text)

                        const params = this.pushMessage.toArray()

                        if (this.pushMessage.type !== 'nps_rate') {
                            if (this.pushMessageId && !this.isCopy) {
                                await API.v1.services.newsletter.updatePushMessage(this.pushMessageId, params)
                            } else {
                                await API.v1.services.newsletter.createPushMessage(params)
                            }
                        } else {
                            if (this.pushMessageId && !this.isCopy) {
                                await API.v1.services.newsletter.updateNPSPushMessage(this.pushMessageId, params)
                            } else {
                                await API.v1.services.newsletter.createNPSPushMessage(params)
                            }
                        }

                        this.isSaving = false

                        this.goBack()
                    } catch (e) {
                        this.$root.$pushRequestNotifications('Не удалось сохранить push-сообщение', e)
                        this.$logger.logException(e)
                        this.isSaving = false
                    }
                }
            },

            trimSumbols(text) {
                if (!text) {
                    return
                }

                // HINT: В некоторых ос (к примеру, iOS) не переваривается и отображается символ пробела
                return text.replace(/&nbsp;/g, ' ')
            },

            buildTemplateValues() {
                const templateValues = {}
                _map(this.pushMessage.templateValues, (templateValue) => {
                    templateValues[templateValue.fieldId] = templateValue
                })

                this.templateValuesModel = _map(this.sample?.fields, (field) => {
                    const templateValue = templateValues[field.id]

                    const fieldText = templateValue?.fieldText || null
                    const fieldValue = templateValue?.fieldValue || null

                    const data = {
                        ...field,
                        value: {
                            id: fieldValue,
                            name: fieldText,
                        },
                    }

                    if (field.code === 'entrances') {
                        data.values = this.entranceValues
                    }

                    if (field.code === 'floors') {
                        data.values = this.floorValues
                    }

                    if (field.code === 'accident_companies') {
                        data.values = this.companies
                    }

                    if (field.type === 'date' && fieldText) {
                        data.value.name = moment(fieldText, 'DD.MM.YYYY').unix()
                    }

                    return data
                })
            },

            checkTemplateValuesModel() {
                // Здесь реализуется всякая нестандартная логика связи полей

                // Для поля телефона подставляем значение из справочника компаний, если его нельзя редактировать
                this.templateValuesModel.map((templateValue) => {
                    if (templateValue.code === 'phone') {
                        templateValue.value.name = this.accidentCompanyPhone || templateValue.value.name
                    }

                    return templateValue
                })
            },

            fillTemplateValuesFloors() {
                if (this.templateValuesModel?.length) {
                    this.templateValuesModel.forEach((templateValue) => {
                        if (templateValue.code === 'floors') {
                            templateValue.values = this.floorValues
                        }
                    })
                }
            },

            clearPushMessage() {
                this.$router.push({
                    name: 'NewslettersPush',
                })
            },

            requiredNotEmptyObject(value) {
                return value?.name?.length > 0 || 'Обязательное поле.'
            },
        },
    }
</script>

<style scoped>
.card-warning {
    border: 3px solid rgb(255,218,218) !important;
}
</style>
