import Entity from '_entities/base/EntityNew'
import DepartmentLink from '_entities/department/DepartmentLink'
import UserOld from '_entities/user/UserOld'
import WorkerPositionLink from '_entities/worker/WorkerPositionLink'
import User from '_entities/user/User'

export const WorkerSchema = {
    name: 'Worker',
    primaryKey: 'id',
    properties: {
        commissionRate: { type: 'int' },
        department: { type: 'DepartmentLink', entity: DepartmentLink },
        id: { type: 'string' },
        internalPhone: { type: 'string' },
        isActive: { type: 'bool' },
        netMonetId: { type: 'string' },
        sendEmailNotifications: { type: 'bool' },
        user: { type: 'UserOld', entity: UserOld },
        workerPosition: { type: 'WorkerPositionLink', entity: WorkerPositionLink },
        workingStatus: { type: 'bool' },
        queueNumber: { type: 'int' },
        deletedAt: { type: 'int' },
        deletedBy: { type: 'User', entity: User },
    },
}

class Worker extends Entity {
    get schema() {
        return WorkerSchema
    }

    get dataMap() {
        return {
            commission_rate: 'commissionRate',
            internal_phone: 'internalPhone',
            is_active: 'isActive',
            net_monet_id: 'netMonetId',
            working_status: 'workingStatus',
            queue_number: 'queueNumber',
            send_email_notifications: 'sendEmailNotifications',
            worker_position: 'workerPosition',
            deleted_at: 'deletedAt',
            deleted_by: 'deletedBy',
        }
    }

    static fieldLabels = {
        commissionRate: 'Процент комиссии',
        department: 'Отдел',
        id: 'ID сотрудника',
        internalPhone: 'Внутренний номер',
        isActive: 'Активный',
        netMonetId: 'Код НетМонет',
        sendEmailNotifications: 'Отправка заявок на эл.почту',
        user: 'Пользователь',
        workerPosition: 'Должность',
        workingStatus: 'Рабочий статус',
        queueNumber: 'Номер очереди',
    }

    toServer() {
        return {
            commission_rate: this.commissionRate,
            department_id: this.department?.id,
            internal_phone: this.internalPhone,
            is_active: this.isActive,
            net_monet_id: this.netMonetId,
            worker_position_id: this.workerPosition?.id,
            working_status: this.workingStatus,
            // User
            email: this.user?.email,
            first_name: this.user?.firstName,
            last_name: this.user?.lastName,
            middle_name: this.user?.middleName,
            phone_number: this.user?.phone,
            queue_number: this.queueNumber === '' ? null : this.queueNumber,
            send_email_notifications: this.sendEmailNotifications,
            user: this.user?.id,
        }
    }
}

export default Worker
