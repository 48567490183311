import Entity from '_entities/base/EntityNew'

import ServiceDiscountCardCategory from '_entities/service/ServiceDiscountCardCategory'
import ServiceLink from '_entities/service/ServiceLink'
import ServicePartnerCompany from '_entities/service/ServicePartnerCompany'

export const ServicePartnerPromotionSchema = {
    name: 'ServicePartnerPromotion',
    primaryKey: 'id',
    properties: {
        category: { type: 'ServiceDiscountCardCategory', entity: ServiceDiscountCardCategory },
        company: { type: 'ServicePartnerCompany', entity: ServicePartnerCompany },
        dateFinish: { type: 'int' },
        dateStart: { type: 'int' },
        description: { type: 'string' },
        id: { type: 'string' },
        instruction: { type: 'string' },
        name: { type: 'string' },
        promoCode: { type: 'string' },
        service: { type: 'ServiceLink', entity: ServiceLink },
    },
}

class ServicePartnerPromotion extends Entity {
    get schema() {
        return ServicePartnerPromotionSchema
    }

    get dataMap() {
        return {
            date_finish: 'dateFinish',
            date_start: 'dateStart',
            promo_code: 'promoCode',
        }
    }

    static fieldLabels = {
        category: 'Категория',
        company: 'ID компании-партнера',
        dateFinish: 'Период действия акции',
        dateStart: 'Дата начала акции',
        description: 'Описание',
        id: 'ID',
        instruction: 'Как активировать акцию',
        name: 'Название',
        promoCode: 'Промокод',
        service: 'ID сервиса',
    }

    toServer() {
        return {
            category: this.category?.id || null,
            company: this.company?.id || null,
            date_finish: this.dateFinish,
            date_start: this.dateStart,
            description: this.description,
            id: this.id,
            instruction: this.instruction,
            name: this.name,
            promo_code: this.promoCode,
            service: this.service?.id,
        }
    }
}

export default ServicePartnerPromotion
