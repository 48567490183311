import mime from 'browserify-mime'

import { find as _find } from 'lodash'

const getSemanticType = (mimeType) => {
    if (!mimeType) {
        return null
    }

    if (mimeType.indexOf('audio') !== -1) {
        return 'audio'
    }
    if (mimeType.indexOf('image') !== -1) {
        return 'image'
    }
    if (mimeType.indexOf('video') !== -1) {
        return 'video'
    }
    if (mimeType.indexOf('pdf') !== -1) {
        return 'pdf'
    }
    if (mimeType.indexOf('csv') !== -1) {
        return 'csv'
    }
    if (mimeType.indexOf('zip') !== -1) {
        return 'zip'
    }
    if (mimeType.indexOf('ms-excel') !== -1) {
        return 'ms-excel'
    }
    if (mimeType.indexOf('vnd.openxmlformats-officedocument.spreadsheetml.sheet') !== -1) {
        return 'ms-excel'
    }
    if (mimeType.indexOf('ms-powerpoint') !== -1) {
        return 'ms_powerpoint'
    }
    if (mimeType.indexOf('vnd.openxmlformats-officedocument.presentationml.presentation') !== -1) {
        return 'ms_powerpoint'
    }
    if (mimeType.indexOf('msword') !== -1) {
        return 'msword'
    }
    if (mimeType.indexOf('vnd.openxmlformats-officedocument.wordprocessingml.document') !== -1) {
        return 'msword'
    }

    return null
}

const resolveFileItemBase = (data, type = 'document') => {
    const id = data?.id ? `${data.id}` : null

    /*
    if (!id) {
        return null
    }
    */

    const originalName = data?.name
    const url = data?.url

    const mimeType = mime.lookup(originalName)
    const extension = mimeType ? mime.extension(mimeType) : null

    const originalNameParts = originalName?.split('/')
    const fileName = originalNameParts?.length ? originalNameParts[originalNameParts?.length - 1] : null

    const extraFields = {}

    if (type === 'image') {
        if (Array.isArray(data.sizes)) {
            extraFields.url1000 = _find(data.sizes, (size) => size.code === 'l')?.url
            extraFields.url200 = _find(data.sizes, (size) => size.code === 'm')?.url
            extraFields.url50 = _find(data.sizes, (size) => size.code === 's')?.url
        } else {
            extraFields.url1000 = url
            extraFields.url200 = url
            extraFields.url50 = url
        }
    }

    return {
        type,
        id,
        [type]: {
            extension,
            fileName,
            id,
            mimeType,
            originalName,
            semanticType: getSemanticType(mimeType),
            type,
            url,
            ...extraFields,
        },
    }
}

export const convertMediaFileToFileItem = (data, type = 'document') => {
    if (!data) {
        return null
    }

    // Новый формат
    if (data?.type) {
        return data
    }

    // Старый формат
    return resolveFileItemBase(data, type)
}

export const convertMediaPhotoToFileItem = (data, type = 'image') => {
    if (!data) {
        return null
    }

    // Новый формат
    if (data?.type) {
        return data
    }

    // Старый формат
    const sizeOriginal = _find(data?.sizes || [], (size) => size.code === 'o')

    data.url = sizeOriginal?.url

    return resolveFileItemBase(data, type)
}

export const convertTimelineEventAttachmentFileToFileItem = (data) => {
    if (!data) {
        return null
    }

    // Новый формат
    if (data?.type) {
        return data
    }

    // Старый формат
    data.url = data.link ?? null

    let type = 'document'

    const mimeType = data.mime_type || data.mimeType

    if (mimeType.indexOf('image/') !== -1) {
        type = 'image'
    }

    return resolveFileItemBase(data, type)
}
