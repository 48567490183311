import Entity from '_entities/base/EntityNew'

export const TicketMostProbableDepartmentSchema = {
    name: 'TicketMostProbableDepartment',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
    },
}

class TicketMostProbableDepartment extends Entity {
    get schema() {
        return TicketMostProbableDepartmentSchema
    }

    get dataMap() {
        return {
            department_id: 'id',
            department_name: 'name',
        }
    }
}

export default TicketMostProbableDepartment
