import Entity from '_entities/base/EntityNew'

import User from '_entities/user/User'

export const ApprovalTypeSchema = {
    name: 'ApprovalType',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' }, // UUID
        isActive: { type: 'bool', default: false },
        deletedAt: { type: 'int' },
        deletedBy: { type: 'User', entity: User },
        name: { type: 'string' },
    },
}

class ApprovalType extends Entity {
    get schema() {
        return ApprovalTypeSchema
    }

    get dataMap() {
        return {
            is_active: 'isActive',
            deleted_at: 'deletedAt',
            deleted_by: 'deletedBy',
        }
    }
}
export default ApprovalType
