import Entity from '_entities/base/EntityNew'

import TicketLink from '_entities/ticket/TicketLink'
import TicketMostProbableDepartment from '_entities/ticket/TicketMostProbableDepartment'
import TicketMostProbableExecutor from '_entities/ticket/TicketMostProbableExecutor'
import TicketMostProbableType from '_entities/ticket/TicketMostProbableType'

export const TicketSemanticSimilarSchema = {
    name: 'TicketSemanticSimilar',
    properties: {
        departments: { type: 'TicketMostProbableDepartment[]', entity: TicketMostProbableDepartment, default: [] },
        executors: { type: 'TicketMostProbableExecutor[]', entity: TicketMostProbableExecutor, default: [] },
        tickets: { type: 'TicketLink[]', entity: TicketLink, default: [] },
        types: { type: 'TicketMostProbableType[]', entity: TicketMostProbableType, default: [] },
    },
}

class TicketSemanticSimilar extends Entity {
    get schema() {
        return TicketSemanticSimilarSchema
    }

    get dataMap() {
        return {
            most_probable_deps: 'departments',
            most_probable_types: 'types',
            most_probable_executors: 'executors',
            most_similar_tickets: 'tickets',
        }
    }

    static fieldLabels = {
        departments: 'Вероятные отделы',
        executors: 'Вероятные исполнители',
        types: 'Вероятная типизация заявки',
        tickets: 'Похожие заявки',
    }
}

export default TicketSemanticSimilar
