import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'

import authRoutes from '@/router/Auth'
import homeRoutes from '@/router/Home'

const ErrorScreen = () => import('_screens/ErrorScreen')

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Root',
        redirect: { name: 'Home' },
    },
    ...authRoutes,
    ...homeRoutes,
    {
        path: '*',
        name: 'error',
        component: ErrorScreen,
    },
]

const router = new VueRouter({
    routes,
})

const hasPermission = (permissions) => {
    return store.getters['permissions/hasPermission'](permissions)
}

router.beforeEach((to, from, next) => {
    const isAuthorized = Boolean(localStorage.getItem('accessToken') || localStorage.getItem('accessKeycloakToken'))

    const toAuthScreen = to.matched.some((route) => route.name === 'Auth')
    const toHomeScreen = to.matched.some((route) => route.name === 'Home')

    if (isAuthorized && !toHomeScreen) {
        // Если авторизован и открываем экран не из Home, то редирект на Home

        next({ name: 'Home' })
    } else if (!isAuthorized && !toAuthScreen) {
        // Если НЕ авторизован и открываем экран не из Авторизации, то редирект на авторизацию

        next({ name: 'Auth' })
    } else {
        // Логика по умолчанию
        const permissions = to?.meta?.permissions
        const isAllowed = permissions ? hasPermission(permissions) : true

        if (!isAllowed) {
            next({ name: 'Home' })
        } else {
            let goal = ''

            if (from.name !== to.name) {
                switch (to?.name) {
                    case 'KnowledgeBase':
                        goal = 'Посещение базы знаний'
                        break
                    case 'PersonalAccountDetail':
                        goal = 'Просмотр данных лицевого счета'
                        break
                    case 'StorageKeyIssuance':
                        goal = 'open_issues_page'
                        break
                    case 'StorageKey':
                        goal = 'open_keys_page'
                        break
                    case 'StorageProxy':
                        goal = 'open_proxies_page'
                        break
                    case 'Tickets':
                        goal = 'Посещение заявок - Все'
                        break
                    case 'TicketOverdue':
                        goal = 'Посещение заявок - Просроченные'
                        break
                    case 'TicketAlmostOverdue':
                        goal = 'Посещение заявок - Остался 1 день'
                        break
                    case 'TicketOnNew':
                        goal = 'Посещение заявок - Новые'
                        break
                    case 'TicketCreatedByMe':
                        goal = 'Посещение заявок - Я заявитель'
                        break
                    case 'TicketOnNeedExecutor':
                        goal = 'Посещение заявок - Назначить'
                        break
                    case 'TicketOnInWork':
                        goal = 'Посещение заявок - Мои в работе'
                        break
                    case 'TicketAuditor':
                        goal = 'Посещение заявок - Наблюдаю'
                        break
                    case 'TicketOnCheck':
                        goal = 'Посещение заявок - На проверке'
                        break
                }

                if (goal) {
                    router.app.$logger.logEvent({ goal })
                }
            }

            next()
        }
    }
})

router.onError((error) => {
    const isChunkLoadFailed = error.message.match(/Loading chunk (\d) failed/g)

    console.error('router.onError', error)

    if (isChunkLoadFailed) {
        console.error('On isChunkLoadFailed')

        window.location.reload(true)

        console.error('On isChunkLoadFailed')
    } else {
        throw error
    }
})
export default router
