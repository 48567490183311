import { API } from '_api'

import UserOld from '_entities/user/UserOld'

const actions = {
    async fetchCallerInfo({ commit, state }, { callerId, callerPhone, cardTitle, cardColor, showPhonePanel, serviceCompanyName }) {
        commit('setCallerInfo', { callerId, callerPhone, cardTitle, cardColor, showPhonePanel, serviceCompanyName })

        if (!state.isFetching) {
            commit('fetchResidentRequest')

            let resident
            try {
                if (callerId) {
                    const response = await API.v1.services.user.getResidentById(callerId)

                    resident = new UserOld(response)
                }

                if (!resident && callerPhone) {
                    const params = {
                        search: callerPhone,
                    }

                    const response = await API.v1.services.user.getResidents(params)

                    if (response?.results?.length) {
                        resident = new UserOld(response.results[0])
                    }
                }

                commit('fetchResidentSuccess', { resident })
            } catch (e) {
                commit('fetchResidentError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
