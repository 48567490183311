import Entity from '_entities/base/EntityNew'

import User from '_entities/user/User'

export const TicketInspectionTypeLinkSchema = {
    name: 'TicketInspectionTypeLink',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
        isActive: { type: 'bool', default: false },
        deletedAt: { type: 'int' },
        deletedBy: { type: 'User', entity: User },
    },
}

class TicketInspectionTypeLink extends Entity {
    get schema() {
        return TicketInspectionTypeLinkSchema
    }

    get dataMap() {
        return {
            is_active: 'isActive',
            deleted_at: 'deletedAt',
            deleted_by: 'deletedBy',
        }
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Наименование',
    }
}

export default TicketInspectionTypeLink
