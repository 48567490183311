<template>
    <v-app-bar app elevate-on-scroll>
        <v-btn icon @click="toggleNavigationDrawer">
            <v-icon v-text="'$bars'" />
        </v-btn>

        <v-toolbar-title v-if="!isPoolMode">
            {{ title }}
        </v-toolbar-title>

        <v-spacer />
        <!--        <v-btn-->
        <!--            v-if="isAvailableSoftwareUpdate"-->
        <!--            color="primary"-->
        <!--            @click="updateSoftware"-->
        <!--        >-->
        <!--            Обновить ПО-->
        <!--        </v-btn>-->

        <v-text-field
            v-if="isPollUser"
            v-model="callLine"
            label="Код линии"
            outlined
            hide-details
            dense
        />

        <FPhoneOnLineSwitcher
            v-if="canUseWebRTC && isHasCallLine"
            ref="phoneOnLineSwitcher"
            :show-call-button="showCallButton"
            class="mr-3"
        />

        <template v-if="!isShort">
            <FMaintenanceInfo v-if="isMaintenanceInfoShown" class="mx-3" />

            <FIncomingCallButton
                v-if="callerPhone"
                class="mr-3"
            />

            <UIWorkStatus class="mr-3" />
            <UIIncomeCalls v-if="timeEntry" ref="incomeCalls" class="mr-3" />

            <v-btn icon>
                <v-badge
                    v-if="hasNewMessages"
                    color="red"
                    offset-x="5"
                    offset-y="5"
                    dot
                >
                    <v-icon @click="showNotificationCenter" v-text="'$bell'" />
                </v-badge>
                <v-icon v-else @click="showNotificationCenter" v-text="'$bell'" />
            </v-btn>

            <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon v-text="'$question'" />
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="openChangeLog">
                        <v-list-item-title>
                            История изменений
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>

        <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon v-text="'$userCircle'" />
                </v-btn>
            </template>
            <v-list>
                <v-list-item @click="signOut()">
                    <v-list-item-title>
                        Выйти
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
    import { mapState } from 'vuex'

    import FIncomingCallButton from '_features/incomingCall/components/FIncomingCallButton'
    import FMaintenanceInfo from '_features/maintenanceWork/components/FMaintenanceInfo'
    import FPhoneOnLineSwitcher from '_features/sipCall/components/FPhoneOnLineSwitcher'
    import { PERMISSIONS } from '_features/permissions/constants'
    import { sipUsers } from '_features/sipCall/constants/users'

    import { UIIncomeCalls, UIWorkStatus } from '_ui'

    export default {
        name: 'CAppBar',

        components: {
            FIncomingCallButton,
            FMaintenanceInfo,
            FPhoneOnLineSwitcher,
            UIIncomeCalls,
            UIWorkStatus,
        },

        props: {
            isShort: { type: Boolean, default: false },
        },

        data: () => ({
            changeLogUrl: 'https://docs.google.com/document/d/1Hp_EszXzkw5YeSLUSi1dP4r63JOUrhLOdP5GsoRAQnU',
            callLine: null,
        }),

        computed: {
            ...mapState('global', {
                navigationDrawer: (state) => state.navigationDrawer,
            }),

            ...mapState('incomingCall', {
                callerPhone: (state) => state.callerPhone,
            }),

            currentWorker() {
                return this.$store.state.auth.currentWorker
            },

            canUseWebRTC() {
                const internalPhone = this.currentWorker?.internalPhone

                return sipUsers[internalPhone]
            },

            isPollUser() {
                return this.currentWorker?.internalPhone === '9998'
            },

            showCallButton() {
                // TODO: Костыль для Опросов. Убрать или переделать при изменении логики Опросов
                return !this.isPollUser
            },

            isHasCallLine() {
                return !this.isPollUser || (this.isPollUser && this.callLine)
            },

            hasNewMessages() {
                return this.$store.state.notificationCenter.hasNew
            },

            timeEntry() {
                return this.$store.state.timeManagement.timeEntry
            },

            isMaintenanceInfoShown() {
                return this.$store.state.maintenanceWork.isInfoShown
            },

            title() {
                let title
                this.$route.matched.forEach((route) => {
                    const screenOptions = route.meta?.screenOptions

                    if (screenOptions?.title) {
                        title = screenOptions.title
                    }
                })

                return title
            },

            isAvailableSoftwareUpdate() {
                return this.$store.state.softwareUpdate.isAvailable
            },

            isPoolMode() {
                const pollRole = this.$hasPermission([PERMISSIONS.CAN_ADD_POLL_RESULT])

                return pollRole
            },

            phoneSwitcherRef() {
                return this.$store.state.sipCall.ref
            },
        },

        watch: {
            timeEntry: {
                handler() {
                    if (!this.timeEntry) {
                        this.$refs?.incomeCalls?.changeCallState('finish')
                    }
                },
            },

            isAvailableSoftwareUpdate: {
                handler(isAvailableSoftwareUpdate) {
                    if (isAvailableSoftwareUpdate) {
                        this.updateSoftware()
                    }
                },
            },

            callLine: {
                handler(callLine) {
                    localStorage.setItem('callLine', callLine)
                    this.phoneSwitcherRef?.disconnectPhone()
                },
            },
        },

        mounted() {
            this.callLine = '' || localStorage.getItem('callLine')
        },

        methods: {
            signOut() {
                this.$store.dispatch('auth/signOut')
            },

            showNotificationCenter() {
                this.$store.commit('notificationCenter/show')
            },

            toggleNavigationDrawer() {
                const navigationDrawer = this.navigationDrawer

                this.$store.commit('global/setNavigationDrawer', {
                    ...navigationDrawer,
                    isShown: !this.navigationDrawer.isShown,
                })

                this.$store.commit('global/setShownNavigationDrawer', {
                    isShown: this.navigationDrawer.isShown,
                })
            },

            updateSoftware() {
                const serviceWorkerRegistration = this.$store.state.softwareUpdate.serviceWorkerRegistration

                this.$store.dispatch('softwareUpdate/update', {
                    serviceWorkerRegistration,
                })
            },

            openChangeLog() {
                this.$router.push({
                    name: 'ChangeLog',
                })
            },
        },
    }
</script>
