const mutations = {
    setCallerInfo(state, { callerId, callerPhone, cardTitle, cardColor, showPhonePanel, serviceCompanyName }) {
        state.callerId = callerId
        state.callerPhone = callerPhone
        state.cardColor = cardColor
        state.cardTitle = cardTitle
        state.serviceCompanyName = serviceCompanyName
        state.isShownDialog = true
        state.showPhonePanel = showPhonePanel || false
    },

    fetchResidentRequest(state) {
        state.isFetching = true
    },

    fetchResidentSuccess(state, { resident }) {
        state.isFetching = false
        state.resident = resident
    },

    fetchResidentError(state, errors) {
        state.errors = errors
        state.isFetching = false
        state.resident = null
    },

    resetData(state) {
        state.resident = null
        state.callerId = null
        state.callerPhone = null
        state.cardTitle = null
        state.isShownDialog = false
    },

    hideDialog(state) {
        state.isShownDialog = false
    },

    showDialog(state) {
        state.isShownDialog = true
    },
}

export default mutations
