import axios from 'axios'
import { createGraphqlRequest } from '../utils/createGraphqlRequest.js'

const apiInstance = axios.create({
    baseURL: process.env.VUE_APP_GRAPHQL_URL,
    // baseURL: 'https://scs-stage.service-company.biz/leopard_api/v1/test/ticket/type/graphql/',
    headers: {
        // 'Content-Type': 'application/json',
        // 'X-Client-Version': '2',
        Accept: 'application/json',
    },
})

apiInstance.interceptors.request.use(async (rq) => {
    const accessToken = localStorage.getItem('accessToken') || localStorage.getItem('accessKeycloakToken')

    rq.headers.Authorization = accessToken ? `Bearer ${accessToken}` : undefined
    return rq
})

export const graphqlRequest = createGraphqlRequest(apiInstance)
