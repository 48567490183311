<template>
    <div>
        <v-row dense align="baseline">
            <v-col>
                <v-autocomplete
                    v-model="typing"
                    clearable
                    :filter="customFilter"
                    :items="typingList"
                    item-value="id"
                    item-text="name"
                    label="Поиск по отделу, типу, виду. Начните ввод..."
                    :loading="isFetching"
                    outlined
                    return-object
                    :search-input.sync="search"
                    @keydown="fetchTyping"
                />
            </v-col>
            <v-col cols="auto">
                <v-btn
                    icon
                    @click="fetchData"
                >
                    <v-icon big v-text="'$search'" />
                </v-btn>
            </v-col>
        </v-row>

        <FTicketDepartmentsSelect
            v-model="ticketDepartmentModel"
            :is-available="isAvailableTicketDepartment"
            hide-details
            :multiple="multiple"
            :rules="[isRequiredTicketDepartment]"
        />
        <FTicketKindsSelect
            v-model="ticketKindModel"
            :filter="ticketKindsFilter"
            :disabled="!presentTicketDepartment"
            hide-details
            :multiple="multiple"
            :rules="[isRequiredTicketKind]"
        />
        <FTicketTypesSelect
            v-model="ticketTypeModel"
            :api-filter="ticketTypesAPIFilter"
            :disabled="!presentTicketKind"
            hide-details
            :multiple="multiple"
            :rules="[isRequiredTicketType]"
        />
    </div>
</template>

<script>
    import {
        debounce as _debounce,
        filter as _filter,
        map as _map,
        uniqBy as _uniqBy,
    } from 'lodash'

    import { API } from '_api'

    import TicketTypingItem from '_entities/ticket/TicketTypingItem'

    import FTicketDepartmentsSelect from '_features/ticketDepartments/components/FTicketDepartmentsSelect'
    import FTicketKindsSelect from '_features/ticketKinds/components/FTicketKindsSelect'
    import FTicketTypesSelect from '_features/ticketTypes/components/FTicketTypesSelect'

    export default {
        name: 'FTicketTypingPicker',

        components: {
            FTicketDepartmentsSelect,
            FTicketKindsSelect,
            FTicketTypesSelect,
        },

        props: {
            isAutofill: { type: Boolean, default: false },
            isAvailableTicketDepartment: { type: Boolean, default: false },
            multiple: { type: Boolean, default: false },
            required: { type: Boolean, default: false },
            ticketDepartment: { type: [Object, Array], default: null },
            ticketKind: { type: [Object, Array], default: null },
            ticketType: { type: [Object, Array], default: null },
        },

        data: () => ({
            fetchTyping: null,
            innerAutofill: false,
            isFetching: false,
            isSearchChanging: false,
            rules: {
                requiredObject: (value) => (value !== null && value !== undefined) || 'Обязательное поле.',
            },
            search: null,
            typing: null,
            typingList: [],
        }),

        computed: {
            isAutofillModel() {
                return this.innerAutofill || this.isAutofill
            },

            isRequiredTicketDepartment() {
                if (!this.required) {
                    return true
                }

                if (this.multiple) {
                    return (this.ticketDepartmentModel.length > 0) || 'Обязательное поле'
                } else {
                    return (this.ticketDepartmentModel !== null && this.ticketDepartmentModel !== undefined) || 'Обязательное поле'
                }
            },

            isRequiredTicketKind() {
                if (!this.required) {
                    return true
                }

                if (this.multiple) {
                    return (this.ticketKindModel.length > 0) || 'Обязательное поле'
                } else {
                    return (this.ticketKindModel !== null && this.ticketKindModel !== undefined) || 'Обязательное поле'
                }
            },

            isRequiredTicketType() {
                if (!this.required) {
                    return true
                }

                if (this.multiple) {
                    return (this.ticketTypeModel.length > 0) || 'Обязательное поле'
                } else {
                    return (this.ticketTypeModel !== null && this.ticketTypeModel !== undefined) || 'Обязательное поле'
                }
            },

            ticketDepartmentModel: {
                get() {
                    if (this.multiple) {
                        return this.ticketDepartment?.length ? [...this.ticketDepartment] : []
                    }

                    return this.ticketDepartment?.id ? this.ticketDepartment : null
                },
                set(value) {
                    this.$emit('update:ticketDepartment', value)
                },
            },

            ticketKindModel: {
                get() {
                    if (this.multiple) {
                        return this.ticketKind?.length ? [...this.ticketKind] : []
                    }

                    return this.ticketKind?.id ? this.ticketKind : null
                },
                set(value) {
                    this.$emit('update:ticketKind', value)
                },
            },

            ticketTypeModel: {
                get() {
                    if (this.multiple) {
                        return this.ticketType?.length ? [...this.ticketType] : []
                    }

                    return this.ticketType?.id ? this.ticketType : null
                },
                set(value) {
                    this.$emit('update:ticketType', value)
                },
            },

            presentTicketDepartment() {
                if (this.multiple) {
                    return this.ticketDepartmentModel?.length
                }

                return this.ticketDepartmentModel
            },

            presentTicketKind() {
                if (this.multiple) {
                    return this.ticketKindModel?.length
                }

                return this.ticketKindModel
            },

            ticketKindsFilter() {
                const filter = {}

                if (this.multiple) {
                    if (this.ticketDepartmentModel?.length) {
                        filter.ticketDepartment = (item) => {
                            const items = _filter(this.ticketDepartmentModel, (ticketDepartment) => {
                                return item.ticketDepartment?.id === ticketDepartment.id
                            })

                            return items.length
                        }
                    }
                } else {
                    if (this.ticketDepartmentModel) {
                        filter.ticketDepartment = (item) => {
                            return item.ticketDepartment?.id === this.ticketDepartmentModel?.id
                        }
                    }
                }

                return filter
            },

            ticketTypesAPIFilter() {
                const filter = {}

                if (this.ticketKindModel?.length) {
                    filter.kind__in = _map(this.ticketKindModel, (ticketKind) => ticketKind?.id)
                } else {
                    filter.kind__in = [this.ticketKindModel?.id]
                }

                return filter
            },

            params() {
                return {
                    search: this.search,
                }
            },
        },

        watch: {
            ticketDepartmentModel: {
                handler() {
                    if (!this.isAutofillModel) {
                        this.ticketKindModel = null
                    }
                },
            },

            ticketKindModel: {
                handler() {
                    if (!this.isAutofillModel) {
                        this.ticketTypeModel = null
                    }
                },
            },

            typing: {
                handler(typing) {
                    if (typing) {
                        this.innerAutofill = true

                        if (this.multiple) {
                            this.ticketDepartmentModel = _uniqBy([...this.ticketDepartmentModel, this.typing.ticketDepartment], 'id')
                            this.ticketKindModel = _uniqBy([...this.ticketKindModel, this.typing.ticketKind], 'id')
                            this.ticketTypeModel = _uniqBy([...this.ticketTypeModel, this.typing.ticketType], 'id')
                        } else {
                            this.ticketDepartmentModel = this.typing.ticketDepartment
                            this.ticketKindModel = this.typing.ticketKind
                            this.ticketTypeModel = this.typing.ticketType
                        }

                        setTimeout(() => {
                            this.innerAutofill = false
                        }, 1000)
                    }
                },
            },

            search() {
                this.isSearchChanging = true
            },
        },

        mounted() {
            this.fetchTyping = _debounce(() => {
                this.fetchData()
            }, 1 * 1000)
        },

        methods: {
            async fetchData() {
                if (this.isFetching || !this.isSearchChanging) {
                    return
                }

                this.isFetching = true

                try {
                    const response = await API.v1.services.ticket.getTicketTypification(this.params)

                    const { results } = response

                    const typingList = _map(results, (typing) => {
                        const item = new TicketTypingItem(typing)
                        item.id = item?.ticketType?.id
                        item.name = item?.ticketDepartment?.name + ' / ' + item?.ticketKind?.name + ' / ' + item?.ticketType?.name

                        return item
                    })

                    this.typingList = typingList
                    this.typing = null
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось получить типизацию', e)
                    this.$logger.logException(e)
                }

                this.isFetching = false
                this.isSearchChanging = false
            },

            customFilter(item, queryText) {
                if (queryText) {
                    const fullText = item?.name?.toLocaleLowerCase() || ''

                    // формируем регулярное выражение поиска подстрок.
                    // учитываем только текст и цифры во всех вариациях имени.
                    let clearQueryText = queryText.trim().replace(/[^a-zA-Zа-яА-Я0-9]/g, ' ').toLocaleLowerCase()

                    // чистка от двойных пробелов, чтобы не сломать регулярку
                    clearQueryText = clearQueryText.replace(/\s{2}/g, ' ')

                    const regText = '(' + clearQueryText.split(' ').join('|') + ')'
                    const regEx = new RegExp(regText, 'g')

                    // поиск всех подстрок
                    return fullText.match(regEx)
                }

                return true
            },
        },
    }
</script>
