import Entity from '_entities/base/EntityNew'

import CityLink from '_entities/city/CityLink'
import User from '_entities/user/User'

export const ResidentialComplexSchema = {
    name: 'ResidentialComplex',
    primaryKey: 'id',
    properties: {
        city: { type: 'CityLink', entity: CityLink },
        id: { type: 'string' },
        name: { type: 'string' },
        order: { type: 'int' },
        isActive: { type: 'bool', default: false },
        deletedAt: { type: 'int' },
        deletedBy: { type: 'User', entity: User },
    },
}

class ResidentialComplex extends Entity {
    get schema() {
        return ResidentialComplexSchema
    }

    get dataMap() {
        return {
            is_active: 'isActive',
            deleted_at: 'deletedAt',
            deleted_by: 'deletedBy',
        }
    }

    static fieldLabels = {
        city: 'Город',
        id: 'ID',
        name: 'Название',
        order: 'Порядок',
    }

    toArray() {
        return {
            name: this.name,
            city: this.city?.id,
        }
    }
}

export default ResidentialComplex
