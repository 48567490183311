import Entity from '_entities/base/EntityNew'

import UserProfile from '_entities/user/UserProfile'
import Worker from '_entities/worker/Worker'

export const UserSchema = {
    name: 'User',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        isActive: { type: 'bool', default: true },
        isStaff: { type: 'bool', default: false },
        username: { type: 'string' },
        userProfile: { type: 'UserProfile', entity: UserProfile, default: () => new UserProfile() },
        worker: { type: 'Worker', entity: Worker },
        deletedAt: { type: 'int' },
        deletedBy: { type: 'User', entity: 'self' },
    },
}

class User extends Entity {
    get schema() {
        return UserSchema
    }

    get dataMap() {
        return {
            is_active: 'isActive',
            is_staff: 'isStaff',
            user_profile: 'userProfile',
            deleted_at: 'deletedAt',
            deleted_by: 'deletedBy',
        }
    }

    static fieldLabels = {
        isActive: 'Статус активности',
        isStaff: 'Статус персонала',
        username: 'Логин пользователя',
        userProfile: 'Профиль',
        worker: 'Данные сотрудника',
    }

    get asUserOld() {
        return {
            email: this.userProfile?.email,
            first_name: this.userProfile?.firstName,
            id: this.id,
            last_name: this.userProfile?.lastName,
            middle_name: this.userProfile?.middleName,
            photo: this.userProfile?.photo,
            phone: this.userProfile?.phone,
        }
    }

    get toServer() {
        return {
            is_active: this.isActive,
            isStaff: this.isStaff,
            username: this.username,
            user_profile: this.userProfile.toServer(),
            worker: this.worker.toServer(),
        }
    }
}

export default User
