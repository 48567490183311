import mutations from './mutations'
import actions from './actions'

const state = {
    callerId: null,
    callerPhone: null,
    cardColor: null,
    cardTitle: null,
    serviceSompanyName: null,
    errors: null,
    isFetching: false,
    isShownDialog: false,
    showPhonePanel: false,
    resident: null,
}

export default {
    namespaced: true,
    actions,
    state,
    mutations,
}
