import FileBase from '_entities/media/file/FileBase'

export const FileDocumentSchema = {
    name: 'FileDocument',
    properties: {},
}

class FileDocument extends FileBase {
    get schema() {
        return {
            ...super.schema,
            ...FileDocumentSchema,
            properties: {
                ...super.schema.properties,
                ...FileDocumentSchema.properties,
            },
        }
    }
}

export default FileDocument
