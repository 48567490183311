import Entity from '_entities/base/EntityNew'

export const UserCountersSchema = {
    name: 'UserCounters',
    properties: {
        needAnswerSectionCount: { type: 'int', default: 0 },
        needSolutionCount: { type: 'int', default: 0 },
        needSolutionCommentCount: { type: 'int', default: 0 },
        ticketsOpen: { type: 'int', default: 0 },
        ticketsUnread: { type: 'int', default: 0 },
        ticketsNew: { type: 'int', default: 0 },
        ticketsNewUnread: { type: 'int', default: 0 },
        ticketsInProgress: { type: 'int', default: 0 },
        ticketsInProgressUnread: { type: 'int', default: 0 },
        ticketsExecutorAppointed: { type: 'int', default: 0 },
        ticketsExecutorAppointedUnread: { type: 'int', default: 0 },
        ticketsNeedExecutor: { type: 'int', default: 0 },
        ticketsNeedExecutorUnread: { type: 'int', default: 0 },
        ticketsOnCheck: { type: 'int', default: 0 },
        ticketsOnCheckUnread: { type: 'int', default: 0 },
        ticketsOnAdditionalCheck: { type: 'int', default: 0 },
        ticketsOnAdditionalCheckUnread: { type: 'int', default: 0 },
        ticketsOverdueCount: { type: 'int', default: 0 },
        ticketsAlmostOverdueCount: { type: 'int', default: 0 },
        ticketsAuditCount: { type: 'int', default: 0 },
        userId: { type: 'string' },
    },
}

class UserCounters extends Entity {
    get schema() {
        return UserCountersSchema
    }

    get dataMap() {
        return {
            need_answer_section_count: 'needAnswerSectionCount',
            need_solution_count: 'needSolutionCount',
            need_solution_comment_count: 'needSolutionCommentCount',
            tickets_open: 'ticketsOpen',
            tickets_unread: 'ticketsUnread',
            tickets_new: 'ticketsNew',
            tickets_new_unread: 'ticketsNewUnread',
            tickets_in_progress: 'ticketsInProgress',
            tickets_in_progress_unread: 'ticketsInProgressUnread',
            tickets_executor_appointed: 'ticketsExecutorAppointed',
            tickets_executor_appointed_unread: 'ticketsExecutorAppointedUnread',
            tickets_need_executor: 'ticketsNeedExecutor',
            tickets_need_executor_unread: 'ticketsNeedExecutorUnread',
            tickets_on_check: 'ticketsOnCheck',
            tickets_on_check_unread: 'ticketsOnCheckUnread',
            tickets_on_additional_check: 'ticketsOnAdditionalCheck',
            tickets_on_additional_check_unread: 'ticketsOnAdditionalCheckUnread',
            tickets_overdue_count: 'ticketsOverdueCount',
            tickets_almost_overdue_count: 'ticketsAlmostOverdueCount',
            tickets_audit_count: 'ticketsAuditCount',
            user_id: 'userId',
        }
    }
}

export default UserCounters
