import Entity from '_entities/base/EntityNew'

export const ServiceBuyerCatalogInfoElementSchema = {
    name: 'ServiceBuyerCatalogInfoElement',
    properties: {
        label: { type: 'string' },
        value: { type: 'string' },
    },
}

class ServiceBuyerCatalogInfoElement extends Entity {
    get schema() {
        return ServiceBuyerCatalogInfoElementSchema
    }

    static fieldLabels = {
        label: 'Наименование',
        value: 'Значение',
    }
}

export default ServiceBuyerCatalogInfoElement
