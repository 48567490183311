import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ru from 'vuetify/es5/locale/ru'
import light from './theme'

import { library as faLibrary } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
    faAnalytics as falAnalytics,
    faAngleLeft as falAngleLeft,
    faAngleRight as falAngleRight,
    faArrowAltFromBottom as falArrowAltFromBottom,
    faArrowAltToBottom as falArrowAltToBottom,
    faArrowDown as falArrowDown,
    faArrowLeft as falArrowLeft,
    faArrowRight as falArrowRight,
    faArrowRightFromBracket as falArrowRightFromBracket,
    faArrowRightToBracket as falArrowRightToBracket,
    faArrowsAlt as falArrowsAlt,
    faArrowUpRightFromSquare as falArrowUpRightFromSquare,
    faBarcode as falBarcode,
    faBars as falBars,
    faBan as falBan,
    faBell as falBell,
    faBook as falBook,
    faBoxesAlt as falBoxesAlt,
    faBuilding as falBuilding,
    faCalendarDay as falCalendarDay,
    faCamera as falCamera,
    faCar as falCar,
    faCaretDown as falCaretDown,
    faCaretLeft as falCaretLeft,
    faCaretRight as falCaretRight,
    faCaretUp as falCaretUp,
    faChartPie as falChartPie,
    faCheck as falCheck,
    faCheckDouble as falCheckDouble,
    faCheckCircle as falCheckCircle,
    faCircle as falCircle,
    faCity as falCity,
    faClipboardList as falClipboardList,
    faClock as falClock,
    faClone as falClone,
    faCode as falCode,
    faCog as falCog,
    faComment as falComment,
    faComments as falComments,
    faEdit as falEdit,
    faEllipsisH as falEllipsisH,
    faEllipsisV as falEllipsisV,
    faExclamationTriangle as falExclamationTriangle,
    faExternalLinkAlt as falExternalLinkAlt,
    faFile as falFile,
    faFileInvoice as falFileInvoice,
    faFolder as falFolder,
    faFolderOpen as falFolderOpen,
    faGripLines as falGripLines,
    faHashtag as falHashtag,
    faHistory as falHistory,
    faHome as falHome,
    faHouse as falHouse,
    faHouseUser as falHouseUser,
    faInboxIn as falInboxIn,
    faInboxOut as falInboxOut,
    faInfo as falInfo,
    faImage as falImage,
    faKey as falKey,
    faLink as falLink,
    faList as falList,
    faListTree as falListTree,
    faNewspaper as falNewspaper,
    faMailBulk as falMailBulk,
    faMessage as falMessage,
    faMinus as falMinus,
    faMobileScreen as falMobileScreen,
    faMoneyBill as falMoneyBill,
    faPaperPlane as falPaperPlane,
    faPause as falPause,
    faPaperclip as falPaperclip,
    faParking as falParking,
    faPencilPaintbrush as falPencilPaintbrush,
    faPeopleArrows as falPeopleArrows,
    faPhoneAlt as falPhoneAlt,
    faPhoneArrowRight as falPhoneArrowRight,
    faPhoneOffice as falPhoneOffice,
    faPhoneSlash as falPhoneSlash,
    faPlay as falPlay,
    faPlug as falPlug,
    faPlus as falPlus,
    faQuestion as falQuestion,
    faRubleSign as falRubleSign,
    faSave as falSave,
    faSearch as falSearch,
    faSms as falSms,
    faSort as falSort,
    faStop as falStop,
    faSquare as falSquare,
    faStar as falStar,
    faTemperatureFull as falTemperatureFull,
    faTimes as falTimes,
    faTools as falTools,
    faTrash as falTrash,
    faTrashRestore as falTrashRestore,
    faTriangleExclamation as falTriangleExclamation,
    faUser as falUser,
    faUsers as falUsers,
    faUserTie as falUserTie,
    faUserCircle as falUserCircle,
    faVideo as falVideo,
    faVolume as falVolume,
    faVolumeMute as falVolumeMute,
    faWarehouse as falWarehouse,
} from '@fortawesome/pro-light-svg-icons'

import {
    faArrowRightFromBracket as fasArrowRightFromBracket,
    faArrowRightToBracket as fasArrowRightToBracket,
    faCaretLeft as fasCaretLeft,
    faCaretRight as fasCaretRight,
    faCheckCircle as fasCheckCircle,
    faCheckSquare as fasCheckSquare,
    faChevronDown as fasChevronDown,
    faChevronUp as fasChevronUp,
    faDotCircle as fasDotCircle,
    faFile as fasFile,
    faMicrophone as fasMicrophone,
    faMicrophoneSlash as fasMicrophoneSlash,
    faMinusCircle as fasMinusCircle,
    faPause as fasPause,
    faPen as fasPen,
    faPhone as fasPhone,
    faPhoneArrowDownLeft as fasPhoneArrowDownLeft,
    faPhoneArrowRight as fasPhoneArrowRight,
    faPhoneArrowUpRight as fasPhoneArrowUpRight,
    faPhoneHangup as fasPhoneHangup,
    faPhoneOffice as fasPhoneOffice,
    faPhoneMissed as fasPhoneMissed,
    faPlay as fasPlay,
    faPlusCircle as fasPlusCircleSolid,
    faPhoneSlash as fasPhoneSlash,
    faPhoneVolume as fasPhoneVolume,
    faQuestion as fasQuestion,
    faRobot as fasRobot,
    faRotateLeft as fasRotateLeft,
    faScrewdriverWrench as fasScrewdriverWrench,
    faStar as fasStar,
    faUser as fasUser,
} from '@fortawesome/pro-solid-svg-icons'

import {
    faCheckCircle as farCheckCircle,
} from '@fortawesome/pro-regular-svg-icons'

import {
    faCheckCircle as fatCheckCircle,
} from '@fortawesome/pro-thin-svg-icons'

import {
    faCheckCircle as fadCheckCircle,
} from '@fortawesome/pro-duotone-svg-icons'

const makeIconComponentDeclaration = (icon) => {
    faLibrary.add(icon)

    return {
        component: FontAwesomeIcon,
        props: {
            icon: [icon.prefix, icon.iconName],
        },
    }
}

const MY_ICONS = {
    // Vuetify
    complete: '...',
    cancel: '...',
    close: makeIconComponentDeclaration(falTimes),
    delete: makeIconComponentDeclaration(falTimes), // delete (e.g. v-chip close)
    clear: makeIconComponentDeclaration(falTimes),
    book: makeIconComponentDeclaration(falBook),
    success: 'success',
    info: makeIconComponentDeclaration(falInfo),
    warning: 'warning',
    error: makeIconComponentDeclaration(falExclamationTriangle),
    prev: makeIconComponentDeclaration(falAngleLeft),
    next: makeIconComponentDeclaration(falAngleRight),
    checkboxOn: makeIconComponentDeclaration(fasCheckSquare),
    checkboxOff: makeIconComponentDeclaration(falSquare),
    checkboxIndeterminate: '...',
    delimiter: 'delimiter', // for carousel
    sort: makeIconComponentDeclaration(falArrowDown),
    expand: makeIconComponentDeclaration(falCaretDown),
    caretLeft: makeIconComponentDeclaration(falCaretLeft),
    caretLeftSolid: makeIconComponentDeclaration(fasCaretLeft),
    caretRight: makeIconComponentDeclaration(falCaretRight),
    caretRightSolid: makeIconComponentDeclaration(fasCaretRight),
    chevronDown: makeIconComponentDeclaration(fasChevronDown),
    chevronUp: makeIconComponentDeclaration(fasChevronUp),
    menu: makeIconComponentDeclaration(falEllipsisV),
    more: makeIconComponentDeclaration(falEllipsisH),
    subgroup: makeIconComponentDeclaration(falCaretDown),
    dropdown: makeIconComponentDeclaration(falCaretDown),
    radioOn: makeIconComponentDeclaration(fasDotCircle),
    radioOff: makeIconComponentDeclaration(falCircle),
    edit: makeIconComponentDeclaration(falEdit),
    ratingEmpty: makeIconComponentDeclaration(falStar),
    toolsSolid: makeIconComponentDeclaration(fasScrewdriverWrench),
    ratingFull: makeIconComponentDeclaration(fasStar),
    ratingHalf: 'ratingHalf',
    loading: 'loading',
    first: 'first',
    last: 'last',
    unfold: makeIconComponentDeclaration(falSort),

    file__Light: makeIconComponentDeclaration(falFile),
    file__Solid: makeIconComponentDeclaration(fasFile),

    // Other
    analytics: makeIconComponentDeclaration(falAnalytics),
    arrowAltFromBottom: makeIconComponentDeclaration(falArrowAltFromBottom),
    arrowAltToBottom: makeIconComponentDeclaration(falArrowAltToBottom),
    arrowLeft: makeIconComponentDeclaration(falArrowLeft),
    arrowRight: makeIconComponentDeclaration(falArrowRight),
    arrowsAlt: makeIconComponentDeclaration(falArrowsAlt),
    arrowUpRightFromSquare: makeIconComponentDeclaration(falArrowUpRightFromSquare),
    exit: makeIconComponentDeclaration(falArrowRightFromBracket),
    exitSolid: makeIconComponentDeclaration(fasArrowRightFromBracket),
    enter: makeIconComponentDeclaration(falArrowRightToBracket),
    enterSolid: makeIconComponentDeclaration(fasArrowRightToBracket),
    barcode: makeIconComponentDeclaration(falBarcode),
    bars: makeIconComponentDeclaration(falBars),
    ban: makeIconComponentDeclaration(falBan),
    bell: makeIconComponentDeclaration(falBell),
    boxesAlt: makeIconComponentDeclaration(falBoxesAlt),
    building: makeIconComponentDeclaration(falBuilding),
    car: makeIconComponentDeclaration(falCar),
    calendarDay: makeIconComponentDeclaration(falCalendarDay),
    camera: makeIconComponentDeclaration(falCamera),
    chartPie: makeIconComponentDeclaration(falChartPie),
    caretUp: makeIconComponentDeclaration(falCaretUp),
    check: makeIconComponentDeclaration(falCheck),
    checkDouble: makeIconComponentDeclaration(falCheckDouble),

    checkCircle__Light: makeIconComponentDeclaration(falCheckCircle),
    checkCircle__Solid: makeIconComponentDeclaration(fasCheckCircle),
    checkCircle__Regular: makeIconComponentDeclaration(farCheckCircle),
    checkCircle__Tin: makeIconComponentDeclaration(fatCheckCircle),
    checkCircle__DuoTone: makeIconComponentDeclaration(fadCheckCircle),

    city: makeIconComponentDeclaration(falCity),
    clipboardList: makeIconComponentDeclaration(falClipboardList),
    clock: makeIconComponentDeclaration(falClock),
    clone: makeIconComponentDeclaration(falClone),
    code: makeIconComponentDeclaration(falCode),
    cog: makeIconComponentDeclaration(falCog),
    comment: makeIconComponentDeclaration(falComment),
    comments: makeIconComponentDeclaration(falComments),
    externalLinkAlt: makeIconComponentDeclaration(falExternalLinkAlt),
    fileInvoice: makeIconComponentDeclaration(falFileInvoice),
    folder: makeIconComponentDeclaration(falFolder),
    folderOpen: makeIconComponentDeclaration(falFolderOpen),
    gripLines: makeIconComponentDeclaration(falGripLines),
    history: makeIconComponentDeclaration(falHistory),
    hashtag: makeIconComponentDeclaration(falHashtag),
    home: makeIconComponentDeclaration(falHome),
    house: makeIconComponentDeclaration(falHouse),
    houseUser: makeIconComponentDeclaration(falHouseUser),
    inboxOut: makeIconComponentDeclaration(falInboxOut),
    inboxIn: makeIconComponentDeclaration(falInboxIn),
    image: makeIconComponentDeclaration(falImage),
    key: makeIconComponentDeclaration(falKey),
    link: makeIconComponentDeclaration(falLink),
    list: makeIconComponentDeclaration(falList),
    listTree: makeIconComponentDeclaration(falListTree),
    newspaper: makeIconComponentDeclaration(falNewspaper),
    mailBulk: makeIconComponentDeclaration(falMailBulk),
    minus: makeIconComponentDeclaration(falMinus),
    message: makeIconComponentDeclaration(falMessage),
    microphone__Solid: makeIconComponentDeclaration(fasMicrophone),
    microphoneSlash__Solid: makeIconComponentDeclaration(fasMicrophoneSlash),
    minusCircleSolid: makeIconComponentDeclaration(fasMinusCircle),
    mobileScreen: makeIconComponentDeclaration(falMobileScreen),
    moneyBill: makeIconComponentDeclaration(falMoneyBill),
    paperPlane: makeIconComponentDeclaration(falPaperPlane),
    paperclip: makeIconComponentDeclaration(falPaperclip),
    parking: makeIconComponentDeclaration(falParking),

    pause: makeIconComponentDeclaration(falPause),
    pause__Solid: makeIconComponentDeclaration(fasPause),

    pen__Solid: makeIconComponentDeclaration(fasPen),

    pencilPaintbrush: makeIconComponentDeclaration(falPencilPaintbrush),
    peopleArrows: makeIconComponentDeclaration(falPeopleArrows),
    phoneSolid: makeIconComponentDeclaration(fasPhone),
    phoneAlt: makeIconComponentDeclaration(falPhoneAlt),
    phoneInSolid: makeIconComponentDeclaration(fasPhoneArrowDownLeft),
    phoneOutSolid: makeIconComponentDeclaration(fasPhoneArrowUpRight),
    phoneHangup__Solid: makeIconComponentDeclaration(fasPhoneHangup),
    phoneMissedSolid: makeIconComponentDeclaration(fasPhoneMissed),
    phoneArrowRight: makeIconComponentDeclaration(falPhoneArrowRight),
    phoneArrowRight__Solid: makeIconComponentDeclaration(fasPhoneArrowRight),
    phoneOffice: makeIconComponentDeclaration(falPhoneOffice),
    phoneOfficeSolid: makeIconComponentDeclaration(fasPhoneOffice),
    phoneSlash: makeIconComponentDeclaration(falPhoneSlash),
    phoneSlashSolid: makeIconComponentDeclaration(fasPhoneSlash),
    phoneVolume__Solid: makeIconComponentDeclaration(fasPhoneVolume),
    play: makeIconComponentDeclaration(falPlay),
    play__Solid: makeIconComponentDeclaration(fasPlay),
    plug: makeIconComponentDeclaration(falPlug),
    plus: makeIconComponentDeclaration(falPlus),
    plusCircleOutline: makeIconComponentDeclaration(fasPlusCircleSolid),
    question: makeIconComponentDeclaration(falQuestion),
    question__Solid: makeIconComponentDeclaration(fasQuestion),
    robot__Solid: makeIconComponentDeclaration(fasRobot),
    rotateLeft: makeIconComponentDeclaration(fasRotateLeft),
    rubleSign: makeIconComponentDeclaration(falRubleSign),
    search: makeIconComponentDeclaration(falSearch),
    sms: makeIconComponentDeclaration(falSms),
    stop: makeIconComponentDeclaration(falStop),
    temperature: makeIconComponentDeclaration(falTemperatureFull),
    tools: makeIconComponentDeclaration(falTools),
    trash: makeIconComponentDeclaration(falTrash),
    trashRestore: makeIconComponentDeclaration(falTrashRestore),
    triangleExclamation: makeIconComponentDeclaration(falTriangleExclamation),
    user: makeIconComponentDeclaration(falUser),
    user__Solid: makeIconComponentDeclaration(fasUser),
    users: makeIconComponentDeclaration(falUsers),
    userTie: makeIconComponentDeclaration(falUserTie),
    userCircle: makeIconComponentDeclaration(falUserCircle),
    video: makeIconComponentDeclaration(falVideo),
    volume: makeIconComponentDeclaration(falVolume),
    volumeMute: makeIconComponentDeclaration(falVolumeMute),
    save: makeIconComponentDeclaration(falSave),
    warehouse: makeIconComponentDeclaration(falWarehouse),
}

Vue.use(Vuetify)

export default new Vuetify({
    lang: {
        locales: { ru },
        current: 'ru',
    },
    icons: {
        values: MY_ICONS,
    },
    theme: {
        themes: { light },
    },
})
