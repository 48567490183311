const TelephonyNavigator = () => import('_navigations/Home/Telephony/TelephonyNavigator')

const AccidentsScreen = () => import('_screens/Home/Telephony/AccidentsScreen')
const AccidentEditScreen = () => import('_screens/Home/Telephony/AccidentEditScreen')
const PhoneCallsScreen = () => import('_screens/Home/Telephony/PhoneCallsScreen')
const PhoneSectionScreen = () => import('_screens/Home/Telephony/PhoneSectionScreen')

const telephonyRoutes = [
    {
        path: 'telephony',
        name: 'TelephonyNavigator',
        redirect: { name: 'Accident' },
        component: TelephonyNavigator,
        children: [
            {
                path: 'accident/',
                name: 'Accident',
                component: AccidentsScreen,
                meta: {
                    screenOptions: {
                        title: 'Автоинформатор',
                    },
                },
            },
            {
                path: 'accident/edit/:accidentId?',
                name: 'AccidentEdit',
                component: AccidentEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Новый автоинформатор',
                    },
                },
            },
            {
                path: 'phone-calls/',
                name: 'PhoneCalls',
                component: PhoneCallsScreen,
                meta: {
                    screenOptions: {
                        title: 'Звонки',
                    },
                },
            },
            {
                path: 'phone-section/',
                name: 'PhoneSection',
                component: PhoneSectionScreen,
                meta: {
                    screenOptions: {
                        title: 'Панель телефонии',
                    },
                },
            },
        ],
    },
]

export default telephonyRoutes
