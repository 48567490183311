export const PERMISSIONS = {
    // Module: acceptance
    CAN_ADD_ACCEPTANCE: 'acceptance.add_acceptance',
    CAN_ADD_ACCEPTANCE_CHECKLIST: 'acceptance.add_acceptancechecklist',
    CAN_ADD_ACCEPTANCE_CHECKLIST_ELEMENT_HISTORY: 'acceptance.add_acceptancechecklistelementhistory',
    CAN_ADD_ACCEPTANCE_CHECKLIST_INFO: 'acceptance.add_acceptancechecklistinfo',
    CAN_ADD_ACCEPTANCE_CHECKLIST_ITEM: 'acceptance.add_acceptancechecklistitem',
    CAN_ADD_ACCEPTANCE_GROUP: 'acceptance.add_acceptancegroup',
    CAN_ADD_ACCEPTANCE_TYPE: 'acceptance.add_acceptancetype',
    CAN_CHANGE_ACCEPTANCE: 'acceptance.change_acceptance',
    CAN_CHANGE_ACCEPTANCE_CHECKLIST: 'acceptance.change_acceptancechecklist',
    CAN_CHANGE_ACCEPTANCE_CHECKLIST_ELEMENT_HISTORY: 'acceptance.change_acceptancechecklistelementhistory',
    CAN_CHANGE_ACCEPTANCE_CHECKLIST_INFO: 'acceptance.change_acceptancechecklistinfo',
    CAN_CHANGE_ACCEPTANCE_CHECKLIST_ITEM: 'acceptance.change_acceptancechecklistitem',
    CAN_CHANGE_ACCEPTANCE_GROUP: 'acceptance.change_acceptancegroup',
    CAN_CHANGE_ACCEPTANCE_TYPE: 'acceptance.change_acceptancetype',
    CAN_DELETE_ACCEPTANCE: 'acceptance.delete_acceptance',
    CAN_DELETE_ACCEPTANCE_CHECKLIST: 'acceptance.delete_acceptancechecklist',
    CAN_DELETE_ACCEPTANCE_CHECKLIST_ELEMENT_HISTORY: 'acceptance.delete_acceptancechecklistelementhistory',
    CAN_DELETE_ACCEPTANCE_CHECKLIST_INFO: 'acceptance.delete_acceptancechecklistinfo',
    CAN_DELETE_ACCEPTANCE_CHECKLIST_ITEM: 'acceptance.delete_acceptancechecklistitem',
    CAN_DELETE_ACCEPTANCE_GROUP: 'acceptance.delete_acceptancegroup',
    CAN_DELETE_ACCEPTANCE_TYPE: 'acceptance.delete_acceptancetype',
    CAN_VIEW_ACCEPTANCE: 'acceptance.view_acceptance',
    CAN_VIEW_ACCEPTANCE_CHECKLIST: 'acceptance.view_acceptancechecklist',
    CAN_VIEW_ACCEPTANCE_CHECKLIST_ELEMENT_HISTORY: 'acceptance.view_acceptancechecklistelementhistory',
    CAN_VIEW_ACCEPTANCE_CHECKLIST_INFO: 'acceptance.view_acceptancechecklistinfo',
    CAN_VIEW_ACCEPTANCE_CHECKLIST_ITEM: 'acceptance.view_acceptancechecklistitem',
    CAN_VIEW_ACCEPTANCE_GROUP: 'acceptance.view_acceptancegroup',
    CAN_VIEW_ACCEPTANCE_TYPE: 'acceptance.view_acceptancetype',

    // Module: accident
    CAN_ADD_ACCIDENT: 'accident.add_accident',
    CAN_ADD_ACCIDENT_COMPANY: 'accident.add_accidentcompany',
    CAN_ADD_ACCIDENT_TYPE: 'accident.add_accidenttype',
    CAN_ADD_MAINTENANCE_WORK_EVENT: 'accident.add_maintenanceworkevent',
    CAN_CHANGE_ACCIDENT: 'accident.change_accident',
    CAN_CHANGE_ACCIDENT_COMPANY: 'accident.change_accidentcompany',
    CAN_CHANGE_ACCIDENT_TYPE: 'accident.change_accidenttype',
    CAN_CHANGE_MAINTENANCE_WORK_EVENT: 'accident.change_maintenanceworkevent',
    CAN_DELETE_ACCIDENT: 'accident.delete_accident',
    CAN_DELETE_ACCIDENT_COMPANY: 'accident.delete_accidentcompany',
    CAN_DELETE_ACCIDENT_TYPE: 'accident.delete_accidenttype',
    CAN_DELETE_MAINTENANCE_WORK_EVENT: 'accident.delete_maintenanceworkevent',
    CAN_VIEW_ACCIDENT: 'accident.view_accident',
    CAN_VIEW_ACCIDENT_COMPANY: 'accident.view_accidentcompany',
    CAN_VIEW_ACCIDENT_TYPE: 'accident.view_accidenttype',
    CAN_VIEW_MAINTENANCE_WORK_EVENT: 'accident.view_maintenanceworkevent',

    // Module: analytics
    CAN_VIEW_ANALYTICS: 'analytics.can_view_analytics',

    CAN_VIEW_COMMON_CSI_REPORT: 'analytics.common-сsi_report',
    CAN_VIEW_COMMON_NPS_REPORT: 'analytics.common-nps_report',
    CAN_VIEW_COMMON_TICKET_REPORT: 'analytics.common-ticket_report',
    CAN_VIEW_COMMON_INSPECTION_REPORT: 'analytics.common-inspection_report',
    CAN_VIEW_COMMON_SPC_REPORT: 'analytics.common-spc_report',
    CAN_VIEW_COMMON_SERVICES_REPORT: 'analytics.common-services_report',
    CAN_VIEW_COMMON_MOBILEAPP_REPORT: 'analytics.common-mobileapp_report',
    CAN_VIEW_COMMON_ITHER_REPORTS: 'analytics.common-other_reports',
    CAN_VIEW_COMMON_UK_REPORT: 'analytics.common-uk_rating',
    CAN_VIEW_COMMON_EMPLOYER_KPI: 'analytics.common-employer_kpi',
    CAN_VIEW_COMMON_VOTE: 'analytics.common-vote',
    CAN_VIEW_COMMON_TELEGRAM_CHANNELS_ACTIVITY: 'analytics.common-telegram_channels_activity',
    CAN_VIEW_COMMON_PLANNED_OUTAGES: 'analytics.common-planned_outages',

    CAN_VIEW_SECTORS_DASHBOARD_ID_REPORT: 'analytics.sectors-dashboard_id_report',
    CAN_VIEW_SECTORS_CLEANING: 'analytics.sectors-cleaning',
    CAN_VIEW_SECTORS_ENGINEER_REPORT: 'analytics.sectors-engineer_report',
    CAN_VIEW_SECTORS_HEAD_REPORT: 'analytics.sectors-head_report',
    CAN_VIEW_SECTORS_MANAGER_REPORT: 'analytics.sectors-manager_report',

    CAN_VIEW_TECHSERVICE_DIRECTOR: 'analytics.techservice-director',
    CAN_VIEW_TECHSERVICE_GUARANTEE: 'analytics.techservice-guarantee',
    CAN_VIEW_TECHSERVICE_GUARANTEE_KPI: 'analytics.techservice-guarantee_kpi',
    CAN_VIEW_TECHSERVICE_ACCEPTANCE: 'analytics.techservice-acceptance',
    CAN_VIEW_TECHSERVICE_GUARANTEE_TICKETS: 'analytics.techservice-guarantee_tickets',

    CAN_VIEW_COMMERCIAL_PAID_SERVICES: 'analytics.commercial-paid_services',
    CAN_VIEW_COMMERCIAL_COMMON_PROPERTY_LEASE: 'analytics.commercial-common_property_lease',

    CAN_VIEW_COMMON_IT_REPORT: 'analytics.common-it_report',

    CAN_VIEW_COMMON_LEGAL_DEPARTMENT_REPORT: 'analytics.common-legal_department_report',
    CAN_VIEW_COMMON_LEGAL_DEPARTMENT_ACCOUNTS_RECEIVABLE: 'analytics.legal_department-accounts_receivable',

    CAN_VIEW_COMMON_TESTING_REPORT: 'analytics.common-testing_report',

    // Module: auth
    CAN_ADD_AUTH_GROUP: 'auth.add_group',
    CAN_ADD_AUTH_PERMISSION: 'auth.add_permission',
    CAN_CHANGE_AUTH_GROUP: 'auth.change_group',
    CAN_CHANGE_AUTH_PERMISSION: 'auth.change_permission',
    CAN_DELETE_AUTH_GROUP: 'auth.delete_group',
    CAN_DELETE_AUTH_PERMISSION: 'auth.delete_permission',
    CAN_VIEW_AUTH_GROUP: 'auth.view_group',
    CAN_VIEW_AUTH_PERMISSION: 'auth.view_permission',

    // Module: calendar
    CAN_CREATE_SLOT: 'bh_calendar.create_calendar_slot',
    CAN_DELETE_SLOT: 'bh_calendar.delete_calendar_slot',
    CAN_EDIT_OWN_DEPARTMENT: 'bh_calendar.only_own_department',
    CAN_UPDATE_SLOT: 'bh_calendar.update_calendar_slot',
    CAN_VIEW_SLOT: 'bh_calendar.view_calendar_slot',

    // Report
    CAN_CHANGE_PHOTOREPORT: 'bh_sc_report.change_photoreport',
    CAN_CHANGE_SCREPORT: 'bh_sc_report.change_screport',
    CAN_VIEW_PHOTOREPORT: 'bh_sc_report.view_photoreport',
    CAN_VIEW_SCREPORT: 'bh_sc_report.view_screport',

    // Module: call
    CAN_ADD_CALL: 'call.add_call',
    CAN_CHANGE_CALL: 'call.change_call',
    CAN_CHANGE_RECALL_SECTION: 'call.change_recall_section',
    CAN_DELETE_CALL: 'call.delete_call',
    CAN_VIEW_CALL: 'call.view_call',
    CAN_VIEW_RECALL_SECTION: 'call.view_recall_section',

    // Module: chat
    CAN_ADD_CHAT: 'chat.add_chat',
    CAN_ADD_CHAT_TIME_SETTING: 'chat.add_chatanswertimelimitsetting',
    CAN_ADD_CHAT_EVENT: 'chat.add_chatevent',
    CAN_ADD_CHAT_MESSAGE: 'chat.add_chatmessage',
    CAN_ADD_CHAT_TYPICAL_ANSWER: 'chat.add_typicalanswer',
    CAN_ADD_CHAT_UNREAD_MESSAGE: 'chat.add_unreadmessage',
    CAN_CHANGE_CHAT: 'chat.change_chat',
    CAN_CHANGE_CHAT_TIME_SETTING: 'chat.change_chatanswertimelimitsetting',
    CAN_CHANGE_CHAT_EVENT: 'chat.change_chatevent',
    CAN_CHANGE_CHAT_MESSAGE: 'chat.change_chatmessage',
    CAN_CHANGE_CHAT_TYPICAL_ANSWER: 'chat.change_typicalanswer',
    CAN_CHANGE_CHAT_UNREAD_MESSAGE: 'chat.change_unreadmessage',
    CAN_CHAT_REPORT_GENERATION: 'chat.report_generation',
    CAN_DELETE_CHAT: 'chat.delete_chat',
    CAN_DELETE_CHAT_TIME_SETTING: 'chat.delete_chatanswertimelimitsetting',
    CAN_DELETE_CHAT_EVENT: 'chat.delete_chatevent',
    CAN_DELETE_CHAT_MESSAGE: 'chat.delete_chatmessage',
    CAN_DELETE_CHAT_TYPICAL_ANSWER: 'chat.delete_typicalanswer',
    CAN_DELETE_CHAT_UNREAD_MESSAGE: 'chat.delete_unreadmessage',
    CAN_VIEW_CHAT: 'chat.view_chat',
    CAN_VIEW_CHAT_TIME_SETTING: 'chat.view_chatanswertimelimitsetting',
    CAN_VIEW_CHAT_EVENT: 'chat.view_chatevent',
    CAN_VIEW_CHAT_MESSAGE: 'chat.view_chatmessage',
    CAN_VIEW_CHAT_TYPICALA_NSWER: 'chat.view_typicalanswer',
    CAN_VIEW_CHAT_UNREAD_MESSAGE: 'chat.view_unreadmessage',

    // Module: chatbot
    CAN_ADD_CHAT_BOT: 'chatbot.add_chatbot',
    CAN_ADD_CHAT_BOT_ANSWER: 'chatbot.add_chatbotanswer',
    CAN_CHANGE_CHAT_BOT: 'chatbot.change_chatbot',
    CAN_CHANGE_CHAT_BOT_ANSWER: 'chatbot.change_chatbotanswer',
    CAN_DELETE_CHAT_BOT: 'chatbot.delete_chatbot',
    CAN_DELETE_CHAT_BOT_ANSWER: 'chatbot.delete_chatbotanswer',
    CAN_VIEW_CHAT_BOT: 'chatbot.view_chatbot',
    CAN_VIEW_CHAT_BOT_ANSWER: 'chatbot.view_chatbotanswer',

    // Module: department
    CAN_ADD_ABSENT_REASON: 'department.add_absentreason',
    CAN_ADD_ABSENT_SCHEDULE: 'department.add_absentschedule',
    CAN_ADD_APPROVAL: 'department.add_approval',
    CAN_ADD_DEPARTMENT_COMPANY: 'department.add_company',
    CAN_ADD_DEPARTMENT_COMPANY_EMAIL: 'department.add_companyemail',
    CAN_ADD_DEPARTMENT_COMPANY_PHONE: 'department.add_companyphone',
    CAN_ADD_DEPARTMENT_COMPANY_REQUISITE: 'department.add_companyrequisite',
    CAN_ADD_DEPARTMENT: 'department.add_department',
    CAN_ADD_DEPARTMENT_TIMESHEET: 'department.add_timesheet',
    CAN_ADD_DEPARTMENT_WORKER: 'department.add_worker',
    CAN_ADD_DUTY_SCHEDULE: 'department.add_dutyschedule',
    CAN_SELECT_CALL_QUEUE: 'department.can_select_call_queue',
    CAN_CHANGE_ABSENT_REASON: 'department.change_absentreason',
    CAN_CHANGE_ABSENT_SCHEDULE: 'department.change_absentschedule',
    CAN_CHANGE_APPROVAL: 'department.change_approval',
    CAN_CHANGE_DEPARTMENT_COMPANY: 'department.change_company',
    CAN_CHANGE_DEPARTMENT_COMPANY_EMAIL: 'department.change_companyemail',
    CAN_CHANGE_DEPARTMENT_COMPANY_PHONE: 'department.change_companyphone',
    CAN_CHANGE_DEPARTMENT_COMPANY_REQUISITE: 'department.change_companyrequisite',
    CAN_CHANGE_DEPARTMENT: 'department.change_department',
    CAN_CHANGE_DEPARTMENT_TIMESHEET: 'department.change_timesheet',
    CAN_CHANGE_DEPARTMENT_WORKER: 'department.change_worker',
    CAN_CHANGE_DUTY_SCHEDULE: 'department.change_dutyschedule',
    CAN_DELETE_ABSENT_REASON: 'department.delete_absentreason',
    CAN_DELETE_ABSENT_SCHEDULE: 'department.delete_absentschedule',
    CAN_DELETE_APPROVAL: 'department.delete_approval',
    CAN_DELETE_DEPARTMENT_COMPANY: 'department.delete_company',
    CAN_DELETE_DEPARTMENT_COMPANY_EMAIL: 'department.delete_companyemail',
    CAN_DELETE_DEPARTMENT_COMPANY_PHONE: 'department.delete_companyphone',
    CAN_DELETE_DEPARTMENT_COMPANY_REQUISITE: 'department.delete_companyrequisite',
    CAN_DELETE_DEPARTMENT: 'department.delete_department',
    CAN_DELETE_DEPARTMENT_TIMESHEET: 'department.delete_timesheet',
    CAN_DELETE_DEPARTMENT_WORKER: 'department.delete_worker',
    CAN_DELETE_DUTY_SCHEDULE: 'department.delete_dutyschedule',
    CAN_VIEW_ABSENT_REASON: 'department.view_absentreason',
    CAN_VIEW_ABSENT_SCHEDULE: 'department.view_absentschedule',
    CAN_VIEW_APPROVAL: 'department.view_approval',
    CAN_VIEW_DEPARTMENT_COMPANY: 'department.view_company',
    CAN_VIEW_DEPARTMENT_COMPANY_EMAIL: 'department.view_companyemail',
    CAN_VIEW_DEPARTMENT_COMPANY_PHONE: 'department.view_companyphone',
    CAN_VIEW_DEPARTMENT_COMPANY_REQUISITE: 'department.view_companyrequisite',
    CAN_VIEW_DEPARTMENT: 'department.view_department',
    CAN_VIEW_DEPARTMENT_TIMESHEET: 'department.view_timesheet',
    CAN_VIEW_DEPARTMENT_WORKER: 'department.view_worker',
    CAN_VIEW_DUTY_SCHEDULE: 'department.view_dutyschedule',

    // Module: document
    CAN_ADD_LEGAL_DOCUMENT: 'document.add_legaldocument',
    CAN_CHANGE_LEGAL_DOCUMENT: 'document.change_legaldocument',
    CAN_DELETE_LEGAL_DOCUMENT: 'document.delete_legaldocument',
    CAN_VIEW_LEGAL_DOCUMENT: 'document.view_legaldocument',

    // Module: feedback
    CAN_ADD_FEEDBACK_CSI: 'feedback.add_csifeedback',
    CAN_ADD_FEEDBACK_CSI_POINT: 'feedback.add_csipoint',
    CAN_ADD_FEEDBACK_CSI_POLL: 'feedback.add_csipoll',
    CAN_ADD_FEEDBACK_NPS: 'feedback.add_npsfeedback',
    CAN_CHANGE_FEEDBACK_CSI: 'feedback.change_csifeedback',
    CAN_CHANGE_FEEDBACK_CSI_POINT: 'feedback.change_csipoint',
    CAN_CHANGE_FEEDBACK_CSI_POLL: 'feedback.change_csipoll',
    CAN_CHANGE_FEEDBACK_NPS: 'feedback.change_npsfeedback',
    CAN_DELETE_FEEDBACK_CSI: 'feedback.delete_csifeedback',
    CAN_DELETE_FEEDBACK_CSI_POINT: 'feedback.delete_csipoint',
    CAN_DELETE_FEEDBACK_CSI_POLL: 'feedback.delete_csipoll',
    CAN_DELETE_FEEDBACK_NPS: 'feedback.delete_npsfeedback',
    CAN_VIEW_FEEDBACK_CSI: 'feedback.view_csifeedback',
    CAN_VIEW_FEEDBACK_CSI_POINT: 'feedback.view_csipoint',
    CAN_VIEW_FEEDBACK_CSI_POLL: 'feedback.view_csipoll',
    CAN_VIEW_FEEDBACK_NPS: 'feedback.view_npsfeedback',

    // Module: files
    CAN_ADD_FILES_ATTACHMENT: 'files.add_attachment',
    CAN_ADD_FILES_IMAGE: 'files.add_image',
    CAN_CHANGE_FILES_ATTACHMENT: 'files.change_attachment',
    CAN_CHANGE_FILES_IMAGE: 'files.change_image',
    CAN_DELETE_FILES_ATTACHMENT: 'files.delete_attachment',
    CAN_DELETE_FILES_IMAGE: 'files.delete_image',
    CAN_VIEW_FILES_ATTACHMENT: 'files.view_attachment',
    CAN_VIEW_FILES_IMAGE: 'files.view_image',

    // Module: intercom
    CAN_ADD_INTERCOM: 'bh_intercom.add_bhintercom',
    CAN_CHANGE_INTERCOM: 'bh_intercom.change_bhintercom',
    CAN_DELETE_INTERCOM: 'bh_intercom.delete_bhintercom',
    CAN_VIEW_INTERCOM: 'bh_intercom.view_bhintercom',

    // Module: location
    CAN_ADD_LOCATION_PERSONAL_ACCOUNT: 'location.add_account',
    CAN_ADD_LOCATION_PERSONAL_ACCOUNT_ALLOWED_PERSON: 'location.add_accountallowedperson',
    CAN_ADD_LOCATION_ADDRESS: 'location.add_address',
    CAN_ADD_LOCATION_ALLOWED_PERSON: 'location.add_allowed_person',
    CAN_ADD_LOCATION_BUILDING: 'location.add_building',
    CAN_ADD_LOCATION_CAMERA: 'location.add_camera',
    CAN_ADD_LOCATION_CAMERA_GROUP: 'location.add_cameragroup',
    CAN_ADD_LOCATION_CITY: 'location.add_city',
    CAN_ADD_LOCATION_COMPANY_APPEAL_REASON: 'location.add_companyappealreason',
    CAN_ADD_LOCATION_ENTRANCE: 'location.add_entrance',
    CAN_ADD_LOCATION_PIPE: 'location.add_pipe',
    CAN_ADD_LOCATION_PREMISE: 'location.add_premise',
    CAN_ADD_LOCATION_PREMISE_TYPE: 'location.add_premisetype',
    CAN_ADD_LOCATION_RESIDENTIAL_COMPLEX: 'location.add_residentialcomplex',
    CAN_ADD_LOCATION_SECTOR: 'location.add_sector',
    CAN_CHANGE_LOCATION_PERSONAL_ACCOUNT: 'location.change_account',
    CAN_CHANGE_LOCATION_PERSONAL_ACCOUNT_ALLOWED_PERSON: 'location.change_accountallowedperson',
    CAN_CHANGE_LOCATION_ADDRESS: 'location.change_address',
    CAN_CHANGE_LOCATION_BUILDING: 'location.change_building',
    CAN_CHANGE_LOCATION_BUILDING_DOCS: 'location.change_building_docs',
    CAN_CHANGE_LOCATION_CAMERA: 'location.change_camera',
    CAN_CHANGE_LOCATION_CAMERA_GROUP: 'location.change_cameragroup',
    CAN_CHANGE_LOCATION_CITY: 'location.change_city',
    CAN_CHANGE_LOCATION_COMPANY_APPEAL_REASON: 'location.change_companyappealreason',
    CAN_CHANGE_LOCATION_ENTRANCE: 'location.change_entrance',
    CAN_CHANGE_LOCATION_PIPE: 'location.change_pipe',
    CAN_CHANGE_LOCATION_PREMISE: 'location.change_premise',
    CAN_CHANGE_LOCATION_PREMISE_TYPE: 'location.change_premisetype',
    CAN_CHANGE_LOCATION_RESIDENTIAL_COMPLEX: 'location.change_residentialcomplex',
    CAN_CHANGE_LOCATION_SECTOR: 'location.change_sector',
    CAN_DELETE_LOCATION_PERSONAL_ACCOUNT: 'location.delete_account',
    CAN_DELETE_LOCATION_PERSONAL_ACCOUNT_ALLOWED_PERSON: 'location.delete_accountallowedperson',
    CAN_DELETE_LOCATION_ADDRESS: 'location.delete_address',
    CAN_DELETE_LOCATION_BUILDING: 'location.delete_building',
    CAN_DELETE_LOCATION_CAMERA: 'location.delete_camera',
    CAN_DELETE_LOCATION_CAMERA_GROUP: 'location.delete_cameragroup',
    CAN_DELETE_LOCATION_CITY: 'location.delete_city',
    CAN_DELETE_LOCATION_COMPANY_APPEAL_REASON: 'location.delete_companyappealreason',
    CAN_DELETE_LOCATION_ENTRANCE: 'location.delete_entrance',
    CAN_DELETE_LOCATION_PIPE: 'location.delete_pipe',
    CAN_DELETE_LOCATION_PREMISE: 'location.delete_premise',
    CAN_DELETE_LOCATION_PREMISE_TYPE: 'location.delete_premisetype',
    CAN_DELETE_LOCATION_RESIDENTIAL_COMPLEX: 'location.delete_residentialcomplex',
    CAN_DELETE_LOCATION_SECTOR: 'location.delete_sector',
    CAN_REMOVE_LOCATION_ALLOWED_PERSON: 'location.remove_allowed_person',
    CAN_VIEW_LOCATION_PERSONAL_ACCOUNT: 'location.view_account',
    CAN_VIEW_LOCATION_PERSONAL_ACCOUNT_ALLOWED_PERSON: 'location.view_accountallowedperson',
    CAN_VIEW_LOCATION_ADDRESS: 'location.view_address',
    CAN_VIEW_LOCATION_BUILDING: 'location.view_building',
    CAN_VIEW_LOCATION_CAMERA: 'location.view_camera',
    CAN_VIEW_LOCATION_CAMERA_GROUP: 'location.view_cameragroup',
    CAN_VIEW_LOCATION_CITY: 'location.view_city',
    CAN_VIEW_LOCATION_COMPANY_APPEAL_REASON: 'location.view_companyappealreason',
    CAN_VIEW_LOCATION_ENTRANCE: 'location.view_entrance',
    CAN_VIEW_LOCATION_PIPE: 'location.view_pipe',
    CAN_VIEW_LOCATION_PREMISE: 'location.view_premise',
    CAN_VIEW_LOCATION_PREMISE_TYPE: 'location.view_premisetype',
    CAN_VIEW_LOCATION_RESIDENTIAL_COMPLEX: 'location.view_residentialcomplex',
    CAN_VIEW_LOCATION_SECTOR: 'location.view_sector',

    // Module: meter
    CAN_ADD_METER: 'meter.add_meter',
    CAN_ADD_METERAGE: 'meter.add_meterage',
    CAN_ADD_METER_TYPE: 'meter.add_metertype',
    CAN_ADD_METER_USERNAME: 'meter.add_meterusername',
    CAN_CHANGE_METER: 'meter.change_meter',
    CAN_CHANGE_METERAGE: 'meter.change_meterage',
    CAN_CHANGE_METER_TYPE: 'meter.change_metertype',
    CAN_CHANGE_METER_USERNAME: 'meter.change_meterusername',
    CAN_DELETE_METER: 'meter.delete_meter',
    CAN_DELETE_METERAGE: 'meter.delete_meterage',
    CAN_DELETE_METER_TYPE: 'meter.delete_metertype',
    CAN_DELETE_METER_USERNAME: 'meter.delete_meterusername',
    CAN_GET_METERS: 'meter.get_meters',
    CAN_SEND_METERAGE: 'meter.send_meterage',
    CAN_VIEW_METER: 'meter.view_meter',
    CAN_VIEW_METERAGE: 'meter.view_meterage',
    CAN_VIEW_METER_TYPE: 'meter.view_metertype',
    CAN_VIEW_METER_USERNAME: 'meter.view_meterusername',

    // Module: news
    CAN_ADD_NEWS: 'news.add_news',
    CAN_ADD_NEWS_CATEGORY: 'news.add_newscategory',
    CAN_ADD_NEWS_QUIZ_LIST: 'news.add_newsquizlist',
    CAN_ADD_NEWS_QUIZ: 'news.add_quiz',
    CAN_ADD_NEWS_QUIZ_ANSWER: 'news.add_quizanswer',
    CAN_ADD_NEWS_QUIZ_QUESTION: 'news.add_quizquestion',
    CAN_ADD_NEWS_QUIZ_USER_ANSWER: 'news.add_quizuseranswer',
    CAN_ADD_NEWS_STORY: 'news.add_story',
    CAN_ADD_NEWS_STORY_ELEMENT: 'news.add_storyelement',
    CAN_ADD_POLL: 'news.add_poll',
    CAN_ADD_POLL_RESULT: 'news.add_pollresult',
    CAN_CHANGE_NEWS: 'news.change_news',
    CAN_CHANGE_NEWS_CATEGORY: 'news.change_newscategory',
    CAN_CHANGE_NEWS_QUIZ_LIST: 'news.change_newsquizlist',
    CAN_CHANGE_NEWS_QUIZ: 'news.change_quiz',
    CAN_CHANGE_NEWS_QUIZ_ANSWER: 'news.change_quizanswer',
    CAN_CHANGE_NEWS_QUIZ_QUESTION: 'news.change_quizquestion',
    CAN_CHANGE_NEWS_QUIZ_USER_ANSWER: 'news.change_quizuseranswer',
    CAN_CHANGE_NEWS_STORY: 'news.change_story',
    CAN_CHANGE_NEWS_STORY_ELEMENT: 'news.change_storyelement',
    CAN_CHANGE_POLL: 'news.change_poll',
    CAN_CHANGE_POLL_RESULT: 'news.change_pollresult',
    CAN_DELETE_NEWS: 'news.delete_news',
    CAN_DELETE_NEWS_CATEGORY: 'news.delete_newscategory',
    CAN_DELETE_NEWS_QUIZ_LIST: 'news.delete_newsquizlist',
    CAN_DELETE_NEWS_QUIZ: 'news.delete_quiz',
    CAN_DELETE_NEWS_QUIZ_ANSWER: 'news.delete_quizanswer',
    CAN_DELETE_NEWS_QUIZ_QUESTION: 'news.delete_quizquestion',
    CAN_DELETE_NEWS_QUIZ_USER_ANSWER: 'news.delete_quizuseranswer',
    CAN_DELETE_NEWS_STORY: 'news.delete_story',
    CAN_DELETE_NEWS_STORY_ELEMENT: 'news.delete_storyelement',
    CAN_DELETE_POLL: 'news.delete_poll',
    CAN_DELETE_POLL_RESULT: 'news.delete_pollresult',
    CAN_VIEW_NEWS: 'news.view_news',
    CAN_VIEW_NEWS_CATEGORY: 'news.view_newscategory',
    CAN_VIEW_NEWS_QUIZ_LIST: 'news.view_newsquizlist',
    CAN_VIEW_NEWS_QUIZ: 'news.view_quiz',
    CAN_VIEW_NEWS_QUIZ_ANSWER: 'news.view_quizanswer',
    CAN_VIEW_NEWS_QUIZ_QUESTION: 'news.view_quizquestion',
    CAN_VIEW_NEWS_QUIZ_USER_ANSWER: 'news.view_quizuseranswer',
    CAN_VIEW_NEWS_STORY: 'news.view_story',
    CAN_VIEW_NEWS_STORY_ELEMENT: 'news.view_storyelement',
    CAN_VIEW_POLL: 'news.view_poll',
    CAN_VIEW_POLL_RESULT: 'news.view_pollresult',

    // Module: newsletter
    CAN_ADD_NEWSLETTER_PUSH_MESSAGE: 'newsletter.add_pushmessage',
    CAN_ADD_NEWSLETTER_PUSH_MESSAGE_LOCATION: 'newsletter.add_pushmessagelocation',
    CAN_ADD_NEWSLETTER_SETTING: 'newsletter.add_setting',
    CAN_ADD_NEWSLETTER_TEMPLATE_PUSH_MESSAGE: 'newsletter.create_template_message',
    CAN_CHANGE_NEWSLETTER_PUSH_MESSAGE: 'newsletter.change_pushmessage',
    CAN_CHANGE_NEWSLETTER_PUSH_MESSAGE_LOCATION: 'newsletter.change_pushmessagelocation',
    CAN_CHANGE_NEWSLETTER_SETTING: 'newsletter.change_setting',
    CAN_CHANGE_NEWSLETTER_TEMPLATE_PUSH_MESSAGE: 'newsletter.change_template_message',
    CAN_CSI_NEWSLETTER_REPORT_GENERATION: 'newsletter.csi_report_generation',
    CAN_DELETE_NEWSLETTER_PUSH_MESSAGE: 'newsletter.delete_pushmessage',
    CAN_DELETE_NEWSLETTER_PUSH_MESSAGE_LOCATION: 'newsletter.delete_pushmessagelocation',
    CAN_DELETE_NEWSLETTER_SETTING: 'newsletter.delete_setting',
    CAN_VIEW_NEWSLETTER_PUSH_MESSAGE: 'newsletter.view_pushmessage',
    CAN_VIEW_NEWSLETTER_PUSH_MESSAGE_LOCATION: 'newsletter.view_pushmessagelocation',
    CAN_VIEW_NEWSLETTER_SETTING: 'newsletter.view_setting',

    // Module: payment
    CAN_ADD_PAYMENT_ACCRUAL: 'payment.add_accrual',
    CAN_ADD_PAYMENT_ACCRUAL_ELEMENT: 'payment.add_accrualelement',
    CAN_ADD_PAYMENT: 'payment.add_payment',
    CAN_ADD_PAYMENT_HANDLER: 'payment.add_paymenthandler',
    CAN_ADD_PAYMENT_INVOICE: 'payment.add_paymentinvoice',
    CAN_ADD_PAYMENT_INVOICE_ELEMENT: 'payment.add_paymentinvoiceelement',
    CAN_CHANGE_PAYMENT_ACCRUAL: 'payment.change_accrual',
    CAN_CHANGE_PAYMENT_ACCRUAL_ELEMENT: 'payment.change_accrualelement',
    CAN_CHANGE_PAYMENT: 'payment.change_payment',
    CAN_CHANGE_PAYMENT_HANDLER: 'payment.change_paymenthandler',
    CAN_CHANGE_PAYMENT_INVOICE: 'payment.change_paymentinvoice',
    CAN_CHANGE_PAYMENT_INVOICE_ELEMENT: 'payment.change_paymentinvoiceelement',
    CAN_DELETE_PAYMENT_ACCRUAL: 'payment.delete_accrual',
    CAN_DELETE_PAYMENT_ACCRUAL_ELEMENT: 'payment.delete_accrualelement',
    CAN_DELETE_PAYMENT: 'payment.delete_payment',
    CAN_DELETE_PAYMENT_HANDLER: 'payment.delete_paymenthandler',
    CAN_DELETE_PAYMENT_INVOICE: 'payment.delete_paymentinvoice',
    CAN_DELETE_PAYMENT_INVOICE_ELEMENT: 'payment.delete_paymentinvoiceelement',
    CAN_PAYMENT_REPORT_GENERATION: 'payment.get_payment_report',
    CAN_VIEW_PAYMENT_ACCRUAL: 'payment.view_accrual',
    CAN_VIEW_PAYMENT_ACCRUAL_ELEMENT: 'payment.view_accrualelement',
    CAN_VIEW_PAYMENT: 'payment.view_payment',
    CAN_VIEW_PAYMENT_HANDLER: 'payment.view_paymenthandler',
    CAN_VIEW_PAYMENT_INVOICE: 'payment.view_paymentinvoice',
    CAN_VIEW_PAYMENT_INVOICE_ELEMENT: 'payment.view_paymentinvoiceelement',

    // Module: router
    CAN_ADD_ROUTER_BUILDING_VALUE: 'router.add_buildingvalue',
    CAN_ADD_ROUTER_CITY_VALUE: 'router.add_cityvalue',
    CAN_ADD_ROUTER_DATE_VALUE: 'router.add_datevalue',
    CAN_ADD_ROUTER_DAY_OF_WEEK_VALUE: 'router.add_dayofweekvalue',
    CAN_ADD_ROUTER_DEPARTMENT_VALUE: 'router.add_departmentvalue',
    CAN_ADD_ROUTER_ENTRANCE_VALUE: 'router.add_entrancevalue',
    CAN_ADD_ROUTER_FLOOR_VALUE: 'router.add_floorvalue',
    CAN_ADD_ROUTER_KIND_VALUE: 'router.add_kindvalue',
    CAN_ADD_ROUTER_PREMISE_VALUE: 'router.add_premisevalue',
    CAN_ADD_ROUTER_RESIDENTIAL_COMPLEX_VALUE: 'router.add_residentialcomplexvalue',
    CAN_ADD_ROUTER_QUEUE_EXECUTOR_RESOLVER: 'router.add_queueexecutorresolver',
    CAN_ADD_ROUTER_ROUTE_CRITERION: 'router.add_routecriterion',
    CAN_ADD_ROUTER_SOURCE_VALUE: 'router.add_sourcevalue',
    CAN_ADD_ROUTER_TICKET_ROUTE: 'router.add_ticketroute',
    CAN_ADD_ROUTER_TIME_VALUE: 'router.add_timevalue',
    CAN_ADD_ROUTER_TYPE_VALUE: 'router.add_typevalue',
    CAN_CHANGE_ROUTER_BUILDIN_GVALUE: 'router.change_buildingvalue',
    CAN_CHANGE_ROUTER_CITY_VALUE: 'router.change_cityvalue',
    CAN_CHANGE_ROUTER_DATE_VALUE: 'router.change_datevalue',
    CAN_CHANGE_ROUTER_DAY_OF_WEEK_VALUE: 'router.change_dayofweekvalue',
    CAN_CHANGE_ROUTER_DEPARTMENT_VALUE: 'router.change_departmentvalue',
    CAN_CHANGE_ROUTER_ENTRANCE_VALUE: 'router.change_entrancevalue',
    CAN_CHANGE_ROUTER_FLOOR_VALUE: 'router.change_floorvalue',
    CAN_CHANGE_ROUTER_KIND_VALUE: 'router.change_kindvalue',
    CAN_CHANGE_ROUTER_PREMISE_VALUE: 'router.change_premisevalue',
    CAN_CHANGE_ROUTER_RESIDENTIAL_COMPLEX_VALUE: 'router.change_residentialcomplexvalue',
    CAN_CHANGE_ROUTER_QUEUE_EXECUTOR_RESOLVER: 'router.change_queueexecutorresolver',
    CAN_CHANGE_ROUTER_ROUTE_CRITERION: 'router.change_routecriterion',
    CAN_CHANGE_ROUTER_SOURCE_VALUE: 'router.change_sourcevalue',
    CAN_CHANGE_ROUTER_TICKET_ROUTE: 'router.change_ticketroute',
    CAN_CHANGE_ROUTER_TIME_VALUE: 'router.change_timevalue',
    CAN_CHANGE_ROUTER_TYPE_VALUE: 'router.change_typevalue',
    CAN_DELETE_ROUTER_BUILDING_VALUE: 'router.delete_buildingvalue',
    CAN_DELETE_ROUTER_CITY_VALUE: 'router.delete_cityvalue',
    CAN_DELETE_ROUTER_DATE_VALUE: 'router.delete_datevalue',
    CAN_DELETE_ROUTER_DAY_OF_WEEK_VALUE: 'router.delete_dayofweekvalue',
    CAN_DELETE_ROUTER_DEPARTMENT_VALUE: 'router.delete_departmentvalue',
    CAN_DELETE_ROUTER_ENTRANCE_VALUE: 'router.delete_entrancevalue',
    CAN_DELETE_ROUTER_FLOOR_VALUE: 'router.delete_floorvalue',
    CAN_DELETE_ROUTER_KIND_VALUE: 'router.delete_kindvalue',
    CAN_DELETE_ROUTER_PREMISE_VALUE: 'router.delete_premisevalue',
    CAN_DELETE_ROUTER_RESIDENTIAL_COMPLEX_VALUE: 'router.delete_residentialcomplexvalue',
    CAN_DELETE_ROUTER_QUEUE_EXECUTOR_RESOLVER: 'router.delete_queueexecutorresolver',
    CAN_DELETE_ROUTER_ROUTE_CRITERION: 'router.delete_routecriterion',
    CAN_DELETE_ROUTER_SOURCE_VALUE: 'router.delete_sourcevalue',
    CAN_DELETE_ROUTER_TICKET_ROUTE: 'router.delete_ticketroute',
    CAN_DELETE_ROUTER_TIME_VALUE: 'router.delete_timevalue',
    CAN_DELETE_ROUTER_TYPE_VALUE: 'router.delete_typevalue',
    CAN_VIEW_ROUTER_BUILDING_VALUE: 'router.view_buildingvalue',
    CAN_VIEW_ROUTER_CITY_VALUE: 'router.view_cityvalue',
    CAN_VIEW_ROUTER_DATE_VALUE: 'router.view_datevalue',
    CAN_VIEW_ROUTER_DAY_OF_WEEK_VALUE: 'router.view_dayofweekvalue',
    CAN_VIEW_ROUTER_DEPARTMENT_VALUE: 'router.view_departmentvalue',
    CAN_VIEW_ROUTER_ENTRANCE_VALUE: 'router.view_entrancevalue',
    CAN_VIEW_ROUTER_FLOOR_VALUE: 'router.view_floorvalue',
    CAN_VIEW_ROUTER_KIND_VALUE: 'router.view_kindvalue',
    CAN_VIEW_ROUTER_PREMISE_VALUE: 'router.view_premisevalue',
    CAN_VIEW_ROUTER_RESIDENTIAL_COMPLEX_VALUE: 'router.view_residentialcomplexvalue',
    CAN_VIEW_ROUTER_QUEUE_EXECUTOR_RESOLVER: 'router.view_queueexecutorresolver',
    CAN_VIEW_ROUTER_ROUTE_CRITERION: 'router.view_routecriterion',
    CAN_VIEW_ROUTER_SOURCE_VALUE: 'router.view_sourcevalue',
    CAN_VIEW_ROUTER_TICKET_ROUTE: 'router.view_ticketroute',
    CAN_VIEW_ROUTER_TIME_VALUE: 'router.view_timevalue',
    CAN_VIEW_ROUTER_TYPE_VALUE: 'router.view_typevalue',

    // Module: service
    CAN_ADD_SERVICE_BONUS_COMPANY: 'service.add_bonuscompany',
    CAN_ADD_SERVICE_CALENDAR_EVENT: 'service.add_calendarevent',
    CAN_ADD_SERVICE_CALENDAR_EVENT_APPOINTMENT: 'service.add_calendareventappointment',
    CAN_ADD_SERVICE_CATEGORY: 'service.add_category',
    CAN_ADD_SERVICE_DISCOUNT_CARD: 'service.add_discountcard',
    CAN_ADD_SERVICE_DISCOUNT_VALUE: 'service.add_discountvalue',
    CAN_ADD_SERVICE_ELEMENT: 'service.add_element',
    CAN_ADD_SERVICE_ELEMENT_CALENDAR_EVENT: 'service.add_elementcalendarevent',
    CAN_ADD_SERVICE_ELEMENT_CATALOG: 'service.add_elementcatalog',
    CAN_ADD_SERVICE_ELEMENT_CHECKBOX: 'service.add_elementcheckbox',
    CAN_ADD_SERVICE_ELEMENT_CHECKBOX_TAG: 'service.add_elementcheckboxtag',
    CAN_ADD_SERVICE_ELEMENT_DATE: 'service.add_elementdate',
    CAN_ADD_SERVICE_ELEMENT_DATETIME: 'service.add_elementdatetime',
    CAN_ADD_SERVICE_ELEMENT_DROPDOWN: 'service.add_elementdropdown',
    CAN_ADD_SERVICE_ELEMENT_FILES: 'service.add_elementfiles',
    CAN_ADD_SERVICE_ELEMENT_PUSH_MESSAGE: 'service.add_elementpushmessage',
    CAN_ADD_SERVICE_ELEMENT_RADIO_BUTTON: 'service.add_elementradiobutton',
    CAN_ADD_SERVICE_ELEMENT_RADIO_BUTTON_WITH_IMAGE: 'service.add_elementradiobuttonwithimage',
    CAN_ADD_SERVICE_ELEMENT_TEXT: 'service.add_elementtext',
    CAN_ADD_SERVICE_ELEMENT_TEXT_INPUT: 'service.add_elementtextinput',
    CAN_ADD_SERVICE_ELEMENT_TIME: 'service.add_elementtime',
    CAN_ADD_SERVICE_ELEMENT_TITLE1: 'service.add_elementtitle1',
    CAN_ADD_SERVICE_ELEMENT_TITLE2: 'service.add_elementtitle2',
    CAN_ADD_SERVICE_ELEMENT_UPLOAD_FILES: 'service.add_elementuploadfiles',
    CAN_ADD_SERVICE_ELEMENT_UPLOAD_FILES_RESULT: 'service.add_elementuploadfilesresult',
    CAN_ADD_SERVICE_ELEMENT_UPLOAD_IMAGES: 'service.add_elementuploadimages',
    CAN_ADD_SERVICE_ELEMENT_UPLOAD_IMAGES_RESULT: 'service.add_elementuploadimagesresult',
    CAN_ADD_SERVICE_ELEMENT_VARIANT: 'service.add_elementvariant',
    CAN_ADD_SERVICE_FAVORITE_DISCOUNT_CARD: 'service.add_favoritediscountcard',
    CAN_ADD_SERVICE_PROMOTION: 'service.add_promotion',
    CAN_ADD_SERVICE: 'service.add_service',
    CAN_ADD_SERVICE_ACCEPTANCE: 'service.add_serviceacceptance',
    CAN_ADD_SERVICE_BONUS: 'service.add_servicebonus',
    CAN_ADD_SERVICE_CONSTRUCTOR: 'service.add_serviceconstructor',
    CAN_ADD_SERVICE_INFO: 'service.add_serviceinfo',
    CAN_ADD_SERVICE_PRODUCT: 'service.add_serviceproduct',
    CAN_ADD_SERVICE_SECTION: 'service.add_servicesection',
    CAN_ADD_SERVICE_SUBSECTION: 'service.add_servicesubsection',
    CAN_CAN_SERVICE_READ_TICKET_CHATS: 'service.can_read_ticket_chats',
    CAN_CHANGE_SERVICE_BONUS_COMPANY: 'service.change_bonuscompany',
    CAN_CHANGE_SERVICE_CALENDAR_EVENT: 'service.change_calendarevent',
    CAN_CHANGE_SERVICE_CALENDAR_EVENT_APPOINTMENT: 'service.change_calendareventappointment',
    CAN_CHANGE_SERVICE_CATEGORY: 'service.change_category',
    CAN_CHANGE_SERVICE_DISCOUNT_CARD: 'service.change_discountcard',
    CAN_CHANGE_SERVICE_DISCOUNT_VALUE: 'service.change_discountvalue',
    CAN_CHANGE_SERVICE_ELEMENT: 'service.change_element',
    CAN_CHANGE_SERVICE_ELEMENT_CALENDAR_EVENT: 'service.change_elementcalendarevent',
    CAN_CHANGE_SERVICE_ELEMENT_CATALOG: 'service.change_elementcatalog',
    CAN_CHANGE_SERVICE_ELEMENT_CHECKBOX: 'service.change_elementcheckbox',
    CAN_CHANGE_SERVICE_ELEMENT_CHECKBOX_TAG: 'service.change_elementcheckboxtag',
    CAN_CHANGE_SERVICE_ELEMENT_DATE: 'service.change_elementdate',
    CAN_CHANGE_SERVICE_ELEMENT_DATETIME: 'service.change_elementdatetime',
    CAN_CHANGE_SERVICE_ELEMENT_DROPDOWN: 'service.change_elementdropdown',
    CAN_CHANGE_SERVICE_ELEMENT_FILES: 'service.change_elementfiles',
    CAN_CHANGE_SERVICE_ELEMENT_PUSH_MESSAGE: 'service.change_elementpushmessage',
    CAN_CHANGE_SERVICE_ELEMENT_RADIO_BUTTON: 'service.change_elementradiobutton',
    CAN_CHANGE_SERVICE_ELEMENT_RADIO_BUTTON_WITH_IMAGE: 'service.change_elementradiobuttonwithimage',
    CAN_CHANGE_SERVICE_ELEMENT_TEXT: 'service.change_elementtext',
    CAN_CHANGE_SERVICE_ELEMENT_TEXT_INPUT: 'service.change_elementtextinput',
    CAN_CHANGE_SERVICE_ELEMENT_TIME: 'service.change_elementtime',
    CAN_CHANGE_SERVICE_ELEMENT_TITLE1: 'service.change_elementtitle1',
    CAN_CHANGE_SERVICE_ELEMENT_TITLE2: 'service.change_elementtitle2',
    CAN_CHANGE_SERVICE_ELEMENT_UPLOAD_FILES: 'service.change_elementuploadfiles',
    CAN_CHANGE_SERVICE_ELEMENT_UPLOAD_FILES_RESULT: 'service.change_elementuploadfilesresult',
    CAN_CHANGE_SERVICE_ELEMENT_UPLOAD_IMAGES: 'service.change_elementuploadimages',
    CAN_CHANGE_SERVICE_ELEMENT_UPLOAD_IMAGES_RESULT: 'service.change_elementuploadimagesresult',
    CAN_CHANGE_SERVICE_ELEMENT_VARIANT: 'service.change_elementvariant',
    CAN_CHANGE_SERVICE_FAVORITE_DISCOUNT_CARD: 'service.change_favoritediscountcard',
    CAN_CHANGE_SERVICE_PROMOTION: 'service.change_promotion',
    CAN_CHANGE_SERVICE: 'service.change_service',
    CAN_CHANGE_SERVICE_ACCEPTANCE: 'service.change_serviceacceptance',
    CAN_CHANGE_SERVICE_BONUS: 'service.change_servicebonus',
    CAN_CHANGE_SERVICE_CONSTRUCTOR: 'service.change_serviceconstructor',
    CAN_CHANGE_SERVICE_INFO: 'service.change_serviceinfo',
    CAN_CHANGE_SERVICE_PRODUCT: 'service.change_serviceproduct',
    CAN_CHANGE_SERVICE_SECTION: 'service.change_servicesection',
    CAN_CHANGE_SERVICE_SUBSECTION: 'service.change_servicesubsection',
    CAN_CHANGE_SERVICE_TICKET_RESPONSIBLE: 'service.change_ticket_responsible',
    CAN_CHANGE_SERVICE_TICKET_STAGE: 'service.change_ticket_stage',
    CAN_DELETE_SERVICE_BONUS_COMPANY: 'service.delete_bonuscompany',
    CAN_DELETE_SERVICE_CALENDAR_EVENT: 'service.delete_calendarevent',
    CAN_DELETE_SERVICE_CALENDAR_EVENT_APPOINTMENT: 'service.delete_calendareventappointment',
    CAN_DELETE_SERVICE_CATEGORY: 'service.delete_category',
    CAN_DELETE_SERVICE_DISCOUNT_CARD: 'service.delete_discountcard',
    CAN_DELETE_SERVICE_DISCOUNT_VALUE: 'service.delete_discountvalue',
    CAN_DELETE_SERVICE_ELEMENT: 'service.delete_element',
    CAN_DELETE_SERVICE_ELEMENT_CALENDAR_EVENT: 'service.delete_elementcalendarevent',
    CAN_DELETE_SERVICE_ELEMENT_CATALOG: 'service.delete_elementcatalog',
    CAN_DELETE_SERVICE_ELEMENT_CHECKBOX: 'service.delete_elementcheckbox',
    CAN_DELETE_SERVICE_ELEMENT_CHECKBOX_TAG: 'service.delete_elementcheckboxtag',
    CAN_DELETE_SERVICE_ELEMENT_DATE: 'service.delete_elementdate',
    CAN_DELETE_SERVICE_ELEMENT_DATETIME: 'service.delete_elementdatetime',
    CAN_DELETE_SERVICE_ELEMENT_DROPDOWN: 'service.delete_elementdropdown',
    CAN_DELETE_SERVICE_ELEMENT_FILES: 'service.delete_elementfiles',
    CAN_DELETE_SERVICE_ELEMENT_PUSH_MESSAGE: 'service.delete_elementpushmessage',
    CAN_DELETE_SERVICE_ELEMENT_RADIO_BUTTON: 'service.delete_elementradiobutton',
    CAN_DELETE_SERVICE_ELEMENT_RADIO_BUTTON_WITH_IMAGE: 'service.delete_elementradiobuttonwithimage',
    CAN_DELETE_SERVICE_ELEMENT_TEXT: 'service.delete_elementtext',
    CAN_DELETE_SERVICE_ELEMENT_TEXT_INPUT: 'service.delete_elementtextinput',
    CAN_DELETE_SERVICE_ELEMENT_TIME: 'service.delete_elementtime',
    CAN_DELETE_SERVICE_ELEMENT_TITLE1: 'service.delete_elementtitle1',
    CAN_DELETE_SERVICE_ELEMENT_TITLE2: 'service.delete_elementtitle2',
    CAN_DELETE_SERVICE_ELEMENT_UPLOAD_FILES: 'service.delete_elementuploadfiles',
    CAN_DELETE_SERVICE_ELEMENT_UPLOAD_FILES_RESULT: 'service.delete_elementuploadfilesresult',
    CAN_DELETE_SERVICE_ELEMENT_UPLOAD_IMAGES: 'service.delete_elementuploadimages',
    CAN_DELETE_SERVICE_ELEMENT_UPLOAD_IMAGES_RESULT: 'service.delete_elementuploadimagesresult',
    CAN_DELETE_SERVICE_ELEMENT_VARIANT: 'service.delete_elementvariant',
    CAN_DELETE_SERVICE_FAVORITE_DISCOUNT_CARD: 'service.delete_favoritediscountcard',
    CAN_DELETE_SERVICE_PROMOTION: 'service.delete_promotion',
    CAN_DELETE_SERVICE: 'service.delete_service',
    CAN_DELETE_SERVICE_ACCEPTANCE: 'service.delete_serviceacceptance',
    CAN_DELETE_SERVICE_BONUS: 'service.delete_servicebonus',
    CAN_DELETE_SERVICE_CONSTRUCTOR: 'service.delete_serviceconstructor',
    CAN_DELETE_SERVICE_INFO: 'service.delete_serviceinfo',
    CAN_DELETE_SERVICE_PRODUCT: 'service.delete_serviceproduct',
    CAN_DELETE_SERVICE_SECTION: 'service.delete_servicesection',
    CAN_DELETE_SERVICE_SUBSECTION: 'service.delete_servicesubsection',
    CAN_VIEW_SERVICE_BONUS_COMPANY: 'service.view_bonuscompany',
    CAN_VIEW_SERVICE_CALENDAR_EVENT: 'service.view_calendarevent',
    CAN_VIEW_SERVICE_CALENDAR_EVENT_APPOINTMENT: 'service.view_calendareventappointment',
    CAN_VIEW_SERVICE_CATEGORY: 'service.view_category',
    CAN_VIEW_SERVICE_DISCOUNT_CARD: 'service.view_discountcard',
    CAN_VIEW_SERVICE_DISCOUNT_VALUE: 'service.view_discountvalue',
    CAN_VIEW_SERVICE_ELEMENT: 'service.view_element',
    CAN_VIEW_SERVICE_ELEMENT_CALENDAR_EVENT: 'service.view_elementcalendarevent',
    CAN_VIEW_SERVICE_ELEMENT_CATALOG: 'service.view_elementcatalog',
    CAN_VIEW_SERVICE_ELEMENT_CHECKBOX: 'service.view_elementcheckbox',
    CAN_VIEW_SERVICE_ELEMENT_CHECKBOX_TAG: 'service.view_elementcheckboxtag',
    CAN_VIEW_SERVICE_ELEMENT_DATE: 'service.view_elementdate',
    CAN_VIEW_SERVICE_ELEMENT_DATETIME: 'service.view_elementdatetime',
    CAN_VIEW_SERVICE_ELEMENT_DROPDOWN: 'service.view_elementdropdown',
    CAN_VIEW_SERVICE_ELEMENT_FILES: 'service.view_elementfiles',
    CAN_VIEW_SERVICE_ELEMENT_PUSH_MESSAGE: 'service.view_elementpushmessage',
    CAN_VIEW_SERVICE_ELEMENT_RADIO_BUTTON: 'service.view_elementradiobutton',
    CAN_VIEW_SERVICE_ELEMENT_RADIO_BUTTON_WITH_IMAGE: 'service.view_elementradiobuttonwithimage',
    CAN_VIEW_SERVICE_ELEMENT_TEXT: 'service.view_elementtext',
    CAN_VIEW_SERVICE_ELEMENT_TEXT_INPUT: 'service.view_elementtextinput',
    CAN_VIEW_SERVICE_ELEMENT_TIME: 'service.view_elementtime',
    CAN_VIEW_SERVICE_ELEMENT_TITLE1: 'service.view_elementtitle1',
    CAN_VIEW_SERVICE_ELEMENT_TITLE2: 'service.view_elementtitle2',
    CAN_VIEW_SERVICE_ELEMENT_UPLOAD_FILES: 'service.view_elementuploadfiles',
    CAN_VIEW_SERVICE_ELEMENT_UPLOAD_FILES_RESULT: 'service.view_elementuploadfilesresult',
    CAN_VIEW_SERVICE_ELEMENT_UPLOAD_IMAGES: 'service.view_elementuploadimages',
    CAN_VIEW_SERVICE_ELEMENT_UPLOAD_IMAGES_RESULT: 'service.view_elementuploadimagesresult',
    CAN_VIEW_SERVICE_ELEMENT_VARIANT: 'service.view_elementvariant',
    CAN_VIEW_SERVICE_FAVORITE_DISCOUNT_CARD: 'service.view_favoritediscountcard',
    CAN_VIEW_SERVICE_PROMOTION: 'service.view_promotion',
    CAN_VIEW_SERVICE: 'service.view_service',
    CAN_VIEW_SERVICE_ACCEPTANCE: 'service.view_serviceacceptance',
    CAN_VIEW_SERVICE_BONUS: 'service.view_servicebonus',
    CAN_VIEW_SERVICE_CONSTRUCTOR: 'service.view_serviceconstructor',
    CAN_VIEW_SERVICE_INFO: 'service.view_serviceinfo',
    CAN_VIEW_SERVICE_PRODUCT: 'service.view_serviceproduct',
    CAN_VIEW_SERVICE_SECTION: 'service.view_servicesection',
    CAN_VIEW_SERVICE_SUBSECTION: 'service.view_servicesubsection',

    // Module: storage
    CAN_ADD_STORAGE_KEY: 'storage.add_key',
    CAN_ADD_STORAGE_KEY_ISSUANCE: 'storage.add_keyissuance',
    CAN_ADD_STORAGE_KEY_ACCEPTANCE: 'storage.add_keystorageacceptance',
    CAN_ADD_STORAGE_PROXY: 'storage.add_proxy',
    CAN_ADD_STORAGE_HISTORY: 'storage.add_storagehistory',
    CAN_CHANGE_STORAGE_KEY: 'storage.change_key',
    CAN_CHANGE_STORAGE_KEY_ISSUANCE: 'storage.change_keyissuance',
    CAN_CHANGE_STORAGE_KEY_ACCEPTANCE: 'storage.change_keystorageacceptance',
    CAN_CHANGE_STORAGE_PROXY: 'storage.change_proxy',
    CAN_CHANGE_STORAGE_HISTORY: 'storage.change_storagehistory',
    CAN_DELETE_STORAGE_KEY: 'storage.delete_key',
    CAN_DELETE_STORAGE_KEY_ISSUANCE: 'storage.delete_keyissuance',
    CAN_DELETE_STORAGE_KEY_ACCEPTANCE: 'storage.delete_keystorageacceptance',
    CAN_DELETE_STORAGE_PROXY: 'storage.delete_proxy',
    CAN_DELETE_STORAGE_HISTORY: 'storage.delete_storagehistory',
    CAN_VIEW_STORAGE_KEY: 'storage.view_key',
    CAN_VIEW_STORAGE_KEY_ISSUANCE: 'storage.view_keyissuance',
    CAN_VIEW_STORAGE_KEY_ACCEPTANCE: 'storage.view_keystorageacceptance',
    CAN_VIEW_STORAGE_PROXY: 'storage.view_proxy',
    CAN_VIEW_STORAGE_HISTORY: 'storage.view_storagehistory',

    // Module: tariff
    CAN_ADD_TARIFF: 'tariff.add_tariff',
    CAN_ADD_TARIFF_UNIT: 'tariff.add_tariffunit',
    CAN_CHANGE_TARIFF: 'tariff.change_tariff',
    CAN_CHANGE_TARIFF_UNIT: 'tariff.change_tariffunit',
    CAN_DELETE_TARIFF: 'tariff.delete_tariff',
    CAN_DELETE_TARIFF_UNIT: 'tariff.delete_tariffunit',
    CAN_VIEW_TARIFF: 'tariff.view_tariff',
    CAN_VIEW_TARIFF_UNIT: 'tariff.view_tariffunit',

    // Module: ticket
    CAN_ADD_TICKET_APPLICANT_FEEDBACK: 'ticket.add_applicantfeedback',
    CAN_ADD_TICKET: 'ticket.add_ticket',
    CAN_ADD_TICKET_DEPARTMENT: 'ticket.add_ticketdepartment',
    CAN_ADD_TICKET_DEPARTMENT_GROUP: 'ticket.add_ticketdepartmentgroup',
    CAN_ADD_TICKET_DEPARTMENT_PENALTY: 'ticket.add_ticketdepartmentpenalty',
    CAN_ADD_TICKET_FILE: 'ticket.add_ticketfile',
    CAN_ADD_TICKET_KIND: 'ticket.add_ticketkind',
    CAN_ADD_TICKET_SOURCE: 'ticket.add_ticketsource',
    CAN_ADD_TICKET_STAGE: 'ticket.add_ticketstage',
    CAN_ADD_TICKET_TYPE: 'ticket.add_tickettype',
    CAN_CANCEL_TICKET: 'ticket.cancel_ticket',
    CAN_CHANGE_WARRANTY_TICKET_FIELDS: 'ticket.change_warranty_ticket_fields',
    CAN_CHANGE_TICKET_APPLICANTFEEDBACK: 'ticket.change_applicantfeedback',
    CAN_CHANGE_TICKET: 'ticket.change_ticket',
    CAN_CHANGE_TICKET_DEPARTMENT: 'ticket.change_ticketdepartment',
    CAN_CHANGE_TICKET_DEPARTMENT_GROUP: 'ticket.change_ticketdepartmentgroup',
    CAN_CHANGE_TICKET_DEPARTMENT_PENALTY: 'ticket.change_ticketdepartmentpenalty',
    CAN_CHANGE_TICKET_FILE: 'ticket.change_ticketfile',
    CAN_CHANGE_TICKET_KIND: 'ticket.change_ticketkind',
    CAN_CHANGE_TICKET_SOURCE: 'ticket.change_ticketsource',
    CAN_CHANGE_TICKET_STAGE: 'ticket.change_ticketstage',
    CAN_CHANGE_TICKET_TYPE: 'ticket.change_tickettype',
    CAN_CHANGE_TICKET_TYPE_TIMERS: 'ticket.change_tickettype_timers',
    CAN_DELETE_TICKET_APPLICANTFEEDBACK: 'ticket.delete_applicantfeedback',
    CAN_DELETE_TICKET: 'ticket.delete_ticket',
    CAN_DELETE_TICKET_DEPARTMENT: 'ticket.delete_ticketdepartment',
    CAN_DELETE_TICKET_DEPARTMENT_GROUP: 'ticket.delete_ticketdepartmentgroup',
    CAN_DELETE_TICKET_DEPARTMENT_PENALTY: 'ticket.delete_ticketdepartmentpenalty',
    CAN_DELETE_TICKET_FILE: 'ticket.delete_ticketfile',
    CAN_DELETE_TICKET_KIND: 'ticket.delete_ticketkind',
    CAN_DELETE_TICKET_SOURCE: 'ticket.delete_ticketsource',
    CAN_DELETE_TICKET_STAGE: 'ticket.delete_ticketstage',
    CAN_DELETE_TICKET_TYPE: 'ticket.delete_tickettype',
    CAN_FINISH_TICKET_WITHOUT_CHECK: 'ticket.can_finish_ticket_without_check',
    CAN_SEND_SMS_TO_APPLICANT: 'ticket.can_send_sms_to_ticket_applicant',
    CAN_RETYPE_TICKET: 'ticket.retype_tickets',
    CAN_VIEW_OWN_TICKET: 'ticket.view_own_tickets',
    CAN_VIEW_OWN_DEPARMENT_TICKET: 'ticket.view_own_department_tickets',
    CAN_VIEW_TICKET: 'ticket.view_ticket',
    CAN_VIEW_TICKET_DEPARTMENT: 'ticket.view_ticketdepartment',
    CAN_VIEW_TICKET_DEPARTMENT_GROUP: 'ticket.view_ticketdepartmentgroup',
    CAN_VIEW_TICKET_DEPARTMENT_PENALTY: 'ticket.view_ticketdepartmentpenalty',
    CAN_VIEW_TICKET_FILE: 'ticket.view_ticketfile',
    CAN_VIEW_TICKET_KIND: 'ticket.view_ticketkind',
    CAN_VIEW_TICKET_ON_CHECK: 'ticket.view_ticket_on_check',
    CAN_VIEW_TICKET_SETTINGS: 'ticket.ticket_settings',
    CAN_VIEW_TICKET_SOURCE: 'ticket.view_ticketsource',
    CAN_VIEW_TICKET_STAGE: 'ticket.view_ticketstage',
    CAN_VIEW_TICKET_TYPE: 'ticket.view_tickettype',
    CAN_VIEW_WARRANTY_TICKET_FIELDS: 'ticket.view_warranty_ticket_fields',

    // Module: time management
    CAN_CHANGE_ALL_QUEUE_STATUS: 'bh_time_management.change_bhonqueueentry',
    CAN_CHANGE_ALL_USERS_WORKING_STATUS: 'bh_time_management.change_all_users_working_status',

    // Module: user
    CAN_ADD_USER_FCM_TOKEN: 'user.add_fcmtoken',
    CAN_ADD_USER: 'user.add_user',
    CAN_ADD_USER_NAME: 'user.add_username',
    CAN_ADD_USER_PROFILE: 'user.add_userprofile',
    CAN_CHANGE_DOCUMENTS: 'user.update_documents',
    CAN_CHANGE_USER_FCM_TOKEN: 'user.change_fcmtoken',
    CAN_CHANGE_USER: 'user.change_user',
    CAN_CHANGE_USER_NAME: 'user.change_username',
    CAN_CHANGE_USER_PROFILE: 'user.change_userprofile',
    CAN_CREATE_DOCUMENTS: [
        'user.create_documents',
        // TODO: что это костыль и нужно исправить в будущем. Пока право update используется для проверки прав на создание.
        'user.update_documents',
    ],
    CAN_DELETE_DOCUMENTS: 'user.delete_documents',
    CAN_DELETE_USER_FCM_TOKEN: 'user.delete_fcmtoken',
    CAN_DELETE_USER: 'user.delete_user',
    CAN_DELETE_USER_NAME: 'user.delete_username',
    CAN_DELETE_USER_PROFILE: 'user.delete_userprofile',
    CAN_VIEW_EXPERIMENTAL_FEATURES: 'user.can_view_experimental_features',
    CAN_VIEW_DOCUMENTS: 'user.view_documents',
    CAN_VIEW_USER_FCM_TOKEN: 'user.view_fcmtoken',
    CAN_VIEW_USER: 'user.view_user',
    CAN_VIEW_USE_RNAME: 'user.view_username',
    CAN_VIEW_USER_PROFILE: 'user.view_userprofile',
    CAN_VIEW_VOIP_TOKEN: 'user.view_voiptoken',
    CAN_VIEW_NEED_ANSWER_SECTION: 'user.can_view_need_answer_section',
}
