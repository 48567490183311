import crypto from 'crypto'

import store from '@/store'

export const currentWorkerId = () => {
    return store.state.auth.userId
}

export const getFilterLocalStorageKey = (code) => {
    const accessToken = localStorage.getItem('accessToken') || localStorage.getItem('accessKeycloakToken')
    const sessionId = crypto.createHash('md5').update(accessToken).digest('hex')

    return `filter_${code}_${sessionId}`
}

export const getFilterLocalStorageKeyNew = (code) => {
    return `filter_${code}_${currentWorkerId()}`
}
