import { request } from '_api/v1/base/behemoth/client'
import { splitAPIOptions } from '_api/utils/tools'

import { chunk as _chunk, forEach as _forEach } from 'lodash'

import UserOld from '_entities/user/UserOld'

export const getExternalUser = async (userExtId) => {
    return await request('GET', `user/user/external/${userExtId}/`)
}

export const addResident = async (params) => {
    return await request('POST', 'user/resident/', params)
}

export const getResidents = async (params) => {
    try {
        const response = await request('GET', 'user/resident/', params)

        const { count, results } = response

        const entities = []
        _forEach(results, (result) => {
            entities.push(new UserOld(result))
        })

        return { count, results: entities }
    } catch (errors) {
        return Promise.reject(errors)
    }
}

export const getResidentById = async (residentId) => {
    return await request('GET', `user/resident/${residentId}/`)
}

export const updateResident = async (residentId, params) => {
    return await request('PUT', `user/resident/${residentId}/`, params)
}

export const getUserGroups = async () => {
    return await request('GET', 'user/group/')
}

export const getUserOwners = async (params) => {
    return await request('GET', 'user/owner/', params)
}

export const changeUserData = async (params) => {
    return await request('POST', 'user/post/change_data/', params)
}

// User (new)

export const getUsers = async (options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    return await request('POST', 'user/post/users/', filters, { params })
}

export const getUserById = async (userId) => {
    return await request('GET', `user/user/${userId}/`)
}

export const changeUserPhone = async (userId, params) => {
    return await request('POST', `/user/user/${userId}/change_phone/`, params)
}

// Workers

export const getWorkers = async (params) => {
    const extParams = {
        ...params,
        workers: true,
    }

    if (extParams?.id__in) {
        const chatIds = extParams.id__in.split(',')
        let results = []

        const chatIdChunks = _chunk(chatIds, 30)

        for (const chatIdChunk of chatIdChunks) {
            extParams.id__in = chatIdChunk.join(',')

            const response = await request('GET', 'user/user/', extParams)

            results = [...results, ...response?.results]
        }

        return { results }
    } else {
        return await request('GET', 'user/user/', extParams)
    }
}

// Devices

export const getUserDevices = async (options) => {
    const { filters, params } = splitAPIOptions(options)

    return await request('POST', '/user/post/users/devices/', filters, { params })
}
