<template>
    <div v-if="attachment" class="c-attachment-photo-item">
        <v-responsive :aspect-ratio="aspectRatio">
            <div class="c-attachment-photo-item__preview">
                <v-img
                    :aspect-ratio="aspectRatio"
                    class="c-attachment-photo-item__image"
                    :lazy-src="attachment.url"
                    :src="attachment.url"
                >
                    <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0 grey lighten-3"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular color="primary" indeterminate />
                        </v-row>
                    </template>
                </v-img>
            </div>
        </v-responsive>
        <div class="c-attachment-photo-item__actions">
            <v-btn
                class="c-attachment-photo-item__action"
                icon
                @click="remove()"
            >
                <v-icon v-text="`$delete`" />
            </v-btn>
        </div>
    </div>
</template>

<script>
    import FileImage from '_entities/media/file/FileImage'
    import Photo from '_entities/media/Photo'

    export default {
        name: 'UIAttachmentImageItem',

        props: {
            aspectRatio: { type: Number, default: 1 },
            value: { type: FileImage, default: null },
        },

        computed: {
            attachment() {
                if (this.value && this.value instanceof FileImage) {
                    return {
                        id: this.value.id,
                        url: this.value.url200,
                    }
                }

                if (this.value && this.value instanceof Photo) {
                    return {
                        id: this.value.id,
                        url: this.value.getSize('m').url,
                    }
                }

                return null
            },
        },

        methods: {
            remove() {
                this.$emit('click-remove')
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/scss/variables';

    .c-attachment-photo-item {
        &__preview {
            width:           100%;
            height:          100%;

            display:         flex;
            align-items:     center;
            justify-content: center;
        }

        &__actions {
            position: absolute;
            top:      map-get($spacers, 2);
            right:    map-get($spacers, 2);
            z-index:  2;
        }
    }
</style>
